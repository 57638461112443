import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal } from "react-bootstrap";
import { FilterByCallback, Option, TypeaheadPropsAndState } from "react-bootstrap-typeahead/types/types";
import { CheckCircleFill, ExclamationTriangleFill, PlusCircleFill, Search, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import QueryKeys from "../../../api/queryKeys";
import { useWMSpeakers, weekendApi } from "../../../api/weekend";
import { hasAbsence } from "../../../helpers/absence";
import { dateToString, stringToDate, weekOf, weekOfString, wmDate } from "../../../helpers/dateHelpers";
import { HGContext, PythonI18nInterpolation } from "../../../helpers/globals";
import { nameOfUser } from "../../../helpers/user";
import { ISODateString, NotificationDates } from "../../../types/date";
import { Absence } from "../../../types/scheduling/absence";
import { AssignmentNotification, NotificationType } from "../../../types/scheduling/meetings";
import {
  PublicTalk,
  Speaker,
  SpeakerDirectExchange,
  TalkMod,
  WMSchedule,
  WMScheduleInput,
} from "../../../types/scheduling/weekend";
import { QueryStatus } from "../../queryStatus";
import { congCompare, dummyCongregation, publicTalkCompare, ShowStatus, speakerCompare, Status } from "../common";
import { HourglassDatePicker, MenuType } from "../date";
import { NotificationIcon, NotificationOptions, notificationStatePart } from "../notificationStatus";
import { CongDDM } from "./wm_outgoing";
import { NBSP } from "../../../helpers/util";
import { Typeahead } from "react-bootstrap-typeahead";
import { ScheduledEvent } from "../../../types/scheduling/events";
import { CollatorSingleton } from "../../../helpers/locale";
import { canUpdateWeekendSchedules, DisabledPublicTalkIndicator, outlineDisabled, SpeakerName } from "./wm_common";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";

export function EditWeek(props: {
  schedule: WMSchedule;
  langGroupId: number;
  refetchFunc: () => void;
  setEditMode: (set: string) => void;
  notifications?: AssignmentNotification[];
  notificationDates: NotificationDates;
  absences?: Absence[];
  events?: ScheduledEvent[];
}) {
  const myCong = useContext(HGContext).globals.cong;
  const { t } = useTranslation();

  // UI stuff
  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [showMoveTalkModal, setShowMoveTalkModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [selectedTalkWarning, setSelectedTalkWarning] = useState(false);

  // data stuff
  const [selectedTalk, setSelectedTalk] = useState(props.schedule.public_talk?.number ?? 0);

  const getSelectedSpeaker = (): number => {
    if (!!props.schedule.speaker?.id) return props.schedule.speaker?.id;
    if (!!props.schedule.talk_mod) {
      switch (props.schedule.talk_mod) {
        case TalkMod.TBD:
          return -1;
        case TalkMod.Other:
          return -2;
        case TalkMod.Stream:
          return -3;
      }
    }
    return 0;
  };
  const [selectedSpeaker, setSelectedSpeaker] = useState(getSelectedSpeaker());
  const [selectedSpeaker2, setSelectedSpeaker2] = useState(props.schedule.speaker2);

  const getSelectedCong = (): number => {
    if (!!props.schedule.speaker?.congregation?.id) return props.schedule.speaker?.congregation?.id;
    if (!!props.schedule.temp_cong?.id) return props.schedule.temp_cong?.id;
    if (!!props.schedule.talk_mod) {
      switch (props.schedule.talk_mod) {
        case TalkMod.TBD:
          return -1;
        case TalkMod.Other:
          return -2;
        case TalkMod.Stream:
          return -3;
      }
    }
    return 0;
  };
  const [selectedCong, setSelectedCong] = useState(getSelectedCong());
  const [overrideCong, setOverrideCong] = useState(0);

  const speakersQuery = useWMSpeakers(selectedCong, props.langGroupId, true, selectedCong !== myCong.id, {
    enabled: selectedCong > 0,
  });

  const allSpeakersQuery = useQuery({
    queryKey: [QueryKeys.WMAllSpeakers, props.langGroupId],
    queryFn: () => weekendApi.getSpeakersAll(props.langGroupId),
    enabled: showSearchModal,
  });

  const talksQuery = useQuery({
    queryKey: [QueryKeys.WMTalksByCong, selectedCong],

    queryFn: () =>
      selectedCong === -3
        ? weekendApi.getTalks(true, props.langGroupId)
        : weekendApi.getTalksByCong(selectedCong, true, selectedCong !== myCong.id, props.langGroupId),

    enabled: selectedCong === -3 || selectedCong > 0,
  });

  const speakersWithTalk = (): Speaker[] => {
    return speakersQuery.data?.filter((s) => s.public_talks.some((pt) => pt.number === selectedTalk)) || [];
  };

  const possibleSpeakers = selectedTalk > 0 && !selectedSpeaker ? speakersWithTalk() : speakersQuery.data || [];
  const speaker = selectedSpeaker > 0 ? speakersQuery.data?.find((s) => s.id === selectedSpeaker) : undefined;
  const possibleTalks = speaker ? speaker.public_talks : talksQuery.data || [];

  //note: speaker will be undefined until speakersQuery returns a result. when this component is initially loaded,
  //it will render with speakersQuery being undefined, and thus possibleTalks will be empty.
  //for now we are going to avoid setting the selected talk to 0 here, because it seems to cause more harm than good
  //i.e. if a specific talk was previously scheduled, then for some reason the talk shows as not given (due to accidental removal or bug)
  //then this clears it out without the user really being aware.
  if (speakersQuery.data && talksQuery.data && possibleTalks.length > 0) {
    if (!selectedTalkWarning && selectedTalk > 0 && !possibleTalks.find((pt) => pt.number === selectedTalk)) {
      setSelectedTalkWarning(true);
    }
  }

  const meetingDate = wmDate(props.schedule.date, myCong, props.events);
  const enableAssignButton = selectedSpeaker !== 0;

  const resetStates = () => {
    setSelectedTalk(0);
    setSelectedSpeaker(0);
    setSelectedSpeaker2(0);
    setSelectedCong(0);
    setSelectedTalkWarning(false);
    props.setEditMode("");
  };

  const AssignButton = () => {
    let talkMod = "";
    const value = ((): number => {
      if (selectedCong < 0) return selectedCong;
      if (selectedSpeaker < 0) return selectedSpeaker;
      if (selectedTalk < 0) return selectedTalk;
      return 0;
    })();

    switch (value) {
      case -1:
        talkMod = TalkMod.TBD;
        break;
      case -2:
        talkMod = TalkMod.Other;
        break;
      case -3:
        talkMod = TalkMod.Stream;
        break;
    }

    return (
      <Button
        disabled={!enableAssignButton}
        variant="success"
        className="py-2 d-flex align-items-center gap-1"
        onClick={() => {
          weekendApi
            .setSchedule(
              {
                date: props.schedule.date,
                wm_chairman: props.schedule.wm_chairman,
                temp_cong: selectedCong,
                speaker: selectedSpeaker,
                speaker2: selectedSpeaker2 ?? null,
                public_talk: selectedTalk > 0 ? selectedTalk : 0, // could be -1 for tbd
                talk_mod: talkMod,
                confirmed: props.schedule.confirmed,
                wm_reader: props.schedule.wm_reader,
                host: props.schedule.host,
                interpreter: props.schedule.interpreter,
                wt_conductor: props.schedule.wt_conductor ?? null,
                openprayer: props.schedule.openprayer ?? null,
                closeprayer: props.schedule.closeprayer ?? null,
                opening_song: props.schedule.opening_song?.number ?? null,
              },
              props.langGroupId,
            )
            .then(() => {
              resetStates();
              props.refetchFunc();
              props.setEditMode("");
            });
        }}
      >
        <CheckCircleFill size={18} /> {t("general.save")}
      </Button>
    );
  };

  const ClearButton = () => {
    return (
      <Button
        variant="danger"
        className="py-2"
        hidden={!props.schedule.public_talk?.number && !props.schedule.talk_mod}
        onClick={() => {
          setShowConfirmModal("clear");
        }}
      >
        {t("schedules.fill.clear")}
      </Button>
    );
  };

  const MoveButton = () => {
    // no reason to move a TBD assignment...
    if (props.schedule.talk_mod === TalkMod.TBD) return null;

    return (
      <Button
        variant="secondary"
        className="py-2"
        hidden={!props.schedule.public_talk?.number && !props.schedule.talk_mod}
        onClick={() => {
          setShowMoveTalkModal(true);
        }}
      >
        {t("schedules.weekend.move-assignment")}
      </Button>
    );
  };

  return (
    <>
      <SpeakerSearchModal
        show={showSearchModal}
        setShow={setShowSearchModal}
        speakers={allSpeakersQuery.data ?? []}
        setSelectedTalk={setSelectedTalk}
        setSelectedSpeaker={setSelectedSpeaker}
        setSelectedCong={setSelectedCong}
        setOverrideCong={setOverrideCong}
      />
      <div className="row w-100">
        <div className="col-12 col-md-5">
          <CongFinder
            langGroupId={props.langGroupId}
            selectedCong={selectedCong}
            overrideCong={overrideCong}
            setSelectedCong={setSelectedCong}
            setSelectedTalk={setSelectedTalk}
            setSelectedSpeaker={setSelectedSpeaker}
          />
        </div>
        <div className="col-12 col-md-5">
          {selectedCong >= -2 && (
            <SpeakerDDM
              speakers={possibleSpeakers}
              selectedTalk={selectedTalk}
              selectedCong={selectedCong}
              selectedSpeaker2={selectedSpeaker2}
              date={props.schedule.date}
              meetingDate={meetingDate}
              selectedSpeaker={selectedSpeaker}
              setSelectedSpeaker={setSelectedSpeaker}
              setSelectedSpeaker2={setSelectedSpeaker2}
              setSelectedTalk={setSelectedTalk}
              notifications={props.notifications}
              notificationDates={props.notificationDates}
              absences={props.absences}
              setShowSearchModal={setShowSearchModal}
            />
          )}
        </div>
        <div className="col-12 col-md-2">
          {(props.schedule.talk_mod === TalkMod.Stream || selectedCong > 0 || !!selectedTalk) && (
            <TalkDDM
              talks={possibleTalks || []}
              selectedSpeaker={selectedSpeaker}
              selectedCong={selectedCong}
              selectedTalk={selectedTalk}
              setSelectedTalk={setSelectedTalk}
              date={meetingDate}
              selectedTalkWarning={selectedTalkWarning}
            />
          )}
        </div>
      </div>
      <div className="row w-100">
        <ConfirmModal
          show={showConfirmModal}
          setShow={setShowConfirmModal}
          schedule={props.schedule}
          refetchFunc={props.refetchFunc}
          resetStates={resetStates}
          setEditMode={props.setEditMode}
          langGroupId={props.langGroupId}
        />
        <MoveModal
          show={showMoveTalkModal}
          setShow={setShowMoveTalkModal}
          schedule={props.schedule}
          setEditMode={props.setEditMode}
          langGroupId={props.langGroupId}
        />
      </div>
      <div className="row w-100 py-2">
        <ConfirmSwitch schedule={props.schedule} refetchFunc={props.refetchFunc} langGroupId={props.langGroupId} />
      </div>
      <div className="d-flex flex-row gap-2 align-items-center justify-content-end flex-fill me-2">
        <AssignButton /> <ClearButton /> <MoveButton />
      </div>
    </>
  );
}

const CongFinder = (props: {
  langGroupId: number;
  selectedCong: number;
  overrideCong?: number;
  setSelectedCong: (n: number) => void;
  setSelectedSpeaker: (n: number) => void;
  setSelectedTalk: (n: number) => void;
}) => {
  const { t } = useTranslation();
  const congsQuery = useQuery({
    queryKey: [QueryKeys.WMCongregations, props.langGroupId],

    queryFn: () => weekendApi.getCongregations(props.langGroupId),
  });
  const congsQueryStatus = QueryStatus(congsQuery);
  if (congsQueryStatus !== null) return congsQueryStatus;
  if (!congsQuery.data) return null;

  //we want a copy of the array, since we're going to be changing it. if we didn't use the spread operator here,
  //the cache would get polluted by the items we're prepending
  const congs = [...congsQuery.data]
    .filter((c) => c.direct_exchange !== SpeakerDirectExchange.Proposed)
    .sort(congCompare);
  // prepend the special entries for TBD and Other
  congs.unshift(
    dummyCongregation(-1, t("schedules.weekend.tbd")),
    dummyCongregation(-2, t("schedules.privileges.other")),
    dummyCongregation(-3, t("schedules.stream")),
  );

  const selectCong = (congId: number) => {
    props.setSelectedCong(congId);
    const speakerTalk = congId < 0 ? congId : 0;
    props.setSelectedSpeaker(speakerTalk);
    props.setSelectedTalk(speakerTalk);
  };

  return <CongDDM congs={congs} selected={props.selectedCong} setCong={selectCong} override={props.overrideCong} />;
};

const SpeakerDDM = (props: {
  speakers: Speaker[];
  selectedTalk: number;
  selectedCong: number;
  selectedSpeaker: number;
  selectedSpeaker2?: number;
  date: ISODateString;
  meetingDate: ISODateString;
  setSelectedSpeaker: (set: number) => void;
  setSelectedSpeaker2?: (set?: number) => void;
  setSelectedTalk: (set: number) => void;
  absences?: Absence[];
  notifications?: AssignmentNotification[];
  notificationDates: NotificationDates;
  setShowSearchModal: (set: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [showSpeaker2, setShowSpeaker2] = useState(!!props.selectedSpeaker2 && !!props.setSelectedSpeaker2);

  const myCong = useContext(HGContext).globals.cong;

  const speaker = props.speakers.find((s) => s.id === props.selectedSpeaker);
  const speakerDisplayName = speaker ? nameOfUser(speaker) : undefined;

  // speaker2 is a userId, not a speakerId
  const speaker2 = props.speakers.find((s) => s.userId === props.selectedSpeaker2);
  const speaker2DisplayName = speaker2 ? nameOfUser(speaker2) : undefined;

  const hasConflictingAbsence = speaker?.userId
    ? hasAbsence(speaker.userId, stringToDate(props.meetingDate), props.absences)
    : false;
  // we only show notification status for speakers in this congregation
  const notification = props.notifications?.find((n) => speaker?.userId === n.assignee && props.date === n.date);
  const notificationState = notificationStatePart(speaker?.userId ?? 0, notification);

  const hasConflictingAbsence2 = speaker2?.userId
    ? hasAbsence(speaker2.userId, stringToDate(props.meetingDate), props.absences)
    : false;
  const notification2 = props.notifications?.find((n) => speaker2?.userId === n.assignee && props.date === n.date);
  const notification2State = notificationStatePart(speaker2?.userId ?? 0, notification);

  const selectedSpeaker = (): JSX.Element | string => {
    switch (props.selectedSpeaker) {
      case -1:
        return <i>{t("schedules.weekend.tbd")}</i>;
      default:
        return speakerDisplayName || <i>{t("general.none-selected")}</i>;
    }
  };

  if (props.selectedCong === -2) {
    return (
      <Col>
        <div className="hg-small-label mb-2">{t("schedules.weekend.speaker.0")}</div>
        <i>{t("schedules.privileges.other")}</i>
      </Col>
    );
  }

  if (props.selectedCong === -3) {
    return (
      <Col>
        <div className="hg-small-label mb-2">{t("schedules.stream")}</div>
        <i>{t("schedules.stream")}</i>
      </Col>
    );
  }

  return (
    <Form.Group>
      <Form.Label className="fw-bolder text-muted mb-1">{t("schedules.weekend.speaker.0")}</Form.Label>
      <DropdownButton
        title={
          <>
            <span>
              {(speaker?.congregation?.id === myCong.id || !!notification) && (
                <NotificationIcon nstate={notificationState} hasAbsence={hasConflictingAbsence} />
              )}
            </span>
            <span className="mx-1">{selectedSpeaker()}</span>
          </>
        }
        variant="secondary"
        className="dropdown-bounded w-100 d-flex flex-column mb-2"
        disabled={!canUpdateWeekendSchedules()}
      >
        {!!notification && (
          <>
            <NotificationOptions
              startDate={props.notificationDates.start}
              endDate={props.notificationDates.end}
              nstate={notificationState}
              notification={notification}
              notificationType={NotificationType.PT}
            />
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item
          onClick={() => {
            props.setSelectedSpeaker(-1);
            props.setSelectedSpeaker2?.(undefined);
            props.setSelectedTalk(-1);
          }}
        >
          <i>{t("schedules.weekend.tbd")}</i>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            props.setShowSearchModal(true);
          }}
        >
          <Search className="me-1" />
          <i>{t("search.hint")}</i>
        </Dropdown.Item>
        {props.speakers.length > 1 && <Dropdown.Divider />}
        {props.speakers
          .sort(speakerCompare)
          .filter((s) => s.id !== speaker?.id && s.id !== speaker2?.id)
          .map((s) => (
            <Dropdown.Item
              key={s.id}
              onClick={() => props.setSelectedSpeaker(s.id)}
              disabled={s.userId ? hasAbsence(s.userId, stringToDate(props.meetingDate), props.absences) : false}
            >
              <SpeakerName speaker={s} />
            </Dropdown.Item>
          ))}
      </DropdownButton>
      {props.selectedSpeaker > 0 &&
        props.selectedCong === myCong.id &&
        !!props.setSelectedSpeaker2 &&
        !showSpeaker2 && (
          // allow for a symposium
          <Button variant="ghost" onClick={() => setShowSpeaker2(true)}>
            <PlusCircleFill className="me-1" size={18} />
            <span>{t("schedules.weekend.add-speaker")}</span>
          </Button>
        )}

      {showSpeaker2 && (
        // we might be able to DRY this up, but there are several differences in terms of what state is being set
        // and which speakers can be selected
        <DropdownButton
          title={
            <>
              <span>
                {(speaker2?.congregation?.id === myCong.id || !!notification2) && (
                  <NotificationIcon nstate={notification2State} hasAbsence={hasConflictingAbsence2} />
                )}
              </span>
              <span>{speaker2DisplayName || <i>{t("general.none-selected")}</i>}</span>
            </>
          }
          variant="secondary"
          className="dropdown-bounded"
          disabled={!canUpdateWeekendSchedules()}
        >
          {!!notification2 && (
            <>
              <NotificationOptions
                startDate={props.notificationDates.start}
                endDate={props.notificationDates.end}
                nstate={notification2State}
                notification={notification2}
                notificationType={NotificationType.PT}
              />
              <Dropdown.Divider />
            </>
          )}
          <Dropdown.Item
            onClick={() => {
              props.setSelectedSpeaker2?.(undefined);
              setShowSpeaker2(false);
            }}
          >
            <Trash color="red" className="me-2" />
            {t("schedules.fill.clear")}
          </Dropdown.Item>
          {props.speakers.length > 1 && <Dropdown.Divider />}
          {props.speakers
            .sort(speakerCompare)
            .filter((s) => s.id !== speaker?.id && s.id !== speaker2?.id)
            .map((s) => (
              <Dropdown.Item
                key={s.id}
                // note that speaker2 is a user ID, not a speaker ID
                onClick={() => props.setSelectedSpeaker2?.(s.userId)}
                disabled={s.userId ? hasAbsence(s.userId, stringToDate(props.meetingDate), props.absences) : false}
              >
                <SpeakerName speaker={s} />
              </Dropdown.Item>
            ))}
        </DropdownButton>
      )}
    </Form.Group>
  );
};

const TalkDDM = (props: {
  talks: PublicTalk[];
  speakers?: Speaker[];
  selectedSpeaker: number;
  selectedCong: number;
  selectedTalk: number;
  setSelectedTalk: (set: number) => void;
  date: ISODateString;
  selectedTalkWarning: boolean;
}) => {
  const { t } = useTranslation();
  const myCong = useContext(HGContext).globals.cong;

  const talkInfo = (pt: PublicTalk, includeLast = false): JSX.Element => {
    // if we have a last given date and it's not for the same week as this talk, show it
    const showLast =
      includeLast && !!pt.last_date_given && weekOfString(props.date, 7) !== weekOfString(pt.last_date_given, 7);
    const disabled = outlineDisabled(pt.number, props.date);

    return (
      <>
        {pt.number}
        {!!pt.title && (
          <div style={{ maxWidth: "30ch" }} className="text-muted text-truncate mb-0">
            {pt.title}
          </div>
        )}
        {showLast && !disabled && (
          <p className="text-muted mb-0 hg-small-label">
            {t("schedules.weekend.talk-last-given", {
              interpolation: PythonI18nInterpolation,
              date: weekOfString(pt.last_date_given, myCong.wmdow || 7),
            })}
          </p>
        )}
        {!!disabled && <DisabledPublicTalkIndicator talkDate={disabled} />}
      </>
    );
  };

  return (
    <Form.Group>
      <Form.Label className="fw-bolder text-muted mb-1 d-flex align-items-center">
        <span>{t("schedules.general.talk")}</span>
        {props.selectedTalkWarning && <ExclamationTriangleFill className="ms-2 warning-color" />}
      </Form.Label>
      {props.selectedSpeaker === -2 ? (
        t("schedules.privileges.other")
      ) : (
        <DropdownButton
          variant="secondary"
          className="dropdown-bounded w-100 d-flex flex-column"
          title={props.selectedTalk > 0 ? props.selectedTalk : <i>{t("schedules.weekend.tbd")}</i>}
          disabled={props.talks.length === 0}
        >
          <Dropdown.Item disabled={props.selectedTalk === 0} onClick={() => props.setSelectedTalk(-1)}>
            <i>{t("schedules.weekend.tbd")}</i>
          </Dropdown.Item>
          {props.talks.length > 0 && <Dropdown.Divider />}
          {props.talks.sort(publicTalkCompare).map((pt) => (
            <Dropdown.Item
              key={pt.number}
              disabled={pt.number === props.selectedTalk}
              onClick={() => props.setSelectedTalk(pt.number)}
            >
              {talkInfo(pt, true)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      )}
    </Form.Group>
  );
};

const ConfirmSwitch = (props: { schedule: WMSchedule; langGroupId: number; refetchFunc: () => void }) => {
  const { t } = useTranslation();
  return (
    <Form.Check
      id="confirmSwitch"
      type="switch"
      label={t("schedules.weekend.confirmed")}
      className="confim_switch"
      checked={props.schedule.confirmed}
      onChange={async (e) => {
        await weekendApi.setSchedule(
          {
            date: props.schedule.date,
            wm_chairman: props.schedule.wm_chairman,
            temp_cong: props.schedule.temp_cong?.id ?? 0,
            speaker: props.schedule.speaker?.id ?? 0,
            speaker2: props.schedule.speaker2 ?? null,
            public_talk: props.schedule.public_talk?.number ?? 0,
            talk_mod: props.schedule.talk_mod ?? "",
            confirmed: e.target.checked,
            wm_reader: props.schedule.wm_reader,
            host: props.schedule.host,
            interpreter: props.schedule.interpreter,
            wt_conductor: props.schedule.wt_conductor ?? null,
            openprayer: props.schedule.openprayer ?? null,
            closeprayer: props.schedule.closeprayer ?? null,
            opening_song: props.schedule.opening_song?.number ?? null,
          },
          props.langGroupId,
        );
        props.refetchFunc();
      }}
    />
  );
};

const MoveModal = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  setEditMode: (show: string) => void;
  schedule: WMSchedule;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const [status, setStatus] = useState(Status.Null);
  const myCong = useContext(HGContext).globals.cong;
  const [moveTargetDate, setMoveTargetDate] = useState<Date>();
  const queryClient = useQueryClient();

  const targetDate = moveTargetDate || weekOf(props.schedule.date, myCong.wmdow);
  const wmsQuery = useQuery({
    queryKey: [QueryKeys.WMCSchedule, props.langGroupId, moveTargetDate],
    queryFn: () => weekendApi.getSchedule(dateToString(targetDate), props.langGroupId),
    enabled: !!moveTargetDate,
  });
  // we're not checking the query status and returning null to avoid having the modal disappear while the query is happening

  type PT = {
    date: string;
    speaker: number;
    speaker2: number | null;
    public_talk: number;
  };

  const from: PT = {
    date: props.schedule.date,
    speaker: props.schedule.speaker?.id ?? 0,
    speaker2: props.schedule.speaker2 ?? null,
    public_talk: props.schedule.public_talk?.number ?? 0,
  };
  const to: PT = {
    date: wmsQuery.data?.date ?? weekOfString(targetDate),
    speaker: wmsQuery.data?.speaker?.id ?? 0,
    speaker2: wmsQuery.data?.speaker2 ?? null,
    public_talk: wmsQuery.data?.public_talk?.number ?? 0,
  };

  const success = async () => {
    props.setShow(false);
    setMoveTargetDate(undefined);
    props.setEditMode("");
    setStatus(Status.Success);
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.WMCSchedules, props.langGroupId],
      }),
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.WMCSchedule, props.langGroupId],
      }),
    ]);
  };

  const handleMoveSwap = async () => {
    setStatus(Status.Pending);
    try {
      await weekendApi.setSchedule(
        {
          date: to.date,
          speaker: from.speaker,
          speaker2: from.speaker2,
          public_talk: from.public_talk,
          wm_chairman: wmsQuery.data?.wm_chairman ?? 0,
          temp_cong: wmsQuery.data?.temp_cong?.id ?? 0,
          talk_mod: wmsQuery.data?.talk_mod ?? "",
          confirmed: false,
          wm_reader: wmsQuery.data?.wm_reader ?? 0,
          host: wmsQuery.data?.host ?? 0,
          wt_conductor: wmsQuery.data?.wt_conductor ?? null,
          openprayer: wmsQuery.data?.openprayer ?? null,
          closeprayer: wmsQuery.data?.closeprayer ?? null,
          interpreter: wmsQuery.data?.interpreter ?? null,
          // opening song moves with the speaker
          opening_song: props.schedule.opening_song?.number ?? null,
        },
        props.langGroupId,
      );
      await weekendApi.setSchedule(
        {
          date: from.date,
          speaker: to.speaker,
          speaker2: to.speaker2,
          public_talk: to.public_talk,
          wm_chairman: props.schedule.wm_chairman,
          temp_cong: props.schedule.temp_cong?.id ?? 0,
          talk_mod: props.schedule.talk_mod ?? "",
          confirmed: false,
          wm_reader: props.schedule.wm_reader,
          host: props.schedule.host,
          wt_conductor: props.schedule.wt_conductor ?? null,
          openprayer: props.schedule.openprayer ?? null,
          closeprayer: props.schedule.closeprayer ?? null,
          interpreter: props.schedule.interpreter,
          opening_song: wmsQuery.data?.opening_song?.number ?? null,
        },
        props.langGroupId,
      );
      await success();
    } catch (err: any) {
      setStatus(Status.Failure);
      console.error("error swapping incoming public talks", err);
      HGBugsnagNotify("swapWMTalks", err);
    }
  };

  const handleReplace = async () => {
    setStatus(Status.Pending);
    try {
      await weekendApi.setSchedule(
        {
          date: to.date,
          speaker: from.speaker,
          speaker2: from.speaker2,
          public_talk: from.public_talk,
          wm_chairman: wmsQuery.data?.wm_chairman ?? 0,
          temp_cong: props.schedule.temp_cong?.id ?? 0,
          talk_mod: "",
          confirmed: false,
          wm_reader: wmsQuery.data?.wm_reader ?? 0,
          host: wmsQuery.data?.host ?? 0,
          wt_conductor: wmsQuery.data?.wt_conductor ?? null,
          openprayer: wmsQuery.data?.openprayer ?? null,
          closeprayer: wmsQuery.data?.closeprayer ?? null,
          interpreter: wmsQuery.data?.interpreter ?? null,
          opening_song: props.schedule.opening_song?.number ?? null,
        },
        props.langGroupId,
      );
      await weekendApi.setSchedule(
        {
          date: from.date,
          speaker: 0,
          speaker2: null,
          public_talk: 0,
          wm_chairman: props.schedule.wm_chairman,
          temp_cong: 0,
          talk_mod: "",
          confirmed: false,
          wm_reader: props.schedule.wm_reader,
          host: props.schedule.host,
          wt_conductor: props.schedule.wt_conductor ?? null,
          openprayer: props.schedule.openprayer ?? null,
          closeprayer: props.schedule.closeprayer ?? null,
          interpreter: props.schedule.interpreter,
          opening_song: null,
        },
        props.langGroupId,
      );
      await success();
    } catch (err: any) {
      setStatus(Status.Failure);
      console.error("error replacing incoming public talks", err);
      HGBugsnagNotify("replaceWMTalk", err);
    }
  };

  const showReplace = !!wmsQuery.data?.public_talk?.number && wmsQuery.data?.talk_mod !== TalkMod.TBD;

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("schedules.weekend.talk-mover")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("schedules.weekend.move-talk-prompt")}
          <div className="mt-2 mb-3">
            <HourglassDatePicker
              menuType={MenuType.Day}
              date={dateToString(targetDate)}
              onDateChange={(d) => {
                // don't allow selecting the same week as the source of the move
                if (weekOf(d).getTime() === weekOf(props.schedule.date).getTime()) setMoveTargetDate(undefined);
                else setMoveTargetDate(stringToDate(d));
              }}
              required
            />
          </div>
          {showReplace && (
            <div>
              <p>
                {t("schedules.weekend.already-assigned", {
                  interpolation: PythonI18nInterpolation,
                  name: wmsQuery.data ? nameOfUser(wmsQuery.data.speaker) : "",
                  talk: wmsQuery.data?.public_talk?.number ?? 0,
                })}
              </p>
              <p>{t("schedules.weekend.replace-or-swap")}</p>
              <Button variant="primary" onClick={handleReplace}>
                {t("schedules.weekend.replace")}
              </Button>{" "}
              <Button variant="secondary" onClick={handleMoveSwap}>
                {t("schedules.weekend.swap")}
              </Button>
              <ShowStatus status={status} />
            </div>
          )}
          {!!moveTargetDate && !showReplace && (
            <div>
              <p>{t("schedules.weekend.move-assignments-prompt")}</p>
              <span>
                <Button variant="primary" onClick={handleMoveSwap}>
                  {t("schedules.weekend.move-assignment")}
                </Button>
                <ShowStatus status={status} />
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const ConfirmModal = (props: {
  show: string;
  setShow: (show: string) => void;
  schedule: WMSchedule;
  langGroupId: number;
  refetchFunc: () => void;
  resetStates: () => void;
  setEditMode: (set: string) => void;
}) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow("");
  const handleUpdate = async () => {
    await weekendApi.setSchedule(
      {
        date: props.schedule.date,
        wm_chairman: props.schedule.wm_chairman,
        speaker: 0,
        public_talk: 0,
        talk_mod: props.show === "tbd" ? TalkMod.TBD : "",
        confirmed: false,
        wm_reader: props.schedule.wm_reader,
        host: props.schedule.host,
      } as WMScheduleInput,
      props.langGroupId,
    );
    props.resetStates();
    props.refetchFunc();
    props.setEditMode("");
  };

  if (!props.schedule.public_talk?.number && !!props.show) {
    // this bypasses showing the modal if there is no assigned talk
    handleUpdate().then();
    return null;
  }

  return (
    <Modal show={!!props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("popup.confirm.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.show === "clear" && <p>{t("schedules.weekend.clear-confirm")}</p>}
        {props.show === "tbd" && <p>{t("schedules.weekend.tbd-confirm")}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("popup.confirm.button.no")}
        </Button>
        <Button variant="danger" onClick={handleUpdate}>
          {t("popup.confirm.button.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Talk, Speaker, Cong
type TSC = { pt: PublicTalk; speaker: Speaker; label: string };

export function SpeakerSearchModal(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  speakers: Speaker[];
  setSelectedTalk: (set: number) => void;
  setSelectedSpeaker: (set: number) => void;
  setSelectedCong: (set: number) => void;
  setOverrideCong: (set: number) => void;
}) {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);

  const filterLogic: FilterByCallback = (option: Option, state: TypeaheadPropsAndState) => {
    const tsc = option as TSC;
    const filter = state.text;
    if (!filter) return false; // prevent all options from showing
    const search = filter.toLocaleLowerCase();
    if (nameOfUser(tsc.speaker).toLocaleLowerCase().includes(search)) return true;
    if (tsc.speaker.congregation?.custom_name?.toLocaleLowerCase().includes(search)) return true;
    if (tsc.speaker.congregation?.name.toLocaleLowerCase().includes(search)) return true;
    if (tsc.pt.number === Number(filter)) return true;
    return false;
  };

  const handleClick = (speakerId: number, congId: number, talkNumber: number) => {
    props.setSelectedSpeaker(speakerId);
    props.setSelectedCong(congId);
    props.setOverrideCong(congId);
    props.setSelectedTalk(talkNumber);
    props.setShow(false);
  };

  const selectTSC = (selected: Option[]) => {
    const tsc = selected[0] as TSC;
    handleClick(tsc.speaker.id, tsc.speaker.congregation?.id || 0, tsc.pt.number);
  };

  const renderElements = (option: Option) => {
    const tsc = option as TSC;
    return (
      <span className="font-monospace">
        <span className="text-muted">{`#${tsc.pt.number}`.padStart(4, NBSP)}</span> |{" "}
        <span className="text-muted">{nameOfUser(tsc.speaker)}</span> |{" "}
        <span className="text-muted">{tsc.speaker.congregation?.custom_name || tsc.speaker.congregation?.name}</span>
      </span>
    );
  };

  const coll = CollatorSingleton.getInstance();
  const speakerSearchCompare = (a: TSC, b: TSC) => {
    const aSpeaker = a.speaker;
    const bSpeaker = b.speaker;
    if (aSpeaker.congregation && bSpeaker.congregation && aSpeaker.congregation.id !== bSpeaker.congregation.id) {
      return coll.compare(aSpeaker.congregation.name, bSpeaker.congregation.name);
    }
    if (aSpeaker.userId !== bSpeaker.userId) return speakerCompare(aSpeaker, bSpeaker);
    return a.pt.number - b.pt.number;
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Search className="me-3" />
          {t("search.hint")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Typeahead
          id="speaker_search"
          className={"font-monospace"}
          placeholder={[
            t("schedules.weekend.outlines"),
            t("schedules.weekend.speaker.0"),
            t("list.congregation.title"),
          ].join(" | ")}
          onChange={selectTSC}
          autoFocus
          minLength={1}
          emptyLabel={<></>}
          filterBy={filterLogic}
          renderMenuItemChildren={renderElements}
          options={props.speakers
            .flatMap((s) => {
              return s.public_talks.map((pt) => {
                const tsc: TSC = {
                  pt: pt,
                  speaker: s,
                  label: "", // not used, but must have
                };
                return tsc;
              });
            })
            .sort(speakerSearchCompare)}
        />
      </Modal.Body>
    </Modal>
  );
}
