import { QueryClient } from "@tanstack/react-query";
import QueryKeys from "../api/queryKeys";
import { AVAttendantAssignment } from "../types/scheduling/avAttendant";

export function updateAVACaches(queryClient: QueryClient, langGroupId: number, newAVAs: AVAttendantAssignment[]) {
  if (newAVAs.length === 0) return;
  queryClient.setQueriesData<AVAttendantAssignment[]>(
    { queryKey: [QueryKeys.AVAttendantAssignment, langGroupId] },
    (old) => {
      if (!old) return newAVAs;
      //combine new and old
      const newAvaIds = new Set<number>(newAVAs.map((ava) => ava.id));
      const updatedCache: AVAttendantAssignment[] = newAVAs;
      old.forEach((ava) => {
        if (!newAvaIds.has(ava.id)) {
          //add old in, if there isn't a new one with the same ID
          updatedCache.push(ava);
        }
      });
      return updatedCache;
    },
  );
}

export function deleteFromAVACaches(queryClient: QueryClient, langGroupId: number, assignmentId: number) {
  if (!assignmentId) return;
  queryClient.setQueriesData<AVAttendantAssignment[]>(
    { queryKey: [QueryKeys.AVAttendantAssignment, langGroupId] },
    (old) => (old ? old.filter((ava) => ava.id !== assignmentId) : []),
  );
}
