import { Document, Packer, Paragraph, ShadingType, Table, TableCell, TableRow, TextRun, WidthType } from "docx";
import saveAs from "file-saver";
import i18n, { t } from "i18next";
import { dateToString, stringToLocaleDate, wmDate } from "../../../helpers/dateHelpers";
import { Cong } from "../../../types/cong";
import { WMSchedule } from "../../../types/scheduling/weekend";
import { nameOfUser } from "../../../helpers/user";
import {
  PAGE_MARGIN,
  pageDimensionsTwip,
  standardFooter,
  standardHeader,
  StandardStyles,
  TABLE_NO_BORDERS,
} from "../../../helpers/docx";
import { CongSettings } from "../../../types/scheduling/settings";

export async function upcomingSpeakersDocx(wms: WMSchedule[], cong: Cong, settings?: CongSettings) {
  const wmsRows = wms.flatMap((wm) => {
    if (!wm.id || wm.event || wm.talk_mod) return [];
    return [...wmsRow(wm, cong)];
  });
  if (!wmsRows.length) {
    // to avoid a crash, put something there
    wmsRows.push(
      new TableRow({
        children: [new TableCell({ children: [new Paragraph({ text: "" })] })],
      }),
    );
  }

  const pageDimensions = pageDimensionsTwip(settings?.paper_size ?? "letter");
  const tableWidth = pageDimensions.width - PAGE_MARGIN * 2.5;
  const columnWidths: number[] = [tableWidth / 3, (tableWidth * 2) / 3];

  const doc = new Document({
    styles: StandardStyles,
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: PAGE_MARGIN,
              right: PAGE_MARGIN,
              bottom: PAGE_MARGIN,
              left: PAGE_MARGIN,
            },
          },
        },
        headers: {
          default: standardHeader(cong, t("schedules.weekend.speaker-list"), pageDimensions.width),
        },
        children: [
          new Table({
            borders: TABLE_NO_BORDERS,
            width: {
              size: tableWidth,
              type: WidthType.DXA,
            },
            columnWidths: columnWidths,
            rows: wmsRows,
          }),
        ],
        footers: {
          default: standardFooter(pageDimensions.width),
        },
      },
    ],
  });
  const blob = await Packer.toBlob(doc);
  const fileDate = wms[0]?.date ?? dateToString(new Date());
  saveAs(blob, `${fileDate}_${t("schedules.weekend.speaker-list")}.docx`);
}

function wmsRow(wms: WMSchedule, cong: Cong): TableRow[] {
  const weekHeader = new TableRow({
    cantSplit: true,
    children: [
      new TableCell({
        shading: { color: "5D719E", type: ShadingType.SOLID },
        columnSpan: 2,
        children: [
          new Paragraph({
            keepNext: true,
            children: [
              new TextRun({
                text: stringToLocaleDate(wmDate(wms.date, cong)),
                color: "FFFFFF",
                bold: true,
              }),
            ],
          }),
        ],
      }),
    ],
  });

  const speakerDetailRow = (label: string, value: string): TableRow => {
    return new TableRow({
      cantSplit: true,
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: label,
            }),
          ],
        }),
        new TableCell({
          children: [
            new Paragraph({
              text: value,
            }),
          ],
        }),
      ],
    });
  };

  const speakerTalkDetails: TableRow[] = [];
  if (wms.speaker.email) {
    speakerTalkDetails.push(speakerDetailRow(i18n.t("userinfo.email"), wms.speaker.email));
  }
  if (wms.speaker.cellphone) {
    speakerTalkDetails.push(speakerDetailRow(i18n.t("userinfo.cellphone"), wms.speaker.cellphone));
  }
  if (wms.speaker.homephone) {
    speakerTalkDetails.push(speakerDetailRow(i18n.t("userinfo.homephone"), wms.speaker.homephone));
  }
  if (wms.speaker.otherphone) {
    speakerTalkDetails.push(speakerDetailRow(i18n.t("userinfo.otherphone"), wms.speaker.otherphone));
  }
  if (wms.public_talk && (wms.public_talk.number || wms.public_talk?.title)) {
    const talkInfo = `#${wms.public_talk.number} — ${wms.public_talk.title}`;
    speakerTalkDetails.push(speakerDetailRow(i18n.t("schedules.general.talk"), talkInfo));
  }
  // blank row for spacing
  speakerTalkDetails.push(speakerDetailRow("", ""));

  const speakerInfo = new TableRow({
    cantSplit: true,
    children: [
      new TableCell({
        children: [
          new Paragraph({
            keepNext: true,
            children: [
              new TextRun({
                bold: true,
                text: wms.speaker.id ? nameOfUser(wms.speaker) : i18n.t("schedules.weekend.no-talk-scheduled"),
              }),
            ],
          }),
        ],
        columnSpan: 2,
      }),
    ],
  });

  return [weekHeader, speakerInfo, ...speakerTalkDetails];
}
