import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { gunzipSync, gzipSync, FlateErrorCode } from "fflate";
import { TFunction } from "i18next";
import { ChangeEvent, useContext, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Form, Row, Spinner, ToggleButton } from "react-bootstrap";
import {
  ArrowLeftRight,
  BoxArrowInUpRight,
  BoxArrowUpRight,
  ClipboardCheck,
  ClipboardPlus,
  ExclamationDiamond,
  FileEarmarkLock,
  FileEarmarkPdf,
  FileEarmarkText,
  FiletypeCsv,
} from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useCongSettings } from "../../../api/cong";
import QueryKeys from "../../../api/queryKeys";
import { useWMSpeakers, weekendApi } from "../../../api/weekend";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { dateToLocaleFormat, ISODateFormat, localizedTime, weekOf, wmDate } from "../../../helpers/dateHelpers";
import { decodeUnicode, encodeUnicode } from "../../../helpers/e2e";
import HourglassGlobals, { GlobalContext, HGContext, PythonI18nInterpolation } from "../../../helpers/globals";
import { nameOfUser } from "../../../helpers/user";
import { Cong } from "../../../types/cong";
import { Congregation, PublicTalkAssignment, Speaker } from "../../../types/scheduling/weekend";
import { UserAppt } from "../../../types/user";
import { QueryStatus } from "../../queryStatus";
import { CongName, ShowStatus, Status, speakerCompare, sortedWMCongregations } from "../common";
import { weekendExchangePDF } from "./wm_exchange_pdf";
import dayjs from "../../../helpers/dayjs";
import { saveCSV } from "../../../helpers/csv";
import { canUpdateWeekendSchedules } from "./wm_common";
import { CollatorSingleton } from "../../../helpers/locale";
import { selectedCong } from "../../../helpers/langGroups";

export function Data(props: { langGroupId: number }) {
  const { t, i18n } = useTranslation();
  const ctx = useContext(HGContext);
  let myCong = selectedCong(props.langGroupId) ?? ctx.globals.cong;
  const [data, setData] = useState("");
  const [copied, setCopied] = useState(false);
  const [radioValue, setRadioValue] = useState("cong");

  const settingsQuery = useCongSettings(props.langGroupId);
  const queryStatus = QueryStatus(settingsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!settingsQuery.data) return null;

  const congDisplayName = settingsQuery.data?.congregation_display_name ?? myCong.name;
  myCong = { ...myCong, name: congDisplayName };

  const createExchangePDF = async () => {
    try {
      const speakers = await weekendApi.getSpeakers(myCong.id, false, true, myCong.id);
      const myCongPublic = await weekendApi.getCongregation(myCong.id, myCong.id);
      await weekendExchangePDF(
        speakers
          .filter((s) => {
            if (myCong.id === props.langGroupId) return true;
            if (s.languageGroupId === props.langGroupId) return true;
          })
          .sort(speakerCompare),
        myCongPublic,
        settingsQuery.data?.congregation_pt_contact_info ?? "",
        myCong.locale.code,
      );
    } catch (err: any) {
      console.error("wm create speaker exchange PDF", err);
      HGBugsnagNotify("wmSpeakerExchangePDF", err);
    }
  };

  const handleHGExport = async () => {
    const rawData = encodeUnicode(JSON.stringify(await weekendApi.getExport(myCong.id)));
    const buf = new Uint8Array(rawData.length);
    for (let i = 0, strLen = rawData.length; i < strLen; i++) {
      buf[i] = rawData.charCodeAt(i);
    }

    const blobdata = new Blob([Uint8Array.from(gzipSync(buf)).buffer]);
    downloadFile(blobdata, myCong.name.replace(" ", "_") + ".hourglass");
  };

  const handleTXTExport = async () => {
    const data = await GenerateTXT(
      myCong,
      t,
      i18n,
      settingsQuery.data?.congregation_pt_contact_info ?? "",
      props.langGroupId,
    );
    downloadFile(new Blob([data]), myCong.name.replace(" ", "_") + ".txt");
  };

  const csvExportAll = async () => {
    try {
      const allSpeakers = await weekendApi.getSpeakersAll(props.langGroupId);
      exportAllSpeakersCsv(allSpeakers, t("schedules.weekend.cong-speakers"));
    } catch (err: any) {
      console.error("wm export all speakers csv", err);
      HGBugsnagNotify("wmExportAllSpeakersCSV", err);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Card className="my-2">
            <Card.Body>
              <Card.Title>
                <BoxArrowUpRight size="2em" className="me-3" />
                {t("schedules.weekend.export-speakers")}
              </Card.Title>
              <div className="d-grid my-2">
                <Form.Label>{t("schedules.weekend.export-share")}</Form.Label>
                <ButtonGroup>
                  <Button variant="primary" onClick={handleHGExport}>
                    <FileEarmarkLock size="1.5em" /> HG
                  </Button>
                  <Button variant="primary" onClick={createExchangePDF}>
                    <FileEarmarkPdf size="1.5em" /> PDF
                  </Button>
                  <Button variant="primary" onClick={handleTXTExport}>
                    <FileEarmarkText size="1.5em" /> TXT
                  </Button>
                </ButtonGroup>
              </div>
              <div className="mt-3">
                <Button onClick={csvExportAll}>
                  <FiletypeCsv size="1.5em" /> {t("schedules.fill.all")}
                </Button>
              </div>
            </Card.Body>
          </Card>
          {!HourglassGlobals.isE2E() && canUpdateWeekendSchedules() && (
            <ImportSpeakerData langGroupId={props.langGroupId} />
          )}
          <ExportTalkHistory langGroupId={props.langGroupId} />
        </Col>
        <Col>
          <Card className="my-2">
            <Card.Body>
              <Card.Title>
                <ButtonToolbar className="justify-content-between">
                  <div>
                    <ArrowLeftRight size="2em" className="me-3" />
                    {t("schedules.weekend.upcoming")}
                  </div>
                  <ButtonGroup className="mt-2">
                    {["cong", "speaker"].map((x, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={radioValue === x ? "primary" : "outline-primary"}
                        name="radio"
                        value={x}
                        checked={radioValue === x}
                        onChange={(e) => {
                          setRadioValue(e.currentTarget.value);
                          setData("");
                        }}
                      >
                        {x === "cong" ? t("list.congregation.title") : t("schedules.weekend.speaker.0")}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </ButtonToolbar>
              </Card.Title>
              {radioValue === "cong" ? (
                <CongDDM
                  data={data}
                  setData={setData}
                  buildTradeText={buildTradeText}
                  langGroupId={props.langGroupId}
                />
              ) : (
                <SpeakerDDM data={data} setData={setData} langGroupId={props.langGroupId} />
              )}

              <Form.Group className="cong_notes">
                <Form.Control
                  as="textarea"
                  className="font-monospace"
                  rows={12}
                  value={data}
                  onChange={() => {
                    return;
                  }}
                />
                <Button
                  className="edit_save_button"
                  variant={copied ? "success" : "light"}
                  onClick={() => {
                    navigator.clipboard.writeText(data);
                    setCopied(true);
                  }}
                >
                  {copied ? <ClipboardCheck color="white" size="1.2em" /> : <ClipboardPlus size="1.2em" />}
                  <span className="ms-1">{t("general.copy")}</span>
                </Button>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const GenerateTXT = async (myCong: Cong, t: TFunction, i18n: any, contactInfo: string, langGroupId: number) => {
  const cong = await weekendApi.getCongregation(myCong.id, myCong.id);
  const speakers = (await weekendApi.getSpeakers(cong.id, false, true, cong.id)).filter((s) => {
    if (myCong.id === langGroupId) return true;
    if (s.languageGroupId === langGroupId) return true;
  });

  const speakerText = (s: Speaker): string => {
    let txt = `${nameOfUser(s)}\n`;
    if (s.email) txt += `  ${t("userinfo.email")}: ${s.email}\n`;
    if (s.cellphone) txt += `   ${t("userinfo.cellphone")}: ${s.cellphone}\n`;
    if (s.homephone) txt += `   ${t("userinfo.homephone")}: ${s.homephone}\n`;
    if (s.otherphone) txt += `  ${t("userinfo.otherphone")}: ${s.otherphone}\n`;
    txt += `  ${t("schedules.weekend.outlines")}: ${s.public_talks.map((t) => `${t.number}`).join(", ")}\n`;

    return txt;
  };

  const eldersArray = speakers.filter((s) => s.appt === UserAppt.Elder).map((s) => speakerText(s));

  const mssArray = speakers.filter((s) => s.appt === UserAppt.MS).map((s) => speakerText(s));

  let elders = "";
  let mss = "";
  for (let i = 0; i < eldersArray.length; i++) {
    elders += eldersArray[i] + "\n";
  }
  for (let i = 0; i < mssArray.length; i++) {
    mss += mssArray[i] + "\n";
  }

  return `${cong.name.toUpperCase()}
${cong.address}
${t("general.phone")}: ${cong.phone}
${t("schedules.general.meeting-time")}: ${localizedTime(cong.wm_time, i18n.language, cong.timezone_name)}

${contactInfo}

**${t("statistics.publishers.elders")}**

${elders}

${!!mss ? `**${t("statistics.publishers.ministerial-servants")}**` : ""}

${mss}
`;
};

const downloadFile = (data: Blob, fileName: string) => {
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(data);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const buildTradeText = async (
  e: ChangeEvent<HTMLSelectElement>,
  ctx: GlobalContext,
  congs: Congregation[],
  setData: (set: string) => void,
  langGroupId: number,
) => {
  const myCong = ctx.globals.cong;
  const targetVal = Number(e.target.value);
  const name = ctx.globals.language_groups.find((lg) => lg.id === langGroupId)?.name || myCong.name;

  let tradeData = "";
  const otherCong = congs.filter((c) => (c.id === targetVal ? 1 : 0))[0];
  if (!otherCong) return;
  const trades = await weekendApi.getCongTrades(targetVal, langGroupId);
  const outgoingTitle = `${name} --> ${CongName(otherCong)}\n`;
  let otherCongName = "";
  try {
    otherCongName = otherCong.name;
  } catch (e) {}
  const incomingTitle = `${otherCongName} --> ${name}\n`;

  const assignmentText = (t: PublicTalkAssignment, wmdow: number): string => {
    if (!t.speaker) return "";
    let text = `  ${dateToLocaleFormat(weekOf(t.date, wmdow), ctx.globals.cong.country.datefmt)}  ${nameOfUser(
      t.speaker,
    )}`;
    if (t.public_talk?.number) text += ` (${t.public_talk?.number})`;
    return text;
  };

  tradeData += outgoingTitle;
  tradeData += trades.outbound.map((t) => assignmentText(t, otherCong.wm_dow)).join("\n");

  tradeData += "\n\n";
  tradeData += incomingTitle;
  tradeData += trades.inbound.map((t) => assignmentText(t, myCong.wmdow)).join("\n") || "  n/a";

  setData(tradeData);
};

const CongDDM = (props: {
  data?: string;
  setData?: (set: string) => void;
  setKhsCong?: (set: string) => void;
  cong?: Congregation;
  buildTradeText?: (
    e: ChangeEvent<HTMLSelectElement>,
    ctx: GlobalContext,
    congs: Congregation[],
    setData: (set: string) => void,
    langGroupId: number,
  ) => Promise<void>;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);

  const congregationsQuery = useQuery({
    queryKey: [QueryKeys.WMCongregations, props.langGroupId],

    queryFn: () => weekendApi.getCongregations(props.langGroupId),
  });

  const queryStatus = QueryStatus(congregationsQuery);
  if (queryStatus !== null) return queryStatus;

  const congs = sortedWMCongregations(congregationsQuery.data);
  return (
    <Form.Group className="mb-4" controlId="floatingSelect">
      <Form.Label>{t("list.congregation.title")}</Form.Label>
      <Form.Select
        onChange={async (e) => {
          if (props.setData && buildTradeText) await buildTradeText(e, ctx, congs, props.setData, props.langGroupId);
          if (props.setKhsCong) props.setKhsCong(e.target.value);
        }}
        value={props.cong?.guid}
        disabled={!!props.cong?.guid}
      >
        <option value={0} />
        {congs
          .filter((cong) => {
            return cong.id !== ctx.globals.cong.id;
          })
          .map((cong) => {
            return (
              <option key={cong.id} value={!!props.setKhsCong ? cong.guid : cong.id}>
                {CongName(cong)}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
};

const SpeakerDDM = (props: { data: string; setData: (set: string) => void; langGroupId: number }) => {
  const { t } = useTranslation();
  const ctx = useContext(HGContext);
  const myCong = useContext(HGContext).globals.cong;

  const speakersQuery = useWMSpeakers(myCong.id, props.langGroupId);

  const queryStatus = QueryStatus(speakersQuery);
  if (queryStatus !== null) return queryStatus;

  const speakers = speakersQuery.data ?? [];

  return (
    <Form.Group className="mb-4" controlId="floatingSelect">
      <Form.Label>{t("schedules.weekend.speaker.0")}</Form.Label>
      <Form.Select
        onChange={async (e) => {
          let data = "";
          const speakerId = Number(e.target.value);
          const user = speakers.find((s) => s.id === speakerId);
          if (!user) return;
          const name = nameOfUser(user);
          const asmts = await weekendApi.getSpeakerUpcomingAssignment(speakerId, props.langGroupId);

          const talksFor = t("schedules.weekend.upcoming-talks-speaker", {
            interpolation: PythonI18nInterpolation,
            speaker: name,
          });
          data += `${talksFor}\n\n`;
          data += asmts
            .map((a) => {
              return `${dateToLocaleFormat(
                weekOf(a.date, a.congregation?.wm_dow ?? 7),
                ctx.globals.cong.country.datefmt,
              )} - #${a.public_talk?.number} - ${a.congregation?.name ?? ""}`;
            })
            .join("\n");

          props.setData(data);
        }}
      >
        <option value={0} />
        {speakers
          .filter((speaker) => {
            return speaker.congregation?.id === myCong.id;
          })
          .sort((a: Speaker, b: Speaker) => {
            const nameA = a.lastname.toUpperCase();
            const nameB = b.lastname.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            return 1;
          })
          .map((speaker) => {
            return (
              <option key={speaker.id} value={speaker.id}>
                {nameOfUser(speaker, HourglassGlobals.nameFmt)}
              </option>
            );
          })}
      </Form.Select>
    </Form.Group>
  );
};

export const ImportSpeakerData = (props: { cong?: Congregation; langGroupId: number }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File>();
  const [khsCong, setKhsCong] = useState<string>(props.cong?.guid ?? "");
  const [status, setStatus] = useState<Status>(Status.Null);

  const isKHSfile = file?.name.toLowerCase().endsWith("khs") ?? false;

  const handleImport = async () => {
    if (!file) return;
    setStatus(Status.Pending);

    if (isKHSfile) {
      try {
        const u = await weekendApi.sendKhsExchange(file, khsCong, props.langGroupId);
        if (u.Updated) {
          await queryClient.invalidateQueries({
            queryKey: [QueryKeys.WMSpeakers],
          });
          setStatus(Status.Success);
        } else {
          setStatus(Status.Failure);
        }
      } catch (err: any) {
        setStatus(Status.Failure);
        console.error("khs import speaker data", err, file?.name);
        HGBugsnagNotify("importSpeakerKHS", err);
      }
    } else {
      try {
        const sef = JSON.parse(
          decodeUnicode(new TextDecoder().decode(gunzipSync(new Uint8Array(await file.arrayBuffer())))),
        );

        const u = await weekendApi.sendExport(sef, props.langGroupId);
        if (u.Updated) {
          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.WMSpeakers],
            }),
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.WMCongregations],
            }),
          ]);
          setStatus(Status.Success);
        } else {
          setStatus(Status.Failure);
        }
      } catch (err: any) {
        setStatus(Status.Failure);
        console.error("gzip import speaker data", err, file?.name);
        if (err.code !== FlateErrorCode.InvalidHeader) HGBugsnagNotify("importSpeakerGZ", err);
      }
    }
  };

  return (
    <Card className="my-2">
      {/* TODO: Don't allow import of self's file */}
      <Card.Body>
        <Card.Title>
          <BoxArrowInUpRight size="2em" className="me-3" />
          {t("schedules.weekend.import-speaker-data")}
        </Card.Title>
        <Form.Label>{t("schedules.weekend.import-hourglass-file")}</Form.Label>
        <p className="text-muted">
          <em>
            <ExclamationDiamond size="1.5em" className="me-1" />
            {t("schedules.weekend.import-overwrite")}
          </em>
        </p>
        <Form.Control
          type="file"
          onChange={(e) => {
            const x = e.target as HTMLInputElement;
            if (x && x.files) setFile(x.files[0]);
          }}
        />
        {isKHSfile && <CongDDM setKhsCong={setKhsCong} cong={props.cong} langGroupId={props.langGroupId} />}
        <div className="d-grid my-2">
          <Button variant="primary" disabled={isKHSfile ? !(file && khsCong) : !file} onClick={handleImport}>
            {t("general.save")}
          </Button>
          <span className="mt-1">
            <ShowStatus status={status} />
            {status === Status.Failure && <span className="ms-2">{t("import.error.parse")}</span>}
          </span>
        </div>
      </Card.Body>
    </Card>
  );
};

function ExportTalkHistory(props: { langGroupId: number }) {
  const { t } = useTranslation();
  const [showSpinner, setShowSpinner] = useState(false);
  const curMonth = dayjs().startOf("month");
  const start = curMonth.subtract(3, "years");
  const end = curMonth.add(18, "months");
  const getSchedulesMutation = useMutation({
    mutationFn: () =>
      weekendApi.getSchedules(start.format(ISODateFormat), end.format(ISODateFormat), props.langGroupId),
  });
  const myCong = useContext(HGContext).globals.cong;

  const getSchedules = async () => {
    setShowSpinner(true);
    const header = ["date", "congregation", "speaker", "speaker_congregation", "outline", "title"];
    try {
      const schedules = await getSchedulesMutation.mutateAsync();

      const data: any[][] = [];
      schedules.forEach((s) => {
        if (!s.public_talk || !s.public_talk.number || !s.speaker.id) return;
        const row: (string | number)[] = [
          wmDate(s.date, myCong),
          myCong.name,
          nameOfUser(s.speaker),
          s.speaker.congregation?.name ?? "",
          s.public_talk.number,
          s.public_talk.title,
        ];
        s.out.forEach((out) => {
          if (!out.public_talk || !out.speaker) return;
          data.push([
            wmDate(out.date, out.congregation ?? myCong),
            out.congregation?.name,
            nameOfUser(out.speaker),
            myCong.name,
            out.public_talk.number,
            out.public_talk.title,
          ]);
        });
        data.push(row);
      });

      saveCSV(header, data, "hourglass-speakers.csv");
    } catch (err: any) {
      HGBugsnagNotify("exportWMTalkHistory", err);
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <BoxArrowUpRight size="2em" className="me-3" />
          {t("schedules.weekend.stats.speaker-talk-history")}
        </Card.Title>
        <Button variant="primary" onClick={getSchedules} disabled={showSpinner}>
          <FiletypeCsv /> CSV
        </Button>
        {showSpinner && <Spinner className="ms-2" size="sm" animation="border" />}
      </Card.Body>
    </Card>
  );
}

function exportAllSpeakersCsv(speakers: Speaker[], filename: string) {
  const header = ["name", "congregation", "email", "cellphone", "homephone", "talks"];

  const data: string[][] = speakers
    .map((s) => {
      return [
        nameOfUser(s),
        s.congregation?.name ?? "",
        s.email ?? "",
        s.cellphone ?? "",
        s.homephone ?? "",
        s.public_talks
          ?.map((pt) => pt.number)
          .sort((a, b) => a - b)
          .join(",") ?? "",
      ];
    })
    .sort((row1, row2) => {
      if (!row1[1] || !row2[1]) return 0;
      // sort by congregation name then speaker name
      if (row1[1] !== row2[1]) return CollatorSingleton.getInstance().compare(row1[1], row2[1]);

      if (!row1[0] || !row2[0]) return 0;
      return CollatorSingleton.getInstance().compare(row1[0], row2[0]);
    });

  saveCSV(header, data, filename);
}
