import { QueryClient } from "@tanstack/react-query";
import QueryKeys from "../api/queryKeys";
import { territoryAddressCompare } from "../helpers/address";
import { Territory, TerritoryAddress, TerritoryRecord } from "../types/scheduling/territory";

export function updateTerritoryCache(queryClient: QueryClient, territory: Territory) {
  queryClient.setQueriesData<Territory[]>({ queryKey: [QueryKeys.Territories] }, (old) => {
    if (!old) return [territory];
    if (old.some((t) => t.id === territory.id)) return old.map((t) => (t.id === territory.id ? territory : t));
    return [...old, territory];
  });
}

export function deleteFromTerritoryCache(queryClient: QueryClient, territoryId: number) {
  queryClient.setQueriesData<Territory[]>({ queryKey: [QueryKeys.Territories] }, (old) =>
    old ? old.filter((t) => t.id !== territoryId) : [],
  );
}

export function sortAndLabelAddresses(ta: TerritoryAddress[]) {
  const sorted = ta.sort(territoryAddressCompare);
  const labeled = sorted.map((a, i) => ({ ...a, pinLabel: `${i + 1}` }));
  return [...labeled];
}

export function updateTerritoryAddressCache(queryClient: QueryClient, territoryId: number, address: TerritoryAddress) {
  queryClient.setQueriesData<TerritoryAddress[]>({ queryKey: [QueryKeys.TerritoryAddresses, territoryId] }, (old) => {
    if (!old) return [address];
    if (old.some((ta) => ta.id === address.id))
      return sortAndLabelAddresses(old.map((ta) => (ta.id === address.id ? address : ta)));
    return sortAndLabelAddresses([...old, address]);
  });
}

export function deleteFromTerritoryAddressCache(queryClient: QueryClient, territoryId: number, addressId: number) {
  queryClient.setQueriesData<TerritoryAddress[]>({ queryKey: [QueryKeys.TerritoryAddresses, territoryId] }, (old) =>
    old ? old.filter((ta) => ta.id !== addressId) : [],
  );
}

export function updateTerritoryRecordCache(queryClient: QueryClient, record: TerritoryRecord) {
  queryClient.setQueriesData<TerritoryRecord[]>({ queryKey: [QueryKeys.TerritoryRecordsAll] }, (old) => {
    if (!old) return [record];
    if (old.some((tr) => tr.id === record.id)) return old.map((r) => (r.id === record.id ? record : r));
    return [...old, record];
  });

  queryClient.setQueriesData<TerritoryRecord[]>({ queryKey: [QueryKeys.TerritoryRecordsLatest] }, (old) => {
    if (!old) return [record];
    if (old.some((tr) => tr.id === record.id)) return old.map((r) => (r.id === record.id ? record : r));
    return [...old, record];
  });
}

export function deleteFromTerritoryRecordCache(queryClient: QueryClient, recordId: number) {
  queryClient.setQueriesData<TerritoryRecord[]>({ queryKey: [QueryKeys.TerritoryRecordsAll] }, (old) =>
    old ? old.filter((ta) => ta.id !== recordId) : [],
  );
  queryClient.setQueriesData<TerritoryRecord[]>({ queryKey: [QueryKeys.TerritoryRecordsLatest] }, (old) =>
    old ? old.filter((ta) => ta.id !== recordId) : [],
  );
}
