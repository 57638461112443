import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Form, ListGroup, ListGroupItem, Modal, Row, Spinner, Table } from "react-bootstrap";
import { ArrowDown, ArrowDownUp, ArrowUp, DashSquare, PlusSquare } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useAbsence } from "../../api/absence";
import { useAVAssignments } from "../../api/avAttendant";
import { midweekApi } from "../../api/midweek";
import { useWMSchedules } from "../../api/weekend";
import {
  dateIsBetween,
  dateStringCompare,
  dateToString,
  getDayjs,
  mmDate,
  stringToDate,
  stringToLocaleDate,
  wmDate,
} from "../../helpers/dateHelpers";
import HourglassGlobals from "../../helpers/globals";
import { userCompare } from "../../helpers/user";
import { HourMs } from "../../helpers/util";
import { DateRange, ISODateString } from "../../types/date";
import { Absence } from "../../types/scheduling/absence";
import { AVAttendantAsset, AVAttendantAssignmentType } from "../../types/scheduling/avAttendant";
import { NotificationType } from "../../types/scheduling/meetings";
import {
  MidweekMeetingPart,
  MidweekMeetingPartAsset,
  MidweekMeetingPartType,
  MidweekScheduleInfo,
} from "../../types/scheduling/midweek";
import { WMCoreParts, WMCorePartTitleAsset, WMSchedule } from "../../types/scheduling/weekend";
import User, { UserGender } from "../../types/user";
import { QueryKeyBuilder } from "../../query/queryKeyBuilder";

export type AssignmentSummaryType =
  | MidweekMeetingPartType
  | AVAttendantAssignmentType
  | WMCoreParts
  | "publicTalk"
  | "publicTalkOut";

export type AssignmentSummary = {
  date: string;
  type: AssignmentSummaryType;
  userId: number;
  classroom?: number;
  assistantId?: number;
};

export type SortField = "name" | "all" | "type";

function mmScheduleToAssignments(
  si: MidweekScheduleInfo,
  partsById: Map<number, MidweekMeetingPart>,
): AssignmentSummary[] {
  const assignments: AssignmentSummary[] = [];
  const meetingDate = si.date;
  if (si.chairman) assignments.push({ date: meetingDate, type: MidweekMeetingPartType.Chairman, userId: si.chairman });
  if (si.chairman2)
    assignments.push({ date: meetingDate, type: MidweekMeetingPartType.Chairman2, userId: si.chairman2 });
  if (si.chairman3)
    assignments.push({ date: meetingDate, type: MidweekMeetingPartType.Chairman3, userId: si.chairman3 });
  if (si.openprayer)
    assignments.push({ date: meetingDate, type: MidweekMeetingPartType.OpenPrayer, userId: si.openprayer });
  if (si.cbs_reader)
    assignments.push({ date: meetingDate, type: MidweekMeetingPartType.CBSReader, userId: si.cbs_reader });
  if (si.closeprayer)
    assignments.push({ date: meetingDate, type: MidweekMeetingPartType.ClosePrayer, userId: si.closeprayer });
  si.tgw.concat(si.fm, si.lac).forEach((sa) => {
    const part = partsById.get(sa.part);
    if (part)
      assignments.push({
        date: meetingDate,
        type: part.type,
        userId: sa.assignee,
        classroom: sa.classroom,
        assistantId: sa.assistant,
      });
    if (sa.assistant)
      assignments.push({
        date: meetingDate,
        type: MidweekMeetingPartType.HH,
        userId: sa.assistant,
        classroom: sa.classroom,
        assistantId: sa.assignee,
      });
  });

  return assignments;
}

function wmScheduleToAssignments(wm: WMSchedule, congId: number): AssignmentSummary[] {
  const assignments: AssignmentSummary[] = [];
  const meetingDate = wm.date;
  if (wm.wm_chairman) assignments.push({ date: meetingDate, type: WMCoreParts.Chairman, userId: wm.wm_chairman });
  if (wm.wm_reader) assignments.push({ date: meetingDate, type: WMCoreParts.Reader, userId: wm.wm_reader });
  if (wm.host) assignments.push({ date: meetingDate, type: WMCoreParts.Host, userId: wm.host });
  if (wm.wt_conductor) assignments.push({ date: meetingDate, type: WMCoreParts.WTConductor, userId: wm.wt_conductor });
  if (wm.closeprayer) assignments.push({ date: meetingDate, type: WMCoreParts.ClosingPrayer, userId: wm.closeprayer });

  if (wm.speaker && wm.speaker.userId && wm.speaker.congregation?.id === congId) {
    assignments.push({ date: meetingDate, type: "publicTalk", userId: wm.speaker.userId });
  }
  if (Array.isArray(wm.out)) {
    wm.out.forEach((pta) => {
      if (pta.speaker?.userId)
        assignments.push({ date: meetingDate, type: "publicTalkOut", userId: pta.speaker.userId });
    });
  }

  return assignments;
}

//based on range, calculate a range of dates to look at when viewing assignments: 6 months before the beginning
//and 1 month after the end
export function assignmentMapDateRange(range: DateRange): DateRange {
  return {
    from: dateToString(getDayjs(range.from).subtract(6, "month").toDate()),
    to: dateToString(getDayjs(range.to).add(1, "month").toDate()),
  };
}

// retrieve all the assignments, so they can be seen when searching for a new assignee
export function useAssignmentMap(
  dateRange: DateRange,
  enabled: boolean,
  langGroupId: number,
): Map<number, AssignmentSummary[]> {
  const myDateRange = assignmentMapDateRange(dateRange);

  //note that these queries have long staletimes. it's important that these query keys be updated
  //whenever assignments are changed so that this continues to reflect current data
  const mmsQuery = useQuery({
    queryKey: QueryKeyBuilder.MidweekInfo(langGroupId, myDateRange.from, myDateRange.to),
    queryFn: () => midweekApi.getMeetingsInfo(myDateRange.from, myDateRange.to, langGroupId, true),
    enabled: enabled,
    staleTime: HourMs,
  });
  const wmQuery = useWMSchedules(myDateRange.from, myDateRange.to, langGroupId, {
    enabled: enabled,
    staleTime: HourMs,
  });
  const avaQuery = useAVAssignments(myDateRange.from, myDateRange.to, langGroupId, {
    enabled: enabled,
    staleTime: HourMs,
  });

  const assignmentMap = new Map<number, AssignmentSummary[]>();
  if (!enabled) return assignmentMap;

  const mergeAssignments = (assignments: AssignmentSummary[]) => {
    assignments.forEach((a) => {
      const entry = assignmentMap.get(a.userId);
      if (!entry) assignmentMap.set(a.userId, [a]);
      else entry.push(a);
    });
  };

  if (mmsQuery.data && Array.isArray(mmsQuery.data.meetings) && Array.isArray(mmsQuery.data.schedules)) {
    const partsById = new Map<number, MidweekMeetingPart>();
    //get a mapping of partId to part, for easy lookup
    mmsQuery.data.meetings.forEach((mi) => mi.tgw.concat(mi.fm, mi.lac).forEach((m) => partsById.set(m.id, m)));
    mmsQuery.data.schedules.forEach((si) => mergeAssignments(mmScheduleToAssignments(si, partsById)));
  }

  if (wmQuery.data && Array.isArray(wmQuery.data)) {
    wmQuery.data.forEach((wm) => mergeAssignments(wmScheduleToAssignments(wm, langGroupId)));
  }

  if (avaQuery.data && Array.isArray(avaQuery.data)) {
    const avaAssignments = avaQuery.data.flatMap((ava): AssignmentSummary | [] => {
      if (!ava.assignee) return [];
      return {
        date: ava.date,
        type: ava.type,
        userId: ava.assignee,
      };
    });
    mergeAssignments(avaAssignments);
  }

  return assignmentMap;
}

// summaryDate returns the date that an assignment occurred, depending on its type
function summaryDate(as: AssignmentSummary | null): ISODateString | undefined {
  if (!as) return undefined;

  const isAVA = Object.values(AVAttendantAssignmentType).includes(as.type as AVAttendantAssignmentType);
  const isMM = Object.values(MidweekMeetingPartType).includes(as.type as MidweekMeetingPartType);
  const isWM =
    Object.values(WMCoreParts).includes(as.type as WMCoreParts) ||
    as.type === "publicTalk" ||
    as.type === "publicTalkOut";

  if (isAVA) {
    const dow = getDayjs(as.date).isoWeekday();
    return dow >= 6 ? wmDate(as.date, HourglassGlobals.cong) : mmDate(as.date, HourglassGlobals.cong);
  } else if (isMM) {
    return mmDate(as.date, HourglassGlobals.cong);
  } else if (isWM) {
    return wmDate(as.date, HourglassGlobals.cong);
  }

  return as.date;
}

const attendantTypes: Set<AssignmentSummaryType> = new Set([
  AVAttendantAssignmentType.Attendant,
  AVAttendantAssignmentType.SecurityAttendant,
  AVAttendantAssignmentType.ZoomAttendant,
]);

export function RecentAssignments(props: {
  date: ISODateString;
  possibleAssignees: User[];
  allGenderPossibleAssignees?: User[];
  partType: MidweekMeetingPartType | AVAttendantAssignmentType | WMCoreParts;
  assignmentMap: Map<number, AssignmentSummary[]>;
  selected: number;
  setSelected: (value: number) => any;
  userMap: Map<number, User>;
  categoryOnly: boolean;
  genderFilter?: UserGender;
}) {
  const { t } = useTranslation();
  const absencesQuery = useAbsence();
  const [wraper] = useAutoAnimate<HTMLDivElement>();
  const [sortField, setSortField] = useState<SortField>("type");
  const [sortAscending, setSortAscending] = useState(true);
  const [expandedUser, setExpandedUser] = useState(0);

  const proxyDateStringCompare = (a: string | undefined, b: string | undefined) => {
    const _a = a === undefined ? "1970-01-01" : a;
    const _b = b === undefined ? "1970-01-01" : b;
    return dateStringCompare(_a, _b);
  };

  const absenceIncludes = (absence: Absence, date: ISODateString) => {
    const aDate = stringToDate(absence.start);
    const bDate = stringToDate(absence.end);
    return dateIsBetween(stringToDate(date), aDate, bDate);
  };
  const category = partCategory(props.partType);

  const recents = useMemo(() => {
    const possibleAssignees = props.allGenderPossibleAssignees
      ? props.allGenderPossibleAssignees
      : props.possibleAssignees;

    return possibleAssignees
      .filter((u) => (props.genderFilter ? u.sex === props.genderFilter : true))
      .map((u) => {
        const userParts = props.assignmentMap.get(u.id);
        const reverseDateSorted = (assignments: AssignmentSummary[]): AssignmentSummary[] => {
          return assignments
            .sort((a, b) => dateStringCompare(summaryDate(a) ?? a.date, summaryDate(b) ?? b.date))
            .reverse();
        };

        const all = !!userParts?.length ? reverseDateSorted(userParts)[0] ?? null : null;
        const userPartsThisType = !!userParts?.length
          ? userParts.filter((a) => {
              if (props.partType === AVAttendantAssignmentType.Attendant) return attendantTypes.has(a.type);
              return a.type === props.partType;
            })
          : [];
        const type = !!userPartsThisType.length ? reverseDateSorted(userPartsThisType)[0] ?? null : null;

        const isAbsent = !!absencesQuery.data?.some((a) => a.userId === u.id && absenceIncludes(a, props.date));

        return {
          id: u.id,
          name: u.displayName,
          all,
          type,
          isAbsent,
        };
      })
      .sort((a, b) => {
        switch (sortField) {
          case "all":
            if (sortAscending) return proxyDateStringCompare(summaryDate(a.all), summaryDate(b.all));
            else return proxyDateStringCompare(summaryDate(b.all), summaryDate(a.all));
          case "type":
            if (sortAscending) return proxyDateStringCompare(summaryDate(a.type), summaryDate(b.type));
            else return proxyDateStringCompare(summaryDate(b.type), summaryDate(a.type));
          default:
            if (sortAscending) return a.name.localeCompare(b.name);
            else return b.name.localeCompare(a.name);
        }
      });
  }, [
    absencesQuery.data,
    props.assignmentMap,
    props.partType,
    props.possibleAssignees,
    props.allGenderPossibleAssignees,
    sortAscending,
    sortField,
    props.date,
    props.genderFilter,
  ]);

  const partType = (type: AssignmentSummaryType): string => {
    const category = partCategory(type);
    if (category === NotificationType.Midweek) {
      const mmType = type as MidweekMeetingPartType;
      const title = t(MidweekMeetingPartAsset[mmType]);
      if (mmType === MidweekMeetingPartType.Chairman) return `${title} - ${t("conganalysis.attendance.midweek")}`;
      return title;
    }
    if (category === NotificationType.AVAttendant) {
      return t(AVAttendantAsset[type as AVAttendantAssignmentType]);
    }
    switch (type) {
      case "publicTalk":
      case "publicTalkOut":
        return t("schedules.weekend.speaker.0");
      default:
        break;
    }
    if (category === NotificationType.WeekendCRH) {
      const wmType = type as WMCoreParts;
      const title = t(WMCorePartTitleAsset[wmType]);
      // differentiate between midweek and weekend chairman
      if (wmType === WMCoreParts.Chairman) return `${title} - ${t("conganalysis.attendance.weekend")}`;
      return title;
    }

    return type;
  };

  const setSortingColumn = (col: SortField) => {
    if (sortField === col) {
      setSortAscending(!sortAscending);
    } else {
      setSortField(col);
      setSortAscending(true);
    }
  };

  const SortingIcon = (props: { field: SortField }) => {
    if (sortField !== props.field) {
      return <ArrowDownUp className="me-1" />;
    }

    if (sortAscending) {
      return <ArrowDown className="me-1" />;
    }
    return <ArrowUp className="me-1" />;
  };

  const toggleExpandedUser = (id: number) => {
    if (expandedUser === id) setExpandedUser(0);
    else setExpandedUser(id);
  };

  const AssignmentDetails = (adProps: { asmt: AssignmentSummary }) => {
    // this adds some additional details about the assignment. for now, it's just the assistant
    if (adProps.asmt.assistantId) {
      const assistant = props.userMap.get(adProps.asmt.assistantId);
      const isAssistant = adProps.asmt.type === "hh";
      // if this is the assistant, we want to display the type of part it was. so we need to find the student assignment
      const student =
        assistant && isAssistant
          ? props.assignmentMap
              .get(assistant.id)
              ?.find(
                (as) =>
                  as.date === adProps.asmt.date &&
                  as.classroom === adProps.asmt.classroom &&
                  as.userId === assistant.id &&
                  as.assistantId === adProps.asmt.userId &&
                  as.type !== "hh",
              )
          : undefined;
      if (assistant) {
        return (
          <span style={{ backgroundColor: "initial" }}>
            {student && ` – ${partType(student.type)}`} (
            <Badge bg="light" text="dark" pill className="me-1">
              {isAssistant ? t("schedules.midweek.student") : t("schedules.midweek.assistant")}
            </Badge>
            {assistant.displayName})
          </span>
        );
      }
    }
    return null;
  };

  const AssignmentHistory = (ahProps: { recentId: number }) => {
    return (
      <Table size="sm" className="ms-4 mt-1 recent-assignments">
        <tbody>
          {props.assignmentMap
            .get(ahProps.recentId)
            ?.filter((a) => (props.categoryOnly ? partCategory(a.type) === category : true))
            .sort((a, b) => dateStringCompare(summaryDate(a) ?? a.date, summaryDate(b) ?? b.date))
            .reverse()
            .slice(0, 20)
            .map((a, i) => (
              <tr key={i}>
                <td className={summaryDate(a) === props.date ? "bold" : undefined}>
                  {stringToLocaleDate(summaryDate(a))}
                </td>
                <td>
                  {partType(a.type)} <AssignmentDetails asmt={a} />
                  {!!a.classroom && (
                    <Badge className="ms-1" bg="secondary" text="light" pill>
                      {a.classroom + 1}
                    </Badge>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };

  return (
    <ListGroup ref={wraper}>
      <ListGroupItem>
        <Row className="bold cursor-pointer ">
          <Col className="overflow-hidden text-nowrap text-truncate" onClick={() => setSortingColumn("name")}>
            <SortingIcon field="name" />
            {t("general.name")}
          </Col>
          <Col
            xs="4"
            md="3"
            lg="2"
            className="overflow-hidden text-nowrap text-truncate"
            onClick={() => setSortingColumn("all")}
          >
            <SortingIcon field="all" />
            {t("schedules.fill.all")}
          </Col>
          {!!props.partType && (
            <Col
              xs="4"
              md="3"
              lg="2"
              className="overflow-hidden text-nowrap text-truncate"
              onClick={() => setSortingColumn("type")}
            >
              <SortingIcon field="type" />
              {partType(props.partType)}
            </Col>
          )}
        </Row>
      </ListGroupItem>
      {recents.map((r) => {
        return (
          <ListGroupItem
            key={r.id}
            action
            onClick={() => props.setSelected(r.id)}
            disabled={props.partType !== WMCoreParts.Host && r.isAbsent}
          >
            <Row className={props.selected === r.id ? "text-bold" : ""}>
              <Col>
                <span
                  className="me-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleExpandedUser(r.id);
                  }}
                >
                  {r.id === expandedUser ? <DashSquare /> : <PlusSquare />}
                </span>
                {r.name}
                {r.isAbsent && " *"}
              </Col>
              <Col xs="4" md="3" lg="2">
                {!!summaryDate(r.all) ? stringToLocaleDate(summaryDate(r.all)) : "-"}
              </Col>
              {!!props.partType && (
                <Col xs="4" md="3" lg="2">
                  {!!summaryDate(r.type) ? stringToLocaleDate(summaryDate(r.type)) : "-"}
                </Col>
              )}
            </Row>
            {r.id === expandedUser && <AssignmentHistory recentId={r.id} />}
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

export function SearchAssigneeModal(props: {
  date: string;
  assignee?: User;
  possibleAssignees: User[];
  allGenderPossibleAssignees?: User[];
  partType: MidweekMeetingPartType | AVAttendantAssignmentType | WMCoreParts;
  show: boolean;
  setShow: (show: boolean) => void;
  assign: (userId: number) => Promise<void>;
  assignmentMap: Map<number, AssignmentSummary[]>;
  heading: React.JSX.Element;
  userMap: Map<number, User>;
}) {
  const { t } = useTranslation();
  const [categoryOnly, setCategoryOnly] = useState(false);
  const [assistantOnly, setAssistantOnly] = useState(false);
  const [genderFilter, setGenderFilter] = useState<UserGender>();
  const [pending, setPending] = useState(false);
  const [selected, setSelected] = useState(props.assignee?.id ?? 0);

  useEffect(() => {
    //if the assignee in props changes, that means a new person was selected. we need to update our state to reflect it.
    setSelected(props.assignee?.id ?? 0);
  }, [props.assignee]);

  const possibleAssignees = props.assignee
    ? props.possibleAssignees.concat(props.assignee).sort(userCompare)
    : props.possibleAssignees;

  const allGenderPossibleAssignees =
    props.assignee && props.allGenderPossibleAssignees
      ? props.allGenderPossibleAssignees.concat(props.assignee).sort(userCompare)
      : props.allGenderPossibleAssignees;

  const hasAssistant = (): boolean => {
    return (
      props.partType === MidweekMeetingPartType.InitialCall ||
      props.partType === MidweekMeetingPartType.RV ||
      props.partType === MidweekMeetingPartType.StudentBibleStudy
    );
  };

  const handleClose = () => {
    props.setShow(false);
    setPending(false);
    setCategoryOnly(false);
    setGenderFilter(undefined);
  };

  const assignPart = async () => {
    setPending(true);
    await props.assign(selected);
    handleClose();
  };

  const getCategoryName = (): string | undefined => {
    switch (partCategory(props.partType)) {
      case NotificationType.Midweek:
        return t("nav.schedules.meetings.midweek");
      case NotificationType.WeekendCRH:
        return t("nav.schedules.meetings.weekend");
      case NotificationType.AVAttendant:
        return t("schedules.ava.title");
    }
  };

  const categoryName = getCategoryName();
  const partType = assistantOnly ? MidweekMeetingPartType.HH : props.partType;

  return (
    <Modal show={props.show} onHide={handleClose} size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{stringToLocaleDate(props.date)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.heading}

        <div className="d-flex justify-content-between">
          <Form.Group className="mb-1">
            <Form.Label>{t("userinfo.gender.0")}</Form.Label>
            <Form.Select
              onChange={(e) => {
                switch (e.currentTarget.value) {
                  case UserGender.Female:
                    setGenderFilter(UserGender.Female);
                    break;
                  case UserGender.Male:
                    setGenderFilter(UserGender.Male);
                    break;
                  default:
                    setGenderFilter(undefined);
                }
              }}
              value={genderFilter ?? ""}
            >
              <option value="" />
              <option value={UserGender.Male}>{t("userinfo.gender.male")}</option>
              <option value={UserGender.Female}>{t("userinfo.gender.female")}</option>
            </Form.Select>
          </Form.Group>
          <div>
            <b className="me-1">{t("contactlist.filter-by")}</b>
            {!!categoryName && (
              <Form.Check
                id="typeOnly"
                checked={categoryOnly}
                onChange={() => setCategoryOnly(!categoryOnly)}
                label={categoryName}
                className="me-4"
              />
            )}
            {hasAssistant() && (
              <Form.Check
                id="assistantOnly"
                checked={assistantOnly}
                onChange={() => setAssistantOnly(!assistantOnly)}
                label={t("schedules.midweek.assistant")}
                className="me-4"
              />
            )}
          </div>
        </div>
        <RecentAssignments
          date={props.date}
          assignmentMap={props.assignmentMap}
          possibleAssignees={possibleAssignees}
          allGenderPossibleAssignees={allGenderPossibleAssignees}
          partType={partType}
          selected={selected}
          setSelected={setSelected}
          userMap={props.userMap}
          categoryOnly={categoryOnly}
          genderFilter={genderFilter}
        />
      </Modal.Body>
      <Modal.Footer>
        {pending && <Spinner animation="border" size="sm" className="me-2" />}
        <Button
          variant="primary"
          onClick={assignPart}
          disabled={pending || !selected || selected === props.assignee?.id}
        >
          {t("schedules.assignment.assign")}
        </Button>
        <Button variant="primary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function partCategory(partType: AssignmentSummaryType): NotificationType | undefined {
  if (Object.values(MidweekMeetingPartType).includes(partType as MidweekMeetingPartType)) {
    return NotificationType.Midweek;
  }
  if (Object.values(AVAttendantAssignmentType).includes(partType as AVAttendantAssignmentType)) {
    return NotificationType.AVAttendant;
  }
  if (
    Object.values(WMCoreParts).includes(partType as WMCoreParts) ||
    partType === "publicTalk" ||
    partType === "publicTalkOut"
  ) {
    return NotificationType.WeekendCRH;
  }
}
