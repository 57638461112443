import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormEvent, KeyboardEvent, useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  ArrowLeftRight,
  ArrowRepeat,
  ClipboardCheck,
  ClipboardPlus,
  ExclamationTriangleFill,
  HouseFill,
  Plus,
  Question,
  QuestionCircleFill,
  Search,
} from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { congregationApi, useCongSettings } from "../../../api/cong";
import QueryKeys from "../../../api/queryKeys";
import { useUsers } from "../../../api/user";
import { useWMSpeakers, weekendApi } from "../../../api/weekend";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { localizedDayOfWeek } from "../../../helpers/dateHelpers";
import { ErrType, getErrorType, getStatusCode } from "../../../helpers/errors";
import HourglassGlobals, { HGContext, PythonI18nInterpolation } from "../../../helpers/globals";
import { containsFuzzy, getLocaleWithCode, t } from "../../../helpers/locale";
import { nameOfUser } from "../../../helpers/user";
import { NBSP, guidRegex, shallowEqualObjects } from "../../../helpers/util";
import { updateSpeakerCache, updateWMCongregationCache } from "../../../query/meetings";
import { UpdateStatus } from "../../../types/scheduling/meetings";
import {
  Congregation,
  NewCongInput,
  PublicSpeakerInput,
  PublicTalk,
  Speaker,
  SpeakerDirectExchange,
} from "../../../types/scheduling/weekend";
import User, { UserAppt } from "../../../types/user";
import { ValidationErrorDetail } from "../../../types/validation";
import { Danger } from "../../alerts";
import { EditButton } from "../../buttons";
import { QueryStatus } from "../../queryStatus";
import { congShareHelp, meetingSearchWindow } from "../../register/congInfo";
import RouteLeavingGuard, { WarningDialog } from "../../routeLeavingGuard";
import { SaveResult, SaveStatus } from "../../saveStatus";
import { SearchFilter } from "../../searchFilter";
import { CongName, ShowStatus, Status, congCompare, speakerCompare } from "../common";
import { HourglassTimePicker } from "../date";
import { SpeakerSearchModal } from "./wm_edit_week";
import { getMyCongAndGroupIds } from "../../../helpers/cong";
import { BlockAcceptModal, canUpdateWeekendSchedules, CongWeekendMeetingTime, SpeakerName } from "./wm_common";
import { AddSpeakerModal } from "./wm_cong_speakers_add";

export function CongSpeakers(props: { langGroupId: number }) {
  const { t } = useTranslation();
  const myCong = useContext(HGContext).globals.cong;
  const [cong, setCong] = useState({} as Congregation);
  const [speaker, setSpeaker] = useState({} as Speaker);
  const [tempSpeaker, setTempSpeaker] = useState({} as Speaker);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const [showTalkModal, setShowTalkModal] = useState(false);
  const [showAddCongModal, setShowAddCongModal] = useState(false);
  const [showAddSpeakerModal, setShowAddSpeakerModal] = useState(false);
  const [showLocalSpeakerWarning, setShowLocalSpeakerWarning] = useState(false);
  const [showDeleteCongModal, setShowDeleteCongModal] = useState(false);
  const [showMoveSpeakerModal, setShowMoveSpeakerModal] = useState(false);
  const [showDeleteSpeakerModal, setShowDeleteSpeakerModal] = useState(false);
  const [notes, setNotes] = useState("");
  const [notesEditModeEnabled, setNotesEditModeEnabled] = useState(false);
  const [enableEditNameMode, setEnableEditNameMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [showDirtyWarning, setShowDirtyWarning] = useState(false);
  const [pendingSpeaker, setPendingSpeaker] = useState<Speaker>();
  const [speakerIsDirect, setSpeakerIsDirect] = useState(false);
  const [validationErrors, setValidationErrors] = useState<(keyof Speaker)[]>([]);
  const [hasASMTs, setHasASMTs] = useState(false);
  const [congFilter, setCongFilter] = useState("");
  const [speakerFilter, setSpeakerFilter] = useState("");
  const [showSpeakerSearchModal, setShowSpeakerSearchModal] = useState(false);
  const allSpeakersQuery = useQuery({
    queryKey: [QueryKeys.WMAllSpeakers, props.langGroupId],
    queryFn: () => weekendApi.getSpeakersAll(props.langGroupId),
    enabled: showSpeakerSearchModal,
  });

  const congregationQuery = useQuery({
    queryKey: [QueryKeys.WMCongregations, props.langGroupId],

    queryFn: () => weekendApi.getCongregations(props.langGroupId),
  });
  const talksQuery = useQuery({
    queryKey: [QueryKeys.WMTalks],
    queryFn: () => weekendApi.getTalks(undefined, props.langGroupId),
    meta: { clearStoredLangGroupOn404: true },
  });
  const speakerQuery = useWMSpeakers(cong.id, props.langGroupId, false, false, {
    meta: { clearStoredLangGroupOn404: true },
  });
  const usersQuery = useUsers();
  const queryClient = useQueryClient();
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    setDirty(!shallowEqualObjects(speaker, tempSpeaker));
  }, [speaker, tempSpeaker]);

  const queryStatus = QueryStatus(talksQuery, congregationQuery, speakerQuery, usersQuery);
  if (queryStatus !== null) return queryStatus;
  if (!talksQuery.data || !congregationQuery.data || !speakerQuery.data || !usersQuery.data) return null;

  const myCongAndGroupIds = getMyCongAndGroupIds();

  const handleSpeakerInfoUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValidationErrors([]);
    if (!tempSpeaker.congregation) {
      return;
    }

    const form = e.currentTarget;
    if (!form.checkValidity()) return;

    const output: PublicSpeakerInput = {
      id: tempSpeaker.id,
      congregation: tempSpeaker.congregation?.id,
      firstname: tempSpeaker.firstname,
      lastname: tempSpeaker.lastname,
      middlename: tempSpeaker.middlename,
      suffix: tempSpeaker.suffix,
      descriptor: tempSpeaker.descriptor,
      email: tempSpeaker.email,
      cellphone: tempSpeaker.cellphone,
      homephone: tempSpeaker.homephone,
      otherphone: tempSpeaker.otherphone,
      appt: tempSpeaker.appt,
      out: tempSpeaker.out,
      public_talks: tempSpeaker.public_talks,
    };

    try {
      const savedSpeaker = await weekendApi.setSpeaker(output, undefined, props.langGroupId);
      setSaveResult(SaveResult.Success);
      updateSpeakerCache(queryClient, cong.id, props.langGroupId, tempSpeaker, savedSpeaker);
      setSpeaker(savedSpeaker);
      setTempSpeaker(savedSpeaker);
      setDirty(false);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("error updating public speaker", err);
      const errType = await getErrorType(err);
      switch (errType) {
        case ErrType.Validation:
          const respData = err.response?.data;
          if (Array.isArray(respData?.errorDetails)) {
            const validationFailures = respData.errorDetails.map((ve: ValidationErrorDetail) => ve.name);
            setValidationErrors(validationFailures);
          }
          break;
        default:
          HGBugsnagNotify("wmSetSpeaker", err);
      }
    }
  };

  const newSpeaker = (s: Speaker, force?: boolean) => {
    if (dirty && force !== true) {
      setShowDirtyWarning(true);
      setPendingSpeaker(s);
    } else {
      setSpeaker(s);
      setTempSpeaker(s);
      setSaveResult(SaveResult.None);
      setDirty(false);
      setSpeakerIsDirect(!!s.userId && !usersQuery.data.some((u) => u.id === s.userId));
    }
  };

  const disableEdit = myCongAndGroupIds.has(cong.id) || speakerIsDirect || !canUpdateWeekendSchedules();
  const disableEditOutlines = myCongAndGroupIds.has(cong.id) && !!props.langGroupId && props.langGroupId !== myCong.id;

  type SpeakerErrors = { [K in keyof Speaker]?: boolean };
  const validErrors: SpeakerErrors = {};
  validationErrors.forEach((ve) => {
    const vek = ve as keyof Speaker;
    validErrors[vek] = true;
  });

  // not a func because of rendering issues
  const SpeakerInfo = (
    <Form onSubmit={handleSpeakerInfoUpdate}>
      <Row>
        <Form.Group className="mb-4 col-12 col-md-4">
          <Form.Label>{t("general.first-name")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.firstname}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, firstname: e.target.value });
            }}
            value={tempSpeaker.firstname}
          />
        </Form.Group>

        <Form.Group className="mb-4 col-12 col-md-3">
          <Form.Label>{t("userinfo.name.middle")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.middlename}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, middlename: e.target.value });
            }}
            value={tempSpeaker.middlename ?? ""}
          />
        </Form.Group>

        <Form.Group className="mb-4 col-12 col-md-4">
          <Form.Label>{t("userinfo.name.last")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.lastname}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, lastname: e.target.value });
            }}
            value={tempSpeaker.lastname}
          />
        </Form.Group>

        <Form.Group className="mb-4 col-12 col-md">
          <Form.Label>{t("userinfo.name.suffix")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.suffix}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, suffix: e.target.value });
            }}
            value={tempSpeaker.suffix ?? ""}
          />
        </Form.Group>
      </Row>

      <Row>
        {HourglassGlobals.isE2E() && (
          <Form.Group className="mb-4 col-12 col-md">
            <Form.Label>
              {t("userinfo.label.0")}
              <span className="ms-2" title={t("userinfo.label.help")}>
                <QuestionCircleFill color="blue" />
              </span>
            </Form.Label>
            <Form.Control
              disabled={disableEdit}
              required
              isInvalid={validErrors.descriptor}
              onChange={(e) => {
                setTempSpeaker({ ...tempSpeaker, descriptor: e.target.value });
              }}
              value={tempSpeaker.descriptor ?? ""}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-4 col-12 col-md-3">
          <Form.Label>{t("userinfo.email")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.email}
            type="email"
            value={tempSpeaker.email ?? ""}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, email: e.target.value });
            }}
          />
        </Form.Group>

        <Form.Group className="mb-4 col-12 col-md-3">
          <Form.Label>{t("userinfo.cellphone")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.cellphone}
            minLength={4}
            maxLength={20}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, cellphone: e.target.value });
            }}
            value={tempSpeaker.cellphone ?? ""}
          />
        </Form.Group>

        <Form.Group className="mb-4 col-12 col-md-3">
          <Form.Label>{t("userinfo.homephone")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.homephone}
            minLength={4}
            maxLength={20}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, homephone: e.target.value });
            }}
            value={tempSpeaker.homephone ?? ""}
          />
        </Form.Group>

        <Form.Group className="mb-4 col-12 col-md-3">
          <Form.Label>{t("userinfo.otherphone")}</Form.Label>
          <Form.Control
            disabled={disableEdit}
            isInvalid={validErrors.otherphone}
            minLength={4}
            maxLength={20}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, otherphone: e.target.value });
            }}
            value={tempSpeaker.otherphone ?? ""}
          />
        </Form.Group>
      </Row>

      <Row className="mb-4">
        <Form.Group className="col-12 col-md-3">
          <Form.Label>{t("userinfo.appointment.0")}</Form.Label>
          <Form.Select
            disabled={disableEdit}
            isInvalid={validErrors.appt}
            value={tempSpeaker.appt ?? ""}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, appt: e.target.value });
            }}
          >
            <option />
            <option value={UserAppt.Elder}>{t("recordcard.elder")}</option>
            <option value={UserAppt.MS}>{t("recordcard.ministerialservant")}</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="col-md-9">
          {/*for spacing to line up with the appointment box*/}
          <Form.Label>&nbsp;</Form.Label>
          <Select
            className="react-select-container col mb-1"
            classNamePrefix="react-select"
            placeholder={t("schedules.weekend.outlines")}
            isDisabled={speakerIsDirect || !canUpdateWeekendSchedules() || disableEditOutlines}
            closeMenuOnSelect={false}
            isMulti={true}
            isClearable={false}
            styles={{
              //@ts-ignore weird type error with typescript 5.3.3
              menu: (s) => ({ ...s, zIndex: 1500 }),
            }}
            value={
              tempSpeaker.public_talks
                ?.sort((a, b) => a.number - b.number)
                .map((t) => {
                  return { value: t.number, label: `${t.number}` };
                }) || []
            }
            options={
              talksQuery.data
                ?.sort((a, b) => a.number - b.number)
                .map((t) => {
                  return { value: t.number, label: `${`${t.number}`.padStart(3, NBSP)} — ${t.title}` };
                }) || []
            }
            onChange={(e) => {
              setTempSpeaker({
                ...tempSpeaker,
                public_talks: e.map((t) => {
                  return { number: t.value } as PublicTalk;
                }),
              });
            }}
          />
          {!disableEditOutlines && (
            <Form.Text
              muted
              className="cursor-pointer"
              onClick={() => {
                setShowTalkModal(true);
              }}
            >
              {t("schedules.weekend.talk-bulk")}
            </Form.Text>
          )}
        </Form.Group>
      </Row>

      <ButtonToolbar className="justify-content-between mt-2">
        <ButtonGroup>
          <Button type="submit" variant="primary" disabled={!dirty || speakerIsDirect || !canUpdateWeekendSchedules()}>
            {t("general.save")}
          </Button>
          <Button
            variant="secondary"
            disabled={!dirty || speakerIsDirect || !canUpdateWeekendSchedules()}
            onClick={() => {
              setTempSpeaker(speaker);
            }}
          >
            {t("general.reset")}
          </Button>
          <Button
            disabled={disableEdit}
            variant="secondary"
            onClick={() => {
              setShowMoveSpeakerModal(true);
            }}
          >
            {t("schedules.weekend.move-assignment")}
          </Button>
          <Button
            disabled={disableEdit}
            variant="danger"
            onClick={() => {
              setShowDeleteSpeakerModal(true);
            }}
          >
            {t("general.delete")}
          </Button>
          <SaveStatus saveResult={saveResult} saveKey="wm_speaker" setSaveResult={setSaveResult} />
        </ButtonGroup>
        <h5>
          <Form.Check
            disabled={disableEdit}
            type="switch"
            label={t("schedules.weekend.outgoing-talks")}
            checked={tempSpeaker.out}
            onChange={(e) => {
              setTempSpeaker({ ...tempSpeaker, out: e.target.checked });
            }}
          />
        </h5>
      </ButtonToolbar>
    </Form>
  );

  const deleteSuccess = () => {
    setSpeaker({} as Speaker);
    setTempSpeaker({} as Speaker);
    setShowDirtyWarning(false);
  };

  const possibleMoveTargets = congregationQuery.data?.filter((c) => {
    if (!speaker) return false;
    if (c.direct_exchange === SpeakerDirectExchange.Yes) return false;
    if (c.id === speaker.congregation?.id) return false;
    return !myCongAndGroupIds.has(c.id);
  });

  return (
    <>
      <RouteLeavingGuard
        when={dirty}
        modalTitle={t("popup.lose-changes.title")}
        modalBody={t("popup.lose-changes.body %(unsaved_type)s", {
          interpolation: PythonI18nInterpolation,
          unsaved_type: t("schedules.weekend.speaker-info"),
        })}
        modalCancel={t("general.cancel")}
        modalConfirm={t("popup.lose-changes.button.dont-save")}
      />
      <WarningDialog
        show={showDirtyWarning}
        title={t("popup.lose-changes.title")}
        content={t("popup.lose-changes.body %(unsaved_type)s", {
          interpolation: PythonI18nInterpolation,
          unsaved_type: t("schedules.weekend.speaker-info"),
        })}
        cancelButtonText={t("general.cancel")}
        confirmButtonText={t("popup.lose-changes.button.dont-save")}
        onCancel={() => {
          setShowDirtyWarning(false);
          setPendingSpeaker(undefined);
        }}
        onConfirm={() => {
          if (pendingSpeaker) newSpeaker(pendingSpeaker, true);
          setPendingSpeaker(undefined);
          setShowDirtyWarning(false);
        }}
      />
      <TalksModal
        key={`${tempSpeaker.id}_${showTalkModal}`}
        tempSpeaker={tempSpeaker}
        show={showTalkModal}
        setShow={setShowTalkModal}
        setTempSpeaker={setTempSpeaker}
        canSave={!speakerIsDirect}
      />
      <DeleteCongModel
        cong={cong}
        show={showDeleteCongModal}
        setShow={setShowDeleteCongModal}
        setCong={setCong}
        refresh={congregationQuery.refetch}
        hasASMTs={hasASMTs}
        langGroupId={props.langGroupId}
      />
      <MoveSpeakerModal
        speaker={speaker}
        show={showMoveSpeakerModal}
        setShow={setShowMoveSpeakerModal}
        congregations={possibleMoveTargets}
        moveSuccess={async () => {
          setSpeaker({} as Speaker);
          setTempSpeaker({} as Speaker);
          await speakerQuery.refetch();
        }}
        langGroupId={props.langGroupId}
      />
      <DeleteSpeakerModel
        speaker={speaker}
        show={showDeleteSpeakerModal}
        setShow={setShowDeleteSpeakerModal}
        deleteSuccess={deleteSuccess}
        refresh={speakerQuery.refetch}
        langGroupId={props.langGroupId}
      />
      <AddCongModal show={showAddCongModal} setShow={setShowAddCongModal} langGroupId={props.langGroupId} />
      <AddSpeakerModal
        cong={cong}
        show={showAddSpeakerModal}
        setShow={setShowAddSpeakerModal}
        refresh={speakerQuery.refetch}
        newSpeaker={newSpeaker}
        langGroupId={props.langGroupId}
      />
      <LocalSpeakerWarning show={showLocalSpeakerWarning} setShow={setShowLocalSpeakerWarning} />
      <SpeakerSearchModal
        show={showSpeakerSearchModal}
        setShow={setShowSpeakerSearchModal}
        speakers={allSpeakersQuery.data ?? []}
        setSelectedTalk={() => {
          return;
        }}
        setSelectedSpeaker={(speakerId) => {
          const speaker = allSpeakersQuery.data?.find((s) => s.id === speakerId);
          if (speaker) newSpeaker(speaker);
        }}
        setSelectedCong={(congId) => {
          const cong = congregationQuery.data?.find((c) => c.id === congId);
          if (cong) setCong(cong);
        }}
        setOverrideCong={() => {
          return;
        }}
      />
      <Row>
        {!!cong.id && (
          <Card className="cong_speaker_card">
            <Card.Body>
              {!!cong.id && !speaker.id ? (
                <CongInfo
                  cong={cong}
                  notes={notes}
                  notesEditModeEnabled={notesEditModeEnabled}
                  enableEditNameMode={enableEditNameMode}
                  setShowDeleteCongModal={setShowDeleteCongModal}
                  setNotes={setNotes}
                  setNotesEditModeEnabled={setNotesEditModeEnabled}
                  setEnableEditNameMode={setEnableEditNameMode}
                  refresh={congregationQuery.refetch}
                  setHasASMTs={setHasASMTs}
                  langGroupId={props.langGroupId}
                />
              ) : speaker.id ? (
                SpeakerInfo
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        )}
      </Row>
      <Row className="d-flex justify-content-center mt-2 mb-2" xs="auto">
        <Button variant="outline-primary" onClick={() => setShowSpeakerSearchModal(true)}>
          <Search className="me-1" />
          {t("search.hint")}
        </Button>
      </Row>
      <Row>
        <Col>
          <h4 className="d-flex justify-content-between">
            {t("schedules.weekend.congregations")}
            <AddButton setShow={setShowAddCongModal} />
          </h4>
          <SearchFilter className="mb-1" setFilter={setCongFilter} filter={congFilter} />
          <CongList
            congs={
              congregationQuery.data.filter((c) => {
                return (c.custom_name ?? c.name).toLocaleLowerCase().includes(congFilter.toLocaleLowerCase());
              }) ?? []
            }
            scrollPos={scrollPos}
            setScrollPos={setScrollPos}
            cong={cong}
            setCong={setCong}
            setNotes={setNotes}
            setSpeaker={setSpeaker}
            setTempSpeaker={setTempSpeaker}
            setNotesEditModeEnabled={setNotesEditModeEnabled}
            setEnableEditNameMode={setEnableEditNameMode}
            langGroupId={props.langGroupId}
          />
        </Col>
        <Col>
          {!!cong.id && (
            <>
              <h4 className="d-flex justify-content-between">
                {t("schedules.weekend.cong-speakers")}
                {cong.direct_exchange !== SpeakerDirectExchange.Yes && (
                  <AddButton
                    setShow={!myCongAndGroupIds.has(cong.id) ? setShowAddSpeakerModal : setShowLocalSpeakerWarning}
                  />
                )}
              </h4>
              <SearchFilter className="mb-1" setFilter={setSpeakerFilter} filter={speakerFilter} />
              <SpeakerList
                speakers={speakerQuery.data.filter((s) => containsFuzzy(nameOfUser(s), speakerFilter)) ?? []}
                users={usersQuery.data}
                speaker={speaker}
                newSpeaker={newSpeaker}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

type UnlistedCong = {
  name: string;
  url: string;
  wmdow: number;
  wmtime: string;
};
const blankUnlisted: UnlistedCong = { name: "", url: "", wmdow: 7, wmtime: "10:00" };

const AddCongModal = (props: { show: boolean; setShow: (show: boolean) => void; langGroupId: number }) => {
  const { t, i18n } = useTranslation();
  const [inputText, setInputText] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const [errorMessage, setErrorMessage] = useState("");
  const [enableUnlisted, setEnableUnlisted] = useState(false);
  const [enableBrazilUnlistedSearch, setEnableBrazilUnlistedSearch] = useState(false);
  const [brazilUnlistedCongNumber, setBrazilUnlistedCongNumber] = useState("");
  const [unlisted, setUnlisted] = useState<UnlistedCong>(blankUnlisted);
  const queryClient = useQueryClient();
  const myCong = useContext(HGContext).globals.cong;
  const isBrazil = myCong.country.code.toLowerCase() === "br";

  const handleClose = () => {
    props.setShow(false);
    setShowSpinner(false);
    setSaveResult(SaveResult.None);
    setErrorMessage("");
    setUnlisted(blankUnlisted);
    setEnableUnlisted(false);
    setEnableBrazilUnlistedSearch(false);
    setBrazilUnlistedCongNumber("");
  };

  const handleUpdate = async () => {
    setShowSpinner(true);
    const input: NewCongInput = {
      guid: getGUID(),
      name: unlisted.name,
      weekendMeeting: {
        weekday: unlisted.wmdow,
        time: unlisted.wmtime,
      },
      notListed: enableUnlisted,
    };
    try {
      const resp = await weekendApi.addCong(input, props.langGroupId);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WMCongregations, props.langGroupId],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WMSpeakers, resp.congregationId],
        }),
      ]);
      setSaveResult(SaveResult.Success);
      setInputText("");
      handleClose();
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      switch (getStatusCode(err)) {
        case 409:
          setErrorMessage(t("schedules.weekend.cong-already-added"));
          setInputText("");
          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.WMCongregations, props.langGroupId],
            }),
            queryClient.invalidateQueries({
              queryKey: [QueryKeys.WMSpeakers],
            }),
          ]);
          break;
        default:
          console.error("error adding weekend cong", err);
          HGBugsnagNotify("addWMCong", err);
      }
    } finally {
      setShowSpinner(false);
    }
  };

  const findBrazilUnlisted = async () => {
    setSaveResult(SaveResult.None);
    const congNumber = Number(brazilUnlistedCongNumber);
    if (!congNumber) return;
    setShowSpinner(true);
    try {
      const resp = await congregationApi.getBrazilUnlisted(congNumber);
      setUnlisted({ ...unlisted, name: resp.name, url: resp.guid });
      setInputText(resp.guid);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      setUnlisted(blankUnlisted);
      setInputText("");
      switch (getStatusCode(err)) {
        case 429:
          break;
        default:
          console.error("error brazil unlisted search", err);
          HGBugsnagNotify("addWMCong_bru", err);
      }
    } finally {
      setShowSpinner(false);
    }
  };

  const getGUID = (): string => {
    const result = guidRegex.exec(inputText);
    if (!result || result.length !== 1) return "";
    return result[0];
  };

  const unlistedComplete = (): boolean => {
    return !!unlisted.name && !!unlisted.wmdow && !!unlisted.wmtime;
  };

  const curLocale = getLocaleWithCode(i18n.language);

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.add-congregation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!enableUnlisted && !enableBrazilUnlistedSearch && (
          <>
            <Button variant="info" size="sm" onClick={congShareHelp}>
              <Question color="white" size={16} />
            </Button>
            <Button variant="primary" size="sm" className="ms-2" onClick={() => meetingSearchWindow(curLocale.symbol)}>
              {t("search.hint")}
            </Button>
            {isBrazil && (
              <Button
                variant="secondary"
                size="sm"
                className="ms-2"
                onClick={() => {
                  setEnableUnlisted(true);
                  setEnableBrazilUnlistedSearch(true);
                }}
              >
                {t("register.cong-not-listed.title")}
              </Button>
            )}
            <Form.Control
              as="textarea"
              className="mt-2"
              value={inputText}
              onChange={(e) => {
                const input = e.currentTarget.value;
                if (input.trim().toLowerCase() === "jw.org=skip") {
                  setEnableUnlisted(true);
                  setErrorMessage("");
                  setSaveResult(SaveResult.None);
                  return;
                }
                setInputText(input);
                if (!input) {
                  setSaveResult(SaveResult.None);
                  setErrorMessage("");
                  return;
                }
                if (guidRegex.test(input)) {
                  setSaveResult(SaveResult.None);
                  setErrorMessage("");
                } else {
                  setSaveResult(SaveResult.Failure);
                  setErrorMessage(t("popup.error.title.invalid-input"));
                }
              }}
              style={{ height: "10em" }}
              placeholder={t("register.cong-share")}
            />
          </>
        )}
        {enableUnlisted && (
          <div>
            {enableBrazilUnlistedSearch && (
              <div>
                <Form.Group as={Row}>
                  <Col xs={8}>
                    <Form.Control
                      required
                      placeholder={t("register.cong-number")}
                      type="number"
                      value={brazilUnlistedCongNumber}
                      onChange={(e) => setBrazilUnlistedCongNumber(e.currentTarget.value)}
                    />
                  </Col>
                  <Col xs="auto">
                    <Button variant="primary" onClick={findBrazilUnlisted} disabled={!brazilUnlistedCongNumber}>
                      {t("search.hint")}
                    </Button>
                  </Col>
                </Form.Group>
                {!!unlisted.name && <p className="mt-1 fw-bold">{unlisted.name}</p>}
              </div>
            )}
            {!enableBrazilUnlistedSearch && (
              <>
                <Form.Control
                  required
                  disabled={enableBrazilUnlistedSearch}
                  placeholder={t("congprofile.name")}
                  onChange={(e) => setUnlisted({ ...unlisted, name: e.currentTarget.value })}
                />
                <Form.Control
                  className="mt-1"
                  required
                  disabled={enableBrazilUnlistedSearch}
                  placeholder="URL"
                  onChange={(e) => {
                    const url = e.currentTarget.value;
                    setUnlisted({ ...unlisted, url: url });
                    setInputText(url);
                  }}
                ></Form.Control>
              </>
            )}
            <p className="mt-3 mb-1">{t("conganalysis.attendance.weekend")}</p>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Select
                    required
                    value={unlisted.wmdow}
                    onChange={(e) => setUnlisted({ ...unlisted, wmdow: parseInt(e.currentTarget.value) })}
                  >
                    {[1, 2, 3, 4, 5, 6, 7].map((dow) => (
                      <option key={dow} value={dow}>
                        {localizedDayOfWeek(dow, i18n.language)}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <HourglassTimePicker
                time={unlisted.wmtime}
                onTimeChange={(newTime: string) => setUnlisted({ ...unlisted, wmtime: newTime })}
              />
            </Row>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Form.Text className="warn_text" hidden={!errorMessage}>
          {errorMessage}
        </Form.Text>
        {showSpinner && <Spinner size="sm" animation="border" />}
        {!showSpinner && <SaveStatus saveResult={saveResult} saveKey="wm_cong" setSaveResult={setSaveResult} />}
        <Button
          variant="primary"
          disabled={!!errorMessage || !getGUID() || (enableUnlisted && !unlistedComplete()) || showSpinner}
          onClick={handleUpdate}
        >
          {t("general.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const LocalSpeakerWarning = (props: { show: boolean; setShow: (show: boolean) => void }) => {
  const { t } = useTranslation();
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.local-speakers")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("schedules.weekend.local-speakers-body")}</Modal.Body>
    </Modal>
  );
};

const DeleteCongModel = (props: {
  cong: Congregation;
  show: boolean;
  setShow: (show: boolean) => void;
  setCong: (cong: Congregation) => void;
  refresh: () => void;
  hasASMTs: boolean;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const handleDelete = async () => {
    weekendApi.deleteCong(props.cong.id, props.langGroupId).then((d) => {
      if (d.Deleted) {
        props.setCong({} as Congregation);
        handleClose();
        props.refresh();
      }
    });
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("general.delete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("schedules.weekend.delete-cong-confirm")}</p>
        <p>
          <b>{CongName(props.cong)}</b>
        </p>
        {props.hasASMTs && (
          <Danger
            text={
              <>
                <ExclamationTriangleFill /> {t("schedules.weekend.delete-cong-warning")}
              </>
            }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {t("general.delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const MoveSpeakerModal = (props: {
  speaker: Speaker;
  show: boolean;
  setShow: (show: boolean) => void;
  congregations: Congregation[];
  moveSuccess: () => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const [targetCong, setTargetCong] = useState<Congregation>();
  const setSpeakerMutation = useMutation({
    mutationFn: (ps: PublicSpeakerInput) => weekendApi.setSpeaker(ps, undefined, props.langGroupId),
  });

  const handleClose = () => {
    setTargetCong(undefined);
    props.setShow(false);
  };

  const selectCong = (selected: Option[]) => {
    const selectedCong = selected[0] as Congregation;
    if (!selectedCong) return;
    setTargetCong(selectedCong);
  };

  const handleMove = async () => {
    if (!targetCong || !targetCong.id) return;
    const updatedSpeaker: PublicSpeakerInput = { ...props.speaker, congregation: targetCong.id };
    try {
      await setSpeakerMutation.mutateAsync(updatedSpeaker);
      props.moveSuccess();
      handleClose();
    } catch (err: any) {
      HGBugsnagNotify("moveSpeaker", err);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.move-assignment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>{t("list.congregation.title")}</b>
        <Typeahead
          selected={targetCong ? [targetCong] : []}
          id="new_cong"
          flip
          options={props.congregations}
          labelKey={(option: any) => {
            if (option.custom_name) return option.custom_name;
            return option.name || "?";
          }}
          onChange={selectCong}
          maxResults={100}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="primary" onClick={handleMove} disabled={!targetCong}>
          {t("schedules.weekend.move-assignment")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const DeleteSpeakerModel = (props: {
  speaker: Speaker;
  show: boolean;
  setShow: (show: boolean) => void;
  refresh: () => void;
  deleteSuccess: () => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const handleDelete = async () => {
    weekendApi.deleteSpeaker(props.speaker.id, props.langGroupId).then((d) => {
      if (d.Deleted) {
        handleClose();
        props.refresh();
        props.deleteSuccess();
      }
    });
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("general.delete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t("schedules.weekend.delete-speaker-confirm")}
        <p>
          <b>{nameOfUser(props.speaker, HourglassGlobals.nameFmt)}</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {t("general.delete")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AddButton = (props: { setShow: (set: boolean) => void }) => {
  return (
    <Button
      variant="primary"
      size="sm"
      className="ms-2"
      disabled={!canUpdateWeekendSchedules()}
      onClick={() => {
        props.setShow(true);
      }}
    >
      <Plus size="1.5em" />
    </Button>
  );
};

const TalksModal = (props: {
  tempSpeaker: Speaker;
  show: boolean;
  setShow: (set: boolean) => void;
  setTempSpeaker: (set: Speaker) => void;
  canSave: boolean;
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [talks, setTalks] = useState(
    (props.tempSpeaker.public_talks ?? []).map((t: PublicTalk) => `${t.number}`).join(", ") ?? "",
  );
  const [invalid, setInvalid] = useState(false);

  const cleanList = (a: string[]) => {
    const seen: Record<string, boolean> = {};

    // remove dupes
    const filtered = a.filter((item) => {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });

    // convert to numbers
    const numbers = filtered.map((n) => {
      return Number(n);
    });

    // put in order
    numbers.sort((a: number, b: number) => {
      return a - b;
    });
    return numbers;
  };

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("schedules.weekend.talks-for", {
            interpolation: PythonI18nInterpolation,
            speaker: nameOfUser(props.tempSpeaker, HourglassGlobals.nameFmt),
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          as="textarea"
          autoFocus
          defaultValue={talks}
          onChange={(e) => {
            if (e.target.value.match("^[0-9, \n]*$") != null) {
              setInvalid(false);
            } else {
              setInvalid(true);
            }
            setTalks(e.target.value);
          }}
        />
        <div className="warn_text" hidden={!invalid}>
          {t("schedules.weekend.talk-list-warning")}
        </div>
        <Form.Text muted>{t("schedules.weekend.talk-bulk-separated")}</Form.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={copied ? "success" : "secondary"}
          onClick={async () => {
            await navigator.clipboard.writeText(talks);
            setCopied(true);
          }}
        >
          {copied ? <ClipboardCheck color="white" size="1.2em" /> : <ClipboardPlus size="1.2em" />}
          <span className="ms-1">{t("general.copy")}</span>
        </Button>
        {props.canSave && (
          <Button
            variant="primary"
            onClick={() => {
              props.setTempSpeaker({
                ...props.tempSpeaker,
                public_talks: cleanList(
                  talks
                    .replace(/[^0-9\s]+/g, " ")
                    .replace(/\n+/g, " ")
                    .replace(/\s\s+/g, " ")
                    .split(" "),
                ).map((t) => {
                  return { number: Number(t) } as PublicTalk;
                }),
              });
              props.setShow(false);
            }}
          >
            {t("popup.error.button.ok")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const CongInfo = (props: {
  cong: Congregation;
  notes: string;
  notesEditModeEnabled: boolean;
  enableEditNameMode: boolean;
  setShowDeleteCongModal: (set: boolean) => void;
  setNotes: (set: string) => void;
  setNotesEditModeEnabled: (set: boolean) => void;
  setEnableEditNameMode: (set: boolean) => void;
  refresh: () => void;
  setHasASMTs: (set: boolean) => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const myCong = useContext(HGContext).globals.cong;
  const [status, setStatus] = useState(Status.Null);
  const [refreshStatus, setRefreshStatus] = useState(Status.Null);
  const [customName, setCustomName] = useState(props.cong.custom_name ?? "");
  const [domParser] = useState(new DOMParser());

  const settings = useCongSettings(props.langGroupId);
  const queryClient = useQueryClient();
  const queryStatus = QueryStatus(settings);
  if (queryStatus !== null) return queryStatus;

  let tempName = "";

  const handleNameChange = async () => {
    if (customName !== props.cong.name) {
      setStatus(Status.Pending);
      let result: UpdateStatus = { Updated: false };

      if (myCong.id === props.cong.id && settings.data) {
        const newSettings = await congregationApi.setSettings(
          {
            ...settings.data,
            congregation_display_name: customName,
          },
          props.langGroupId,
        );
        queryClient.setQueryData([QueryKeys.CongregationSettings, props.langGroupId], newSettings);
        result.Updated = true;
      } else {
        result = await weekendApi.setCongNotes(props.cong.id, customName, props.notes, props.langGroupId);
      }
      if (result) {
        tempName = customName;
        props.refresh();
        setStatus(Status.Null);
        props.setEnableEditNameMode(false);
      }
    } else {
      tempName = "";
      setCustomName("");
    }
  };

  const handleKeyPress = async (e: KeyboardEvent) => {
    // noinspection AllyPlainJsInspection
    if (e.key === "Enter") {
      await handleNameChange();
    }
  };

  return (
    <ButtonToolbar className="justify-content-between">
      <div>
        {props.enableEditNameMode ? (
          <InputGroup>
            <Form.Control
              placeholder={props.cong.name}
              autoFocus
              value={customName || props.cong.name}
              onKeyUp={handleKeyPress}
              onChange={(e) => {
                setCustomName(e.target.value);
              }}
            />
            <Button
              variant="primary"
              onClick={() => {
                setCustomName(tempName);
                props.setEnableEditNameMode(false);
              }}
            >
              {t("general.cancel")}
            </Button>
            <Button variant="primary" onClick={handleNameChange}>
              {status ? <ShowStatus status={status} size="sm" /> : t("general.save")}
            </Button>
          </InputGroup>
        ) : (
          <h4>
            {customName || props.cong.name}
            <EditButton
              size="sm"
              disabled={!canUpdateWeekendSchedules()}
              className="ms-1"
              onClick={() => {
                tempName = customName;
                props.setEnableEditNameMode(true);
              }}
            />
            <Button
              size="sm"
              variant="primary"
              className="ms-1"
              onClick={async () => {
                setRefreshStatus(Status.Pending);
                const jworgcong = await congregationApi.getCongInfoFromJWOrg(props.cong.guid);
                await queryClient.invalidateQueries({
                  queryKey: [QueryKeys.WMCongregations, props.langGroupId],
                });
                if (jworgcong && jworgcong.properties) {
                  // since the state doesn't filter down cleanly, we backfill it
                  props.cong.wm_dow = jworgcong.properties.schedule.current.weekend.weekday;
                  props.cong.wm_time = jworgcong.properties.schedule.current.weekend.time;
                  props.cong.future_wm_dow = jworgcong.properties.schedule.future.weekend.weekday;
                  props.cong.future_wm_time = jworgcong.properties.schedule.future.weekend.time;
                  props.cong.address = jworgcong.properties.address;
                  props.cong.phone =
                    Array.isArray(jworgcong.properties.phones) && jworgcong.properties.phones[0]
                      ? jworgcong.properties.phones[0].phone
                      : "";
                  props.cong.name = jworgcong.properties.orgName;
                }
                setRefreshStatus(Status.Null);
              }}
            >
              {refreshStatus === Status.Pending ? (
                <ShowStatus status={refreshStatus} size="sm" />
              ) : (
                <ArrowRepeat size="1.2em" />
              )}
            </Button>
          </h4>
        )}
        <i>{props.cong.address}</i>
        {/*we need this parsing because some of the phone numbers from jw.org have things like &nbsp; embedded in them*/}
        <div>{domParser.parseFromString(props.cong.phone, "text/html")?.body?.textContent ?? null}</div>
        <CongWeekendMeetingTime cong={props.cong} />
        {props.cong.direct_exchange === SpeakerDirectExchange.Pending && (
          <p className="mt-4">
            <i>{t("schedules.weekend.speaker-trade.pending")}</i>
          </p>
        )}
      </div>
      {props.cong.id !== myCong.id && (
        <Form.Group className="cong_notes">
          <Form.Control
            disabled={!props.notesEditModeEnabled}
            as="textarea"
            rows={3}
            value={props.notes}
            onChange={(e) => props.setNotes(e.target.value)}
            style={{ width: "25em", height: "10em" }}
            placeholder={t("schedules.weekend.notes.other-cong")}
          />
          <Button
            variant={props.notesEditModeEnabled ? "primary" : "outline-primary"}
            className="edit_save_button"
            size="sm"
            disabled={props.cong.id === myCong.id || !canUpdateWeekendSchedules()}
            onClick={() => {
              if (props.notesEditModeEnabled) {
                weekendApi.setCongNotes(props.cong.id, customName, props.notes, props.langGroupId).then((u) => {
                  if (u.Updated) {
                    props.cong.notes = props.notes;
                  }
                });
              }
              props.setNotesEditModeEnabled(!props.notesEditModeEnabled);
            }}
          >
            {props.notesEditModeEnabled ? t("general.save") : t("general.edit")}
          </Button>
        </Form.Group>
      )}
      {props.cong.id !== myCong.id && (
        <div>
          <Button
            variant="danger"
            disabled={!canUpdateWeekendSchedules()}
            onClick={async () => {
              const res = await weekendApi.getCongTrades(props.cong.id, props.langGroupId);
              props.setHasASMTs(!!(res.inbound.length + res.outbound.length));
              props.setShowDeleteCongModal(true);
            }}
          >
            {t("general.delete")}
          </Button>
        </div>
      )}
    </ButtonToolbar>
  );
};

const SpeakerList = (props: {
  speakers: Speaker[];
  speaker: Speaker;
  newSpeaker: (set: Speaker) => void;
  users: User[];
}) => {
  const speakers = props.speakers.length
    ? props.speakers.sort(speakerCompare).map((s) => {
        // if we have a userId, we'll show the display name of the user (otherwise, with e2e, we'd have nothing to show)
        const user = s.userId ? props.users.find((u) => u.id === s.userId) : undefined;
        const directUser = s.userId && !user;
        const speakerOrUser: Speaker | User = user ?? s;
        return (
          <ListGroup.Item
            className="d-flex justify-content-between align-items-start"
            action
            key={s.id}
            active={s.id === props.speaker.id}
            onClick={() => props.newSpeaker(s)}
          >
            <SpeakerName speaker={speakerOrUser} notOutOverride={!s.out} />
            {directUser && <DirectExchangeIcon status={SpeakerDirectExchange.Yes} />}
          </ListGroup.Item>
        );
      })
    : [
        <ListGroup.Item className="d-flex justify-content-between align-items-start" action key="none" disabled={true}>
          <span className="text-muted fst-italic">{t("general.none")}</span>
        </ListGroup.Item>,
      ];

  return <ListGroup>{speakers}</ListGroup>;
};

const CongList = (props: {
  congs: Congregation[];
  cong: Congregation;
  setCong: (set: Congregation) => void;
  setNotes: (set: string) => void;
  setSpeaker: (set: Speaker) => void;
  setTempSpeaker: (set: Speaker) => void;
  setNotesEditModeEnabled: (set: boolean) => void;
  setEnableEditNameMode: (set: boolean) => void;
  langGroupId: number;
  scrollPos: number;
  setScrollPos: (pos: number) => void;
}) => {
  const { t } = useTranslation();
  const myCong = useContext(HGContext).globals.cong;
  const [showBlockAccept, setShowBlockAccept] = useState(false);
  const acceptExchangeMutation = useMutation({
    mutationFn: (response: { congId: number; accept: boolean }) =>
      weekendApi.respondSpeakerExchange(response.congId, response.accept, props.langGroupId),
  });
  const queryClient = useQueryClient();
  const sortedCongs = props.congs.sort(congCompare);
  const proposedCongs = sortedCongs.filter((c) => c.direct_exchange === SpeakerDirectExchange.Proposed);
  const localSpeakersQuery = useWMSpeakers(myCong.id, props.langGroupId);

  const divId = "hg-cong-scroll-div";

  useEffect(() => {
    if (props.scrollPos) {
      const el = document.getElementById(divId);
      if (el) {
        el.scrollTo(0, props.scrollPos);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const canAccept = (): boolean => {
    // must have at least one local speaker who can go out who has at least one outline
    return localSpeakersQuery.data?.filter((s) => s.out).some((s) => s.public_talks.length > 0) ?? false;
  };

  const response = async (cong: Congregation, accept: boolean) => {
    if (accept && !canAccept()) {
      // not allowed to accept: they haven't set up their speakers
      setShowBlockAccept(true);
      return;
    }
    // find the cong in the pending state - it has all the info like meeting times. the "proposed" one doesn't
    const pendingCong =
      props.congs.find((c) => c.id === cong.id && c.direct_exchange !== SpeakerDirectExchange.Proposed) || cong;
    try {
      await acceptExchangeMutation.mutateAsync({ congId: cong.id, accept: accept });
      const newCong: Congregation = {
        ...pendingCong,
        direct_exchange: accept ? SpeakerDirectExchange.Yes : SpeakerDirectExchange.No,
      };
      updateWMCongregationCache(queryClient, cong, newCong, props.langGroupId);
    } catch (err: any) {
      console.error("error accepting speaker exchange", err);
      HGBugsnagNotify("acceptWMSpeakerExchange", err);
    }
  };

  const congClickScroll = () => {
    const el = document.getElementById(divId);
    if (el) {
      props.setScrollPos(el.scrollTop);
    }
  };

  return (
    <div className="max-height-50vh overflow-y-auto" id={divId}>
      <BlockAcceptModal show={showBlockAccept} setShow={setShowBlockAccept} />
      {proposedCongs.length > 0 && (
        <>
          <b>{t("schedules.weekend.speaker-trade.cong-list")}</b>
          <ListGroup>
            {proposedCongs.map((c) => (
              <ListGroup.Item key={c.id} className="d-flex align-items-center">
                <span className="w-100">{c.name}</span>
                <span className="flex-shrink-1 me-2">
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => response(c, true)}
                    disabled={!canUpdateWeekendSchedules()}
                  >
                    {t("schedules.assignment.accept")}
                  </Button>
                </span>
                <span className="flex-shrink-1">
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={() => response(c, false)}
                    disabled={!canUpdateWeekendSchedules()}
                  >
                    {t("schedules.assignment.decline")}
                  </Button>
                </span>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <hr />
        </>
      )}
      <ListGroup>
        {sortedCongs
          .filter((c) => c.direct_exchange !== SpeakerDirectExchange.Proposed)
          .map((c) => (
            <ListGroup.Item
              key={c.id}
              action
              className="d-flex justify-content-between align-items-start"
              active={c.id === props.cong.id}
              onClick={() => {
                props.setCong(c);
                props.setNotes(c.notes);
                props.setSpeaker({} as Speaker);
                props.setTempSpeaker({} as Speaker);
                props.setNotesEditModeEnabled(false);
                props.setEnableEditNameMode(false);
                congClickScroll();
              }}
            >
              <div>
                {CongName(c)}
                {!!c.custom_name && c.custom_name !== c.name && <div className="text-muted smallText">{c.name}</div>}
              </div>
              <CongIcon isMyCong={getMyCongAndGroupIds().has(c.id)} directStatus={c.direct_exchange} />
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  );
};

function CongIcon(props: { isMyCong: boolean; directStatus: SpeakerDirectExchange }) {
  if (props.isMyCong) return <HouseFill />;
  if (props.directStatus) return <DirectExchangeIcon status={props.directStatus} />;
  return null;
}

function DirectExchangeIcon(props: { status?: SpeakerDirectExchange }) {
  if (!props.status || props.status === SpeakerDirectExchange.No) return null;
  return <ArrowLeftRight className={props.status === SpeakerDirectExchange.Yes ? "active-speaker-link" : undefined} />;
}
