import autoTable, { CellInput, RowInput, Styles } from "jspdf-autotable";
import { boldFont, boldStyle, halfInch, newPDFWithHeader, normalFont } from "../../../helpers/pdf";
import { t } from "../../../helpers/locale";
import { PublicTalkAssignment, TalkMod, WMSchedule } from "../../../types/scheduling/weekend";
import { Events, EventTypes, ScheduledEvent } from "../../../types/scheduling/events";
import {
  localizedDayOfWeek,
  localizedTime,
  Month,
  stringToLongLocaleDate,
  weekOfString,
  wmDate,
} from "../../../helpers/dateHelpers";
import { Cong } from "../../../types/cong";
import { jsPDF } from "jspdf";
import { nameOfUser } from "../../../helpers/user";
import { CongName, dummyCongregation } from "../common";
import { CongSettings } from "../../../types/scheduling/settings";
import i18n from "i18next";
import User from "../../../types/user";
import { songStyle } from "../mm/mm_pdf";
import { FSGroup } from "../../../types/fsgroup";
import { getGroupDisplayName } from "../../../helpers/fsgroup";
import { PDFOrientation } from "../../../types/paper";

const margin = halfInch;

//return the autotable header for a WM schedule
export function wmScheduleHeader(settings: CongSettings, orientation: PDFOrientation): RowInput[] {
  const style: Partial<Styles> = { font: boldFont, cellWidth: "wrap" };
  const makeRow = (input: string[]): CellInput[] => {
    return input.map((i) => {
      return { content: i, styles: style };
    });
  };

  //headings on all schedules
  const headings = [
    t("general.date"),
    t("schedules.chairman"),
    t("schedules.weekend.speaker.0"),
    t("schedules.weekend.talk-title"),
  ];

  if (settings.show_wt_reader && settings.show_interpreter) {
    headings.push(`${t("schedules.reader")} / ${t("schedules.interpreter.title")}`);
  } else if (!settings.show_wt_reader) {
    headings.push(t("schedules.interpreter.title"));
  } else {
    headings.push(t("schedules.weekend.wt-reader"));
  }

  if (settings.enable_hospitality) headings.push(t("schedules.privileges.hospitality"));

  let headerRow = makeRow(headings);

  if (orientation === "landscape") {
    const landscapeHeadings = [
      t("schedules.weekend.outgoing"),
      t("list.congregation.title"),
      t("schedules.general.talk"),
    ];
    headerRow = [...headerRow, { content: "", styles: { fillColor: "white" } }, ...makeRow(landscapeHeadings)];
  }

  return [headerRow];
}

type ColumnStyles = { [key: string]: Partial<Styles> };

export function wmScheduleColStyles(tableWidth: number, useHost: boolean, orientation: PDFOrientation): ColumnStyles {
  const perOfTable = (x: number): number => {
    return (x / 100) * tableWidth;
  };
  const makeStyles = (widths: number[]): ColumnStyles => {
    const styleObj: ColumnStyles = {};
    widths.forEach((w, i) => {
      styleObj[i] = {
        cellWidth: perOfTable(w),
        lineWidth: 0.01,
      };
    });
    return styleObj;
  };

  //these are the percentages of table width for the columns, in each combination
  const landscapeNoHost = [7, 10, 19, 17.5, 10, 0.5, 12, 19, 5];
  const landscapeHost = [7, 8, 19, 17.5, 8, 8, 0.5, 8, 19, 5];
  const portraitNoHost = [12, 14, 29, 31, 14];
  const portraitHost = [12, 12, 26, 26, 12, 12];

  if (useHost) {
    if (orientation === "portrait") return makeStyles(portraitHost);
    return makeStyles(landscapeHost);
  }

  if (orientation === "portrait") return makeStyles(portraitNoHost);
  return makeStyles(landscapeNoHost);
}

// factor events into the WM schedule
export function wmsEvents(wms: WMSchedule[], events: ScheduledEvent[]) {
  const eventWeeks = new Map<string, ScheduledEvent>();
  events.forEach((ev) => eventWeeks.set(ev.week, ev));

  const clearWithName = (wm: WMSchedule, name: string) => {
    wm.host = 0;
    wm.wm_reader = 0;
    wm.wm_chairman = 0;
    wm.speaker2 = undefined;
    wm.out = [];
    wm.speaker.lastname = "";
    wm.speaker.middlename = "";
    wm.speaker.suffix = "";
    wm.speaker.firstname = name;
  };

  wms.forEach((wm) => {
    // wm date should already be monday. this is just defensive
    const event = eventWeeks.get(weekOfString(wm.date));
    if (!event) return;
    // there is an event this week
    switch (event.event) {
      case Events.wmem:
        clearWithName(wm, t("schedules.events.memorial"));
        break;
      case Events.ca:
        clearWithName(wm, t("schedules.events.circuit-assembly"));
        break;
      case Events.rc:
        clearWithName(wm, t("schedules.events.regional-convention"));
        break;
      case Events.co:
        // no reader, hospitality, outgoing speakers
        wm.host = 0;
        wm.wm_reader = 0;
        wm.out = [];
        wm.temp_cong = dummyCongregation(0, t("general.circuit-overseer"));
        break;
    }
  });
}

export async function weekendMeetingPDF(
  wms: WMSchedule[],
  events: ScheduledEvent[],
  myCong: Cong,
  useHost: boolean,
  userMap: Map<number, User>,
  settings: CongSettings,
  startMonth: Month,
  fsGroups: FSGroup[],
) {
  if (wms.length === 0) return;
  const { doc, initY } = await newPDFWithHeader(t("conganalysis.attendance.weekend"), myCong.name, myCong.locale.code);

  //determine if we should show more on this page or not
  const moreWeeks = (y: number, wmIdx: number): boolean => {
    // do we have room for more on this page? 2" of vertical space is a conservative number for each week
    if (y >= 9) return false;

    // always show at least 1 month, even if nothing is scheduled
    const wmMonth: Month = Month.fromDateString(weekOfString(wms[wmIdx]?.date ?? "", myCong.wmdow));
    if (startMonth.equals(wmMonth)) return true;

    // if any of the rest have something scheduled, then we return true
    for (let i = wmIdx; i < wms.length; i++) {
      const wm = wms[i];
      if (!wm) return false;
      if (
        wm.speaker.firstname ||
        wm.speaker.id ||
        wm.public_talk?.number ||
        wm.public_talk?.title ||
        wm.out.length > 0 ||
        wm.wm_chairman ||
        wm.wm_reader ||
        wm.host ||
        wm.temp_cong?.id ||
        wm.temp_cong?.name ||
        wm.event?.event
      )
        return true;
    }

    return false;
  };

  let curY = initY;
  let count = 0;
  for (const wm of wms) {
    //space between weeks
    if (count > 0) curY += 0.2;
    if (!moreWeeks(curY, count)) break;
    const weekEvents = events.filter((e) => e.week === weekOfString(wm.date));
    if (settings.hide_outgoing_speakers) wm.out = [];
    curY = await weekendSchedule(doc, curY, userMap, wm, weekEvents, myCong, useHost, settings, fsGroups);
    count++;
  }

  doc.save(`weekend_${startMonth.toString()}.pdf`);
}

async function weekendSchedule(
  doc: jsPDF,
  initialY: number,
  userMap: Map<number, User>,
  wm: WMSchedule,
  events: ScheduledEvent[],
  myCong: Cong,
  useHost: boolean,
  settings: CongSettings,
  fsGroups: FSGroup[],
): Promise<number> {
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const headerFontSize = 9;
  const talkTitleFontSize = 8.5;
  const bodyFontSize = 7.5;
  const leftPadding = 0.07;
  const leftIndent = 0.2;
  const mainColWidth = tableWidth / 2 + 0.6;
  const titleColWidth = getTitleColWidth(doc, bodyFontSize) + 0.15;
  const colWidths = [mainColWidth, titleColWidth, tableWidth - mainColWidth - titleColWidth];

  const headerRow: RowInput[] = [
    [
      {
        content: settings.wm_print_date === "dayof" ? stringToLongLocaleDate(wmDate(wm.date, myCong, events)) : wm.date,
        colSpan: 3,
        styles: { fillColor: "#5D719E", textColor: "white", fontSize: headerFontSize, font: boldFont },
      },
    ],
  ];

  const skipEvents = new Set<Events>([Events.rc, Events.ca, Events.wmem]);
  const skipEvent = events.find((e) => skipEvents.has(e.event));
  const coVisit = wm.talk_mod === TalkMod.CO;

  const getUser = (uid: number): string => {
    return userMap.get(uid)?.displayName ?? "";
  };

  const getHost = (uid: number): string => {
    if (settings.hospitality_by_group) {
      const group = fsGroups.find((g) => g.overseer_id === uid);
      if (group) {
        return getGroupDisplayName(group, userMap, settings.congregation_groups_use_label);
      }
    }
    return getUser(uid);
  };

  const speakerName = (): string => {
    if (coVisit) return settings.circuit_overseer_name || t("general.circuit-overseer");
    if (wm.talk_mod === TalkMod.Other) return settings.other_speaker_name;
    if (wm.talk_mod === TalkMod.TBD && wm.speaker.firstname === TalkMod.TBD) return t("schedules.weekend.tbd");
    if (wm.talk_mod === TalkMod.Stream) return "";
    if (!wm.speaker || (!!wm.speaker && !wm.speaker.id)) return "";
    const speaker1Name = nameOfUser(wm.speaker);
    if (wm.speaker2) {
      const speaker2User = userMap.get(wm.speaker2);
      if (speaker2User) return `${speaker1Name} | ${nameOfUser(speaker2User)}`;
    }
    return speaker1Name;
  };

  const congName = (): string => {
    // if it's a CO and we have the CO's name, the back end sends us english "Circuit Overseer"
    // instead, put in the localized text here
    if (wm.talk_mod === TalkMod.CO && settings.circuit_overseer_name) return t("general.circuit-overseer");
    if (wm.talk_mod === TalkMod.Stream) return "stream.jw.org";
    const speakerCong = CongName(wm.speaker.congregation);
    if (speakerCong && speakerCong !== TalkMod.TBD) return speakerCong;
    const tempCong = CongName(wm.temp_cong);
    if (tempCong && tempCong !== TalkMod.TBD) return tempCong;
    if (wm.talk_mod === TalkMod.TBD && wm.speaker.firstname !== TalkMod.TBD) return t("schedules.weekend.tbd");
    return "";
  };

  const congTime = (out: PublicTalkAssignment): string => {
    if (!out.congregation) return "";
    return `${localizedTime(
      out.congregation.wm_time,
      i18n.language,
      out.congregation.timezone_name,
    )} ${localizedDayOfWeek(out.congregation.wm_dow, i18n.language)}`;
  };

  const talkName = (): string => {
    if (coVisit) {
      return settings.circuit_overseer_wm_talk_title;
    }
    if (wm.talk_mod === TalkMod.TBD && wm.public_talk?.title === TalkMod.TBD) return t("schedules.weekend.tbd");
    if (wm.talk_mod === TalkMod.Stream) return wm.public_talk?.number ? wm.public_talk.title : t("schedules.stream");
    return wm.public_talk?.title ?? "";
  };

  let lastEndY = 0;

  const body: RowInput[] = [];
  if (!skipEvent) {
    const customEvent = events.find((e) => e.event === Events.custom && e.customShowOnMeetingSchedule);
    if (customEvent) {
      const customCol1 = `${customEvent.custom_title}\n${customEvent.custom_body}`;
      body.push([customCol1, null, null]);
    }
    // col1 is the (larger) first column - where talk titles, songs, speakers, etc. go
    const col1: CellInput[] = [];

    if (!!wm.opening_song) col1.push(songStyle(wm.opening_song));
    col1.push({
      content: talkName(),
      styles: { font: boldFont, fontSize: talkTitleFontSize },
    });
    const speakerAndCong = `${speakerName()}${!!speakerName() && !!congName() ? " — " : ""}${congName()}`;
    col1.push(speakerAndCong);
    if (coVisit) {
      // CO service talk
      col1.push(
        {
          content: settings.circuit_overseer_wm_svc_talk_title || t("schedules.service-talk"),
          styles: { font: boldFont },
        },
        speakerAndCong,
      );
    }
    if (!!wm.closing_song) col1.push(songStyle(wm.closing_song));

    if (wm.out.length > 0) {
      col1.push({
        content: t("schedules.weekend.outgoing"),
        styles: { cellPadding: { top: 0.05, bottom: 0, left: leftPadding } },
      });
    }

    // col23 is a 2d array of the second and third columns - where we have labels like "chairman" and "watchtower reader"
    // as well as the names of the assignees
    const col23: CellInput[][] = [
      [
        {
          content: t("schedules.chairman"),
          styles: { halign: "right" },
        },
        getUser(wm.wm_chairman),
      ],
    ];
    if (wm.openprayer) {
      col23.unshift([
        {
          content: t("schedules.assignment.opening-prayer"),
          styles: { halign: "right" },
        },
        getUser(wm.openprayer),
      ]);
    }
    if (wm.wt_conductor) {
      col23.push([
        {
          content: t("schedules.weekend.wt-conductor"),
          styles: { halign: "right" },
        },
        getUser(wm.wt_conductor),
      ]);
    }
    if (!coVisit && settings.show_wt_reader) {
      col23.push([
        {
          content: t("schedules.weekend.wt-reader"),
          styles: { halign: "right" },
        },
        getUser(wm.wm_reader),
      ]);
    }
    if (settings.show_interpreter && wm.interpreter) {
      col23.push([
        {
          content: t("schedules.interpreter.title"),
          styles: { halign: "right" },
        },
        getUser(wm.interpreter),
      ]);
    }
    if (wm.closeprayer || settings.wm_speaker_closing_prayer) {
      const haveSpeaker = !!wm.speaker.id || wm.talk_mod === TalkMod.CO;
      const text = wm.closeprayer ? getUser(wm.closeprayer) : haveSpeaker ? speakerName() : "";
      col23.push([
        {
          content: t("schedules.assignment.closing-prayer"),
          styles: { halign: "right" },
        },
        text,
      ]);
    }

    // only shown if hospitality is in place
    if (useHost && (!coVisit || wm.host)) {
      col23.push([
        {
          content: t("schedules.privileges.hospitality"),
          styles: { halign: "right" },
        },
        getHost(wm.host),
      ]);
    }

    // put col1 and col23 together
    const rows = Math.max(col1.length, col23.length);
    for (let i = 0; i < rows; i++) {
      const c1 = i < col1.length ? col1[i] : "";
      const c23 = i < col23.length ? col23[i] : ["", ""];
      // @ts-ignore noUncheckedIndexedAccess
      body.push([c1, c23[0], c23[1]]);
    }
  }

  autoTable(doc, {
    theme: "plain",
    startY: initialY,
    head: headerRow,
    body: body,
    margin: {
      left: margin,
      bottom: 0,
      right: margin,
    },
    pageBreak: "avoid",
    rowPageBreak: "avoid",
    styles: {
      font: normalFont,
      fontSize: bodyFontSize,
      cellPadding: { top: 0.02, bottom: 0.0, left: leftPadding, right: 0.07 },
      valign: "bottom",
    },
    headStyles: {
      font: normalFont,
      fontSize: headerFontSize,
      cellPadding: { top: 0.04, bottom: 0.02, left: leftPadding },
    },
    columnStyles: {
      0: {
        cellWidth: colWidths[0],
      },
      1: {
        cellWidth: colWidths[1],
      },
      2: {
        cellWidth: colWidths[2],
      },
    },
    didDrawPage: (d) => {
      if (d.cursor) lastEndY = d.cursor.y;
    },
  });

  if (skipEvent) {
    // just show the event name
    const size = doc.getFontSize();
    const font = doc.getFont();

    doc.setFont(boldFont, boldStyle);
    doc.setFontSize(11);
    const eventTitle = t(EventTypes[skipEvent.event]);
    lastEndY += 0.2;
    doc.text(eventTitle, margin, lastEndY);

    doc.setFont(font.fontName, font.fontStyle);
    doc.setFontSize(size);
    return lastEndY + doc.getTextDimensions(eventTitle).h;
  }

  if (wm.out.length > 0) {
    const outgoingBody: RowInput[] = wm.out.map((out) => {
      return [
        {
          content: out.speaker ? nameOfUser(out.speaker) : "",
          styles: { cellPadding: { left: leftIndent, right: 0.05, top: 0.02 } },
        },
        {
          content: out.public_talk ? `#${out.public_talk.number}` : "",
          styles: { cellPadding: { left: 0.05, right: 0.05, top: 0.02 } },
        },
        {
          content: out.congregation ? `${CongName(out.congregation)} ${congTime(out)}` : "",
          styles: { cellPadding: { left: 0.05, right: 0.05, top: 0.02 } },
        },
      ];
    });

    autoTable(doc, {
      theme: "plain",
      startY: lastEndY,
      body: outgoingBody,
      margin: {
        left: margin,
        bottom: 0,
      },
      tableWidth: "wrap",
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        font: normalFont,
        fontSize: bodyFontSize,
        cellWidth: "wrap",
        valign: "bottom",
      },
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  }

  return lastEndY;
}

// see how wide the column with titles like chairman, WT reader, etc. needs to be
function getTitleColWidth(doc: jsPDF, fontSize: number): number {
  const font = doc.getFont();
  doc.setFont(normalFont);
  doc.setFontSize(fontSize);

  const assets = [
    "schedules.chairman",
    "schedules.weekend.wt-conductor",
    "schedules.weekend.wt-reader",
    "schedules.assignment.closing-prayer",
    "schedules.privileges.hospitality",
  ];
  let max = 1;
  assets.forEach((asset) => {
    const width = doc.getTextWidth(t(asset));
    if (width > max) max = width;
  });

  doc.setFont(font.fontName);
  return max;
}
