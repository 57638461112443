type LocaleMap = { [key: string]: Map<number, string> };

export const LocaleWeekdayNames: LocaleMap = {
  kea: new Map<number, string>([
    [1, "sigunda-fera"],
    [2, "tersa-fera"],
    [3, "kuarta-fera"],
    [4, "kinta-fera"],
    [5, "sesta-fera"],
    [6, "sábadu"],
    [7, "dumingu"],
  ]),
  ht: new Map<number, string>([
    [1, "Lendi"],
    [2, "Madi"],
    [3, "Mèkredi"],
    [4, "Jedi"],
    [5, "Vandredi"],
    [6, "Samdi"],
    [7, "Dimanch"],
  ]),
  hy: new Map<number, string>([
    [1, "երկուշաբթի"],
    [2, "երեքշաբթի"],
    [3, "չորեքշաբթի"],
    [4, "հինգշաբթի"],
    [5, "ուրբաթ"],
    [6, "շաբաթ"],
    [7, "կիրակի"],
  ]),
};

export const LocaleMonthNames: LocaleMap = {
  kea: new Map<number, string>([
    [0, "Janeru"],
    [1, "Febreru"],
    [2, "Marsu"],
    [3, "Abril"],
    [4, "Maiu"],
    [5, "Junhu"],
    [6, "Julhu"],
    [7, "Agostu"],
    [8, "Setenbru"],
    [9, "Otubru"],
    [10, "Nuvenbru"],
    [11, "Dizenbru"],
  ]),
  ht: new Map<number, string>([
    [0, "Janvye"],
    [1, "Fevriye"],
    [2, "Mas"],
    [3, "Avril"],
    [4, "Me"],
    [5, "Jen"],
    [6, "Jiyè"],
    [7, "Out"],
    [8, "Septanm"],
    [9, "Oktòb"],
    [10, "Novanm"],
    [11, "Desanm"],
  ]),
  hy: new Map<number, string>([
    [0, "հունվարի"],
    [1, "փետրվարի"],
    [2, "մարտի"],
    [3, "ապրիլի"],
    [4, "մայիսի"],
    [5, "հունիսի"],
    [6, "հուլիսի"],
    [7, "օգոստոսի"],
    [8, "սեպտեմբերի"],
    [9, "հոկտեմբերի"],
    [10, "նոյեմբերի"],
    [11, "դեկտեմբերի"],
  ]),
  "vec-BR": new Map<number, string>([
    [0, "Genaro"],
    [1, "Febraro"],
    [2, "Marso"],
    [3, "April"],
    [4, "Maio"],
    [5, "Giugno"],
    [6, "Lùlio"],
    [7, "Agosto"],
    [8, "Setembre"],
    [9, "Otobre"],
    [10, "Novembre"],
    [11, "Disembre"],
  ]),
};
