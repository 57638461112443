import { boldFont, halfInch, newPDFWithHeader, normalFont } from "../../../helpers/pdf";
import { jsPDF } from "jspdf";
import autoTable, { CellHookData, RowInput, Styles } from "jspdf-autotable";
import { WMSchedule } from "../../../types/scheduling/weekend";
import { t } from "../../../helpers/locale";
import { dateToString, stringToLocaleDate, wmDate } from "../../../helpers/dateHelpers";
import { speakerInfo } from "./wm_exchange_pdf";
import { Cong } from "../../../types/cong";

const margin = halfInch;
const bodyFontSize = 10;

export async function upcomingSpeakersPDF(wms: WMSchedule[], cong: Cong) {
  const { doc, initY } = await newPDFWithHeader(t("schedules.weekend.speaker-list"), cong.name, cong.locale.code);
  let curY = initY + 0.05;

  for (const s of wms) {
    if (!s.id || s.event || s.talk_mod) {
      continue;
    }
    curY = week(doc, curY, s, cong);
    curY = speakerInfo(doc, curY, s.speaker, s.public_talk);
    curY += 0.1; // gap
  }

  const fileDate = wms[0]?.date ?? dateToString(new Date());
  doc.save(`${fileDate}-contact-list.pdf`);
}

function week(doc: jsPDF, initialY: number, s: WMSchedule, cong: Cong): number {
  const heading: RowInput[] = [
    [
      {
        content: stringToLocaleDate(wmDate(s.date, cong)),
        styles: { font: boldFont },
        colSpan: 2,
      },
    ],
  ];

  let lastEndY = 0;
  const headerFontSize = 10;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const perOfTable = (x: number) => {
    return (x / 100) * tableWidth;
  };

  const colStyles: {
    [key: string]: Partial<Styles>;
  } = {
    0: {
      cellWidth: perOfTable(10),
      halign: "right",
    },
    1: {
      cellWidth: perOfTable(90),
      halign: "left",
    },
  };

  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "striped",
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        fontSize: bodyFontSize,
        cellPadding: { top: 0.1, bottom: 0.1, left: 0.1 },
        valign: "middle",
      },
      headStyles: {
        font: normalFont,
        fillColor: "black",
        fontSize: headerFontSize,
        cellPadding: { top: 0.05, bottom: 0.05, left: 0.25 },
        halign: "left",
        valign: "middle",
      },
      columnStyles: colStyles,
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  const theBody: RowInput[] = [];

  drawTable(doc, initialY, heading, theBody);
  return lastEndY;
}
