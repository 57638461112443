import { Dayjs } from "dayjs";
import i18n from "i18next";
import { Cong } from "../types/cong";
import { DateRange, ISODateString } from "../types/date";
import { Events, ScheduledEvent } from "../types/scheduling/events";
import { Congregation } from "../types/scheduling/weekend";
import { CongregationToCong } from "./cong";
import { LocaleMonthNames, LocaleWeekdayNames } from "./dateFixtures";
import dayjs from "./dayjs";
import HourglassGlobals from "./globals";
import { CollatorSingleton } from "./locale";

export enum HourFormat {
  H12,
  H24,
}

export enum HourPeriod {
  AM,
  PM,
}

export const ReferenceDate = "2000-01-01";

export function getHourFormat(locale: string): HourFormat {
  try {
    return Intl.DateTimeFormat(locale, { hour: "numeric" }).resolvedOptions().hour12 ? HourFormat.H12 : HourFormat.H24;
  } catch {}

  return HourFormat.H12;
}

export function parseTime(t: string): { hour: number; minute: number; valid: boolean } {
  const invalid = { hour: 0, minute: 0, valid: false };
  if (!t) return invalid;
  const parts = t.split(":", 2);
  if (parts.length !== 2 || !parts[0] || !parts[1]) return invalid;

  const hour = parseInt(parts[0]);
  const minute = parseInt(parts[1]);
  if (isNaN(hour) || isNaN(minute)) return invalid;
  if (hour < 0 || hour > 23 || minute < 0 || minute > 59) return invalid;

  return { hour: hour, minute: minute, valid: true };
}

//hour12Period adjusts hour for a 12-hour clock and returns the correct hour and am/pm period
export function hour12Period(hour: number): { hour: number; period: HourPeriod } {
  if (hour === 0) return { hour: 12, period: HourPeriod.AM };
  if (hour < 12) return { hour: hour, period: HourPeriod.AM };
  if (hour === 12) return { hour: hour, period: HourPeriod.PM };
  return { hour: hour - 12, period: HourPeriod.PM };
}

//take a date in form "2020-01-01" and return it as a Date
export function stringToDate(isoDate: ISODateString | undefined): Date {
  const invalid = new Error(`invalid date string :${isoDate}:`);
  if (!isoDate) throw invalid;
  const parts = isoDate.split("-", 3).map((part: string) => {
    return parseInt(part);
  });
  if (parts.length !== 3 || !parts[0] || !parts[1] || !parts[2]) throw invalid;
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

//take a date like "2020-01-01" and return it in the localized form, e.g. "01/01/2020", based on dateFmt
export function stringToLocaleDate(isoDate?: ISODateString, dateFmt?: string): string {
  if (!dateFmt) dateFmt = HourglassGlobals.cong.country.datefmt;
  try {
    const date = stringToDate(isoDate);
    return dateToLocaleFormat(date, dateFmt);
  } catch (e) {
    return isoDate || "";
  }
}

export function stringToLocalizedDateTime(dtString: string): string {
  try {
    const djs = dayjs(dtString);
    if (djs.isValid()) return djs.toDate().toLocaleString();
  } catch (e) {}
  return dtString;
}

// this is for our own locales that browsers don't have CLDR data for
function localeLongFormat(locale: string, date: Date, month: string): string {
  switch (locale.toLowerCase()) {
    case "hy":
      return `${date.getDate()} ${month}, ${date.getFullYear()} թ.`;
    case "ht":
    case "vec-br":
      return `${date.getDate()} ${month} ${date.getFullYear()}`;
    case "kea":
      return `${date.getDate()} di ${month} di ${date.getFullYear()}`;
    default:
      return date.toLocaleDateString(locale);
  }
}

//take a date like "2020-01-01" and return the long localized form, e.g. "January 1, 2020"
export function stringToLongLocaleDate(
  isoDate: ISODateString,
  dateStyle: Intl.DateTimeFormatOptions["dateStyle"] = "long",
  locale?: string,
): string {
  if (!locale) locale = i18n.language;
  const options: Intl.DateTimeFormatOptions = { dateStyle: dateStyle };
  const date = stringToDate(isoDate);

  switch (locale) {
    case "th":
      options.calendar = "gregory";
      break;
    case "ht":
    case "hy":
    case "kea":
    case "vec-BR":
      const month = LocaleMonthNames[locale]?.get(date.getMonth()) ?? "";
      const longFormat = localeLongFormat(locale, date, month);
      switch (dateStyle) {
        case "long":
          return longFormat;
        case "full":
          const djs = getDayjs(date);
          const day = LocaleWeekdayNames[locale]?.get(djs.isoWeekday());
          switch (locale) {
            case "hy":
              return `${longFormat}, ${day}`;
            default:
              return `${day}, ${longFormat}`;
          }
      }
  }
  try {
    const ls = date.toLocaleDateString(locale, options);
    if (locale === "th") {
      // strip out the "A.D." indicator since nobody wants it
      // yes, this is a hack, but there is apparently no way to exclude the era
      return ls.replace(" ค.ศ.", "");
    }
    return ls;
  } catch (e) {
    return isoDate;
  }
}

//take a Date object and return a date in form "2020-01-01".
//this is the inverse of stringToDate.
export function dateToString(date: Date, utc = false): ISODateString {
  return getDayjs(date, utc).format(ISODateFormat);
}

export function optionalDateToString(date: Date, utc = false): ISODateString | undefined {
  try {
    const djs = getDayjs(date, utc);
    if (djs.isValid()) return djs.format(ISODateFormat);
  } catch (_) {}
}

//take a Date and turn it into the localized form based on dateFmt
//dateFmt must contain yyyy, mm, and dd. any other characters are left unchanged
export function dateToLocaleFormat(date: Date, dateFmt?: string, options?: Intl.DateTimeFormatOptions): string {
  if (!dateFmt) dateFmt = HourglassGlobals.cong.country.datefmt;
  let dateStr = date.toLocaleDateString([], options);

  // Date uses 0-based months. add 1
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  //make sure we have dd, mm, and yyyy tokens
  let missingToken = false;
  const tokens = ["dd", "mm", "yyyy"];
  tokens.forEach((token) => {
    if (dateFmt?.indexOf(token) === -1) missingToken = true;
  });

  if (missingToken) return dateStr;

  //replace the tokens
  dateStr = dateFmt.replace("dd", day);
  dateStr = dateStr.replace("mm", month);
  dateStr = dateStr.replace("yyyy", date.getFullYear().toString());

  return dateStr;
}

//take "2020-01" and return [2020, 1]
export function yearMonth(datestr: string): number[] {
  const ym = datestr.split("-", 2);
  if (ym.length !== 2 || !ym[0] || !ym[1]) return [2020, 1];
  return [parseInt(ym[0]), parseInt(ym[1])];
}

//take year and month numbers, and return "2020-01"
export function YYYYmm(year: number, month: number): string {
  const paddedMonth = ("0" + month).slice(-2);
  return `${year}-${paddedMonth}`;
}

export function localizedMonthNames(locale: string): string[] {
  const names: string[] = [];
  const override = LocaleMonthNames[locale];

  for (let i = 0; i < 12; i++) {
    if (override && override.get(i)) names.push(override.get(i) || "");
    else names.push(new Date(2020, i, 1).toLocaleString(locale, { month: "long" }));
  }

  return names;
}

export function dateStringCompare(a: ISODateString, b: ISODateString): number {
  const aDate = stringToDate(a);
  const bDate = stringToDate(b);

  if (aDate.getTime() === bDate.getTime()) return 0;
  return aDate < bDate ? -1 : 1;
}

// localizedDayOfWeek takes the ISO weekday (1=Monday, 7=Sunday) and returns the localized version in locale.
export function localizedDayOfWeek(dow: number, locale: string): string {
  // see if we have our own names (for languages browsers don't support)
  const override = LocaleWeekdayNames[locale];
  if (override && override.get(dow)) return override.get(dow) || "";

  // November 1, 2021 was a Monday
  return new Date(2021, 10, dow).toLocaleString(locale, { weekday: "long" });
}

type LocalizedLanguage = {
  id: number;
  code: string;
  native: string;
  localized: string;
};

export function localizedLanguageNames(locale: string): LocalizedLanguage[] {
  // fallback for old browsers
  if (typeof Intl !== "object" || typeof Intl.DisplayNames !== "function") {
    return HourglassGlobals.locales()
      .map((loc) => {
        return {
          id: loc.id,
          code: loc.code,
          localized: loc.name,
          native: loc.name,
        };
      })
      .sort((a, b) => CollatorSingleton.getInstance().compare(a.native, b.native));
  }

  const langNamesInLocale = new Intl.DisplayNames([locale], { type: "language" });
  return HourglassGlobals.locales()
    .map((loc) => {
      const nativeNames = new Intl.DisplayNames([loc.code], { type: "language" });
      // some locales aren't really locales (like ase) and others are obscure enough
      // to not have browser localization support (like kea)
      let localizedName = langNamesInLocale.of(loc.code);
      if (!localizedName || localizedName === loc.code) localizedName = loc.name;
      let nativeName = nativeNames.of(loc.code);
      if (!nativeName || nativeName === loc.code) nativeName = loc.name;
      switch (loc.code.toLowerCase()) {
        case "vec-br":
          localizedName = nativeName = "Talian (Brazil)";
          break;
        case "ca-valencia":
          // special handling because there is a localized name in the CLDR data, but it isn't what native speakers expect
          localizedName = nativeName = "valencià";
          break;
      }
      return {
        id: loc.id,
        code: loc.code,
        localized: localizedName,
        native: nativeName,
      };
    })
    .sort((a, b) => CollatorSingleton.getInstance().compare(a.native, b.native));
}

export function localizedLanguageName(uiLocale: string, localeCode: string): string | undefined {
  const langNamesInLocale = new Intl.DisplayNames([uiLocale], { type: "language" });
  try {
    const locName = langNamesInLocale.of(localeCode);
    if (locName === localeCode) return undefined;
    return locName;
  } catch (err: any) {
    console.error("error getting localized language name", uiLocale, localeCode, err);
  }
}

type LocalizedCountry = {
  id: number;
  code: string;
  localized: string;
};

export function localizedCountryNames(locale: string): LocalizedCountry[] {
  //fallback for old browsers
  if (typeof Intl !== "object" || typeof Intl.DisplayNames !== "function") {
    return HourglassGlobals.countries()
      .map((ctry) => {
        return {
          id: ctry.id,
          code: ctry.code,
          localized: ctry.name,
        };
      })
      .sort((a, b) => CollatorSingleton.getInstance().compare(a.localized, b.localized));
  }

  const regionNames = new Intl.DisplayNames([locale], { type: "region" });
  return HourglassGlobals.countries()
    .map((ctry) => {
      return {
        id: ctry.id,
        code: ctry.code,
        localized: regionNames.of(ctry.code.toUpperCase()) || "?",
      };
    })
    .sort((a, b) => CollatorSingleton.getInstance().compare(a.localized, b.localized));
}

//take "2020-01" and return "January 2020" in the supplied locale
export function localizedMonthYear(datestr: string, locale: string): string {
  const [year, month] = yearMonth(datestr);
  // @ts-ignore noUncheckedIndexedAccess
  return localizedMonthYearYYYYMM(year, month, locale);
}

//take 2020, 1 and return "January 2020" in the supplied locale
export function localizedMonthYearYYYYMM(year: number, month: number, locale: string): string {
  const exceptionMonth = LocaleMonthNames[locale];
  if (exceptionMonth) {
    const monthName = exceptionMonth.get(month - 1);
    switch (locale) {
      case "kea":
        return `${monthName} di ${year}`;
      default:
        return `${monthName} ${year}`;
    }
  }
  const months = localizedMonthNames(locale);
  return `${months[month - 1]} ${year}`;
}

//given a date, return the service year. Date(2021, 0) returns 2021. Date(2021, 8) returns 2022.
export function dateToServiceYear(d: Date): number {
  return d.getMonth() < 8 ? d.getFullYear() : d.getFullYear() + 1;
}

//returns the start of the service year (September 1) containing the date parameter
export function serviceYearStart(d: Date): Date {
  let year = d.getFullYear();
  if (d.getMonth() < 8) year--;
  return new Date(year, 8, 1);
}

//take "2020-09" and return 2021 - the service year for the month
export function workingServiceYear(workingMonth: string): number {
  const [year, month] = yearMonth(workingMonth);
  // @ts-ignore noUncheckedIndexedAccess
  const workingDate = new Date(year, month - 1, new Date().getDate());
  return dateToServiceYear(workingDate);
}

//serviceYearStartEnd takes a single integer year, and returns a string representing the date range of the service year.
//the input year is the service year.
//e.g. if 2016 is input, the output could be '9/1/2015 - 8/31/2016', depending on date locale formatting
//the two flag indicates that we should show the range of two years (e.g. for record cards)
//and thus the start year will be two years before the svcYear parameter
export function serviceYearStartEnd(dateFmt: string, svcYear: number, two: boolean): string {
  if (svcYear < 2004) return "";

  const startYear = two ? svcYear - 2 : svcYear - 1;
  //Date uses zero-based months
  const startstr = dateToLocaleFormat(new Date(startYear, 8, 1), dateFmt);
  const endstr = dateToLocaleFormat(new Date(svcYear, 7, 31), dateFmt);

  return `${startstr} - ${endstr}`;
}

export function calendarYearStartEnd(year: number): { start: ISODateString; end: ISODateString } {
  return {
    start: `${year}-01-01`,
    end: `${year}-12-31`,
  };
}

export function monthToDate(month: string): Date {
  const [y, m] = yearMonth(month);
  // @ts-ignore noUncheckedIndexedAccess
  return new Date(y, m - 1);
}

//Determines the total number of months from m1 to m2, inclusive of both.
//Example: monthCount("2021-04", "2021-09") returns 6.
export function monthCount(m1: string, m2: string): number {
  const d1 = monthToDate(m1);
  const d2 = monthToDate(m2);

  return d2.getMonth() - d1.getMonth() + 12 * (d2.getFullYear() - d1.getFullYear()) + 1;
}

export function addMonth(date: Date, months: number): Date {
  return dayjs(date).add(months, "months").toDate();
}

// returns the number of mondays in a month
export function weekCount(date: Date): number {
  let monday = dayjs(firstMondayOfMonth(date));
  const month = monday.month();
  let out = 0;
  while (month === monday.month()) {
    out++;
    monday = monday.add(7, "d");
  }

  return out;
}

export const ISODateFormat = "YYYY-MM-DD";
export const ISOMonthFormat = "YYYY-MM";
export const TimeFormat = "HH:mm";

export function getDayjs(date: Date | ISODateString, utc = false): Dayjs {
  if (date instanceof Date) return utc ? dayjs.utc(date) : dayjs(date);
  return utc ? dayjs.utc(date, ISODateFormat) : dayjs(date, ISODateFormat, true);
}

export function firstMondayOfMonth(date: Date): Date {
  const d = dayjs(date).startOf("month").day(8);
  if (d.date() > 7) return d.day(-6).toDate();
  return d.toDate();
}

// daysOfMonth returns an array of a given day of the week in a month
export function daysOfMonth(date: Date | ISODateString, dayOfWeek: number, includeLastMonth = false): ISODateString[] {
  const out: string[] = [];

  let d = getDayjs(date).startOf("month").isoWeekday(dayOfWeek);
  const dOrig = d;
  if (d.date() > 7) {
    d = d.add(7, "d");
  }

  const month = d.month();
  while (month === d.month()) {
    out.push(d.format(ISODateFormat));
    d = d.add(7, "d");
  }

  if (includeLastMonth && dOrig.date() > 7) {
    // if the first day was in a different month, prepend it
    out.unshift(dOrig.format(ISODateFormat));
  }

  return out;
}

// see if date is >= start and <= end
export function dateIsBetween(date: Date, start: Date, end: Date): boolean {
  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
}

export function dateRangeIsConflicting(
  dateRange: { from: Date; to: Date },
  otherDateRanges: { from: Date; to: Date }[] = [],
): boolean {
  if (!otherDateRanges.length) return false;
  for (const range of otherDateRanges) {
    if (dateIsBetween(dateRange.from, range.from, range.to) || dateIsBetween(dateRange.to, range.from, range.to)) {
      return true;
    }
  }
  return false;
}

// similar to daysOfMonth, but using a range of dates
export function daysInRange(
  start: Date | ISODateString,
  end: Date | ISODateString,
  dayOfWeek?: number,
): ISODateString[] {
  const out: ISODateString[] = [];

  if (dayOfWeek === 7) {
    dayOfWeek = 0;
  }
  let d = dayOfWeek ? getDayjs(start).day(dayOfWeek) : getDayjs(start);
  const e = getDayjs(end);

  while (!d.isAfter(e)) {
    out.push(d.format(ISODateFormat));
    d = d.add(dayOfWeek ? 7 : 1, "d");
  }

  return out;
}

export function weekOf(date: Date | ISODateString, dayOfWeek = 1): Date {
  return getDayjs(date).isoWeekday(dayOfWeek).toDate();
}

export function weekOfString(date: Date | ISODateString, dayOfWeek?: number): ISODateString {
  return dateToString(weekOf(date, dayOfWeek));
}

export function currentWeek(date: Date): boolean {
  const now = weekOf(new Date());
  return dateToString(now) === dateToString(date);
}

// Month provides for common operations on a month and is mainly convenience for calling some of the above methods
export class Month {
  year: number;
  month: number;

  static fromString(ym: string): Month {
    const [y, m] = yearMonth(ym);
    // @ts-ignore noUncheckedIndexedAccess
    return new Month(y, m);
  }

  static fromDateString(dateStr: ISODateString): Month {
    return this.fromDate(stringToDate(dateStr));
  }

  static fromDate(dt: Date): Month {
    return new Month(dt.getFullYear(), dt.getMonth() + 1);
  }

  constructor(year: number, month: number) {
    if (month > 12) throw new Error(`invalid month: ${month}`);
    this.year = year;
    this.month = month;
  }

  addMonth() {
    this.month++;
    if (this.month > 12) {
      this.year++;
      this.month = 1;
    }
  }

  addMonths(count: number) {
    for (let i = 0; i < count; i++) {
      this.addMonth();
    }
  }

  equals(m: Month): boolean {
    return this.month === m.month && this.year === m.year;
  }

  after(m: Month): boolean {
    if (this.year > m.year) return true;
    if (this.year < m.year) return false;
    return this.month > m.month;
  }

  before(m: Month): boolean {
    if (this.year < m.year) return true;
    if (this.year > m.year) return false;
    return this.month < m.month;
  }

  toDate(): Date {
    return new Date(this.year, this.month - 1);
  }

  toString(): string {
    return YYYYmm(this.year, this.month);
  }

  toDateString(): ISODateString {
    return `${this.toString()}-01`;
  }

  toLocaleString(locale: string): string {
    return localizedMonthYearYYYYMM(this.year, this.month, locale);
  }

  toLocaleMonthName(locale: string): string {
    const months = localizedMonthNames(locale);
    return `${months[this.month - 1]}`;
  }

  weeks(includeLastMonth = false): string[] {
    return daysOfMonth(this.toDate(), 1, includeLastMonth);
  }

  end(): ISODateString {
    return dateToString(dayjs(this.toDate()).endOf("month").toDate());
  }

  startEnd(): ISODateString[] {
    return [this.toDateString(), this.end()];
  }

  start(): ISODateString {
    return this.toDateString();
  }

  // did this month end in the past?
  isInPast(): boolean {
    return isBeforeToday(this.end());
  }
}

//detects if the browser supports the 'month' type on an <input>
export const supportsMonthInput = testMonthInput();

function testMonthInput(): boolean {
  const input = document.createElement("input");
  const val = "a";
  input.setAttribute("type", "month");
  input.setAttribute("value", val);
  return input.value !== val;
}

// converts HH:MM:SS to HH:MM am/pm
export function localizedTime(time: string, locale: string, tzName = ""): string {
  locale = localeForTime(locale);
  // to try to be better about DST, we can pass in an optional date. otherwise, we pick a default
  // if (!date) date = "2022-01-02";
  const datetime = `2022-01-02T${time}`;
  const djs = tzName ? dayjs.tz(datetime, tzName) : dayjs(datetime);
  if (!djs.isValid()) return time;
  return djs.toDate().toLocaleTimeString(locale, { hour: "numeric", minute: "2-digit" });
}

export function dateToLocalizedUTCTime(d: Date, locale: string): string {
  locale = localeForTime(locale);
  return d.toLocaleTimeString(locale, { hour: "numeric", minute: "2-digit", timeZone: "UTC" });
}

export function dateToLocalizedUTCTimeShort(d: Date, locale: string): string {
  locale = localeForTime(locale);
  const loc = d.toLocaleTimeString(locale, { hour: "numeric", minute: "2-digit", timeZone: "UTC" });
  // strip off any am/pm
  return loc
    .toLocaleLowerCase(locale)
    .replace(/[ap.m]/g, "")
    .trim();
}

export function localizedTimeRange(d1: Date, d2: Date, locale: string): string {
  locale = localeForTime(locale);
  try {
    const fmt = new Intl.DateTimeFormat(locale, { timeStyle: "short", timeZone: "UTC" });
    return fmt.formatRange(d1, d2);
  } catch {
    // Intl or formatRange not supported in the browser
    return `${dateToLocalizedUTCTime(d1, locale)} – ${dateToLocalizedUTCTime(d2, locale)}`;
  }
}

export function localizedDateRange(d1: Date, d2: Date, locale: string): string {
  locale = localeForTime(locale);
  try {
    const fmt = new Intl.DateTimeFormat(locale, { timeZone: "UTC", dateStyle: "long" });
    return fmt.formatRange(d1, d2);
  } catch {
    // Intl or formatRange not supported in the browser
    return `${d1.toLocaleDateString(locale)} – ${d2.toLocaleDateString(locale)}`;
  }
}

type IntlDateTimeWeekday = "long" | "short" | "narrow" | undefined;

// localeForTime includes the country if the locale is one where that makes sense
function localeForTime(locale: string): string {
  if (locale === "es") {
    // append the country code
    const country = HourglassGlobals.cong.country.code.toUpperCase();
    return `${locale}-${country}`;
  }
  return locale;
}

export function localizedDayAndTime(
  isoDow: number,
  time: string,
  locale: string,
  weekday: IntlDateTimeWeekday = "short",
): string {
  locale = localeForTime(locale);
  const parsedTime = parseTime(time);
  if (!parsedTime.valid) return "?";
  const df = Intl.DateTimeFormat(locale, { hour: "numeric", minute: "numeric", weekday });
  // November 1, 2021 was a Monday
  const date = new Date(2021, 10, isoDow, parsedTime.hour, parsedTime.minute);
  return df.format(date);
}

export function isAfterToday(date: ISODateString): boolean {
  return getDayjs(date).isAfter(dayjs(), "day");
}

export function isBeforeToday(date: ISODateString): boolean {
  return getDayjs(date).isBefore(dayjs(), "day");
}

// return the midweek meeting date for cong in the given week. this accounts for future meeting schedules.
export function mmDate(week: ISODateString, cong: Cong, events?: ScheduledEvent[]): ISODateString {
  const custom = events?.find(
    (ev) =>
      ev.week === weekOfString(week) &&
      ev.event === Events.custom &&
      ev.customNewWeekday > 0 &&
      ev.customNewWeekday <= 7 &&
      getDayjs(ev.date).isoWeekday() < 6,
  );
  if (custom) return dateToString(weekOf(week, custom.customNewWeekday));

  const coVisit = events?.find((ev) => ev.week === weekOfString(week))?.event === Events.co;
  const dow = coVisit ? 2 : dowFor(week, cong, "midweek");

  return dateToString(weekOf(week, dow));
}

// return the weekend meeting date for cong in the given week. this accounts for future meeting schedules.
export function wmDate(week: ISODateString, cong: Cong | Congregation, events?: ScheduledEvent[]): ISODateString {
  const custom = events?.find(
    (ev) =>
      ev.week === weekOfString(week) &&
      ev.event === Events.custom &&
      ev.customNewWeekday > 0 &&
      ev.customNewWeekday <= 7 &&
      getDayjs(ev.date).isoWeekday() >= 6,
  );
  if (custom) return dateToString(weekOf(week, custom.customNewWeekday));

  const dow = dowFor(week, cong, "weekend");
  return dateToString(weekOf(week, dow));
}

export function wmDateTime(weekOf: ISODateString, cong: Cong | Congregation, events?: ScheduledEvent[]): Dayjs {
  const c = CongregationToCong(cong);
  const dateStr = wmDate(weekOf, cong, events);
  const zone = c.timezone.name || HourglassGlobals.cong.timezone.name;
  const date = dayjs.tz(dateStr, ISODateFormat, zone);
  const time = parseTime(c.wmtime);
  return date.hour(time.hour).minute(time.minute);
}

function dowFor(week: ISODateString, cong: Cong | Congregation, meetingType: "midweek" | "weekend"): number {
  const c = CongregationToCong(cong);

  // fallback: if we don't have any valid info
  const fallback = meetingType === "midweek" ? 2 : 7;
  const currentDow = meetingType === "midweek" ? c.mmdow : c.wmdow;
  const futureDow = meetingType === "midweek" ? c.mmdow_future : c.wmdow_future;

  let dow = currentDow || fallback;
  if (futureDow && futureDow !== dow && cong.future_start_date) {
    // see if the future start date applies
    const futureStartDate = dayjs(cong.future_start_date);
    // when would the meeting be held, if we used the current and future dow?
    const currentMeeting = getDayjs(weekOf(week, currentDow), true);
    const futureMeeting = getDayjs(weekOf(week, futureDow), true);
    if (!currentMeeting.isBefore(futureStartDate, "day") && !futureMeeting.isBefore(futureStartDate, "day")) {
      dow = futureDow;
    }
  }

  return dow;
}

export function timeFor(week: ISODateString, cong: Cong | Congregation, meetingType: "midweek" | "weekend"): string {
  const c = CongregationToCong(cong);

  // fallback: if we don't have any valid info
  const fallback = meetingType === "midweek" ? "19:00" : "10:00";
  const currentTime = meetingType === "midweek" ? c.mmtime : c.wmtime;
  const futureTime = meetingType === "midweek" ? c.mmtime_future : c.wmtime_future;
  const currentDow = meetingType === "midweek" ? c.mmdow : c.wmdow;
  const futureDow = meetingType === "midweek" ? c.mmdow_future : c.wmdow_future;

  let time = currentTime || fallback;
  if (futureTime && futureTime !== time && cong.future_start_date) {
    // see if the future start date applies
    const futureStartDate = dayjs(cong.future_start_date);
    // when would the meeting be held, if we used the current and future dow?
    const currentMeeting = getDayjs(weekOf(week, currentDow), true);
    const futureMeeting = getDayjs(weekOf(week, futureDow), true);
    if (!currentMeeting.isBefore(futureStartDate, "day") && !futureMeeting.isBefore(futureStartDate, "day")) {
      time = futureTime;
    }
  }

  return time;
}

// dateRanges takes an array of ISO dates and returns an array of DateRanges for each contiguous range
export function dateRanges(dates: ISODateString[]): DateRange[] {
  dates.sort((a, b) => (a > b ? 1 : -1));
  const result: DateRange[] = [];
  if (dates.length === 0) return result;
  const initialDate = dates[0];
  if (!initialDate) return result;
  let curRange: DateRange = { from: initialDate, to: initialDate };
  let lastDate: ISODateString | null = null;
  for (let i = 0; i < dates.length; i++) {
    const thisDate = dates[i];
    if (!thisDate) continue;
    const djs = getDayjs(thisDate, true);
    if (lastDate) {
      const lastDjs = getDayjs(lastDate, true);
      if (djs.diff(lastDjs, "days") === 1) {
        // last entry was yesterday, so we're just extending the current range
        curRange.to = thisDate;
      } else {
        // this is a new range
        result.push(curRange);
        curRange = { from: thisDate, to: thisDate };
      }
    }
    lastDate = thisDate;
  }

  // add the final range in if it wasn't already
  const lastResult = result[result.length - 1];
  if (!lastResult || (lastResult && (lastResult.from !== curRange.from || lastResult.to !== curRange.to))) {
    result.push(curRange);
  }

  return result;
}
