import { Feature, FeatureCollection, GeoJsonObject, GeoJsonProperties, MultiPolygon, Position } from "geojson";
import { t } from "i18next";
import * as L from "leaflet";
import { LatLngBounds } from "leaflet";
import { Map as MaplibreMap, StyleSpecification } from "maplibre-gl";
import pointInPolygon from "point-in-polygon";
import { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useMap, useMapEvent } from "react-leaflet";
import { useCongTerritory, useTerritories } from "../../../api/territory";
import { territoryAddressCompare } from "../../../helpers/address";
import { isValidLatLng } from "../../../helpers/geo";
import HourglassGlobals from "../../../helpers/globals";
import { CollatorSingleton, containsFuzzy } from "../../../helpers/locale";
import { userCompare } from "../../../helpers/user";
import { Permission } from "../../../types/permission";
import { CongSettings } from "../../../types/scheduling/settings";
import { MapPoint, Territory, TerritoryAddress, TerritoryRecord } from "../../../types/scheduling/territory";
import User, { UserStatus } from "../../../types/user";
import { DropdownFilter } from "../../scheduling/common";
import { QueryClient } from "@tanstack/react-query";
import QueryKeys from "../../../api/queryKeys";
import { stringToLocaleDate } from "../../../helpers/dateHelpers";

export const mapboxAccessToken =
  import.meta.env.VITE_MAPBOX_ACCESS_TOKEN ||
  "pk.eyJ1IjoiY29uZ3NvZnQiLCJhIjoiY2x0bTR0cGZ5MWhyNjJpcDVqZ3B6NGx6OCJ9.c-WNCj8cEPnphpOB_zHRWQ";

export function terrLabel(terr: Territory): string {
  // "number - locality" unless sortByLocality is enabled, then it's "locality - number"
  const numberTrimmed = terr.number.trim();
  const localityTrimmed = terr.locality.trim();
  const first = HourglassGlobals.whoamiSettings.territory.sortByLocality ? localityTrimmed : numberTrimmed;
  const second = HourglassGlobals.whoamiSettings.territory.sortByLocality ? numberTrimmed : localityTrimmed;

  if (first && second) {
    return `${first} — ${second}`;
  }

  return first || second;
}

export function formatTerritoryRecord(trec: TerritoryRecord, userMap: Map<number, User>): string {
  const publisher = userMap.get(trec.user_id);
  const start = trec.start ? stringToLocaleDate(trec.start) : "---";
  const end = trec.end ? stringToLocaleDate(trec.end) : "---";

  return `${start} | ${end} | ${publisher?.displayName ?? ""}`;
}

export function canUpdateTerritory(): boolean {
  return HourglassGlobals.permissions.has(Permission.UpdateTerritory);
}

const leadingNumberRegex = /^\s*(\d*)(\D*)\s*/;

export function territoryRecordCompare(a: TerritoryRecord, b: TerritoryRecord): number {
  return (a.start ?? 0) > (b.start ?? 0) ? 1 : -1;
}

export function territoryCompare(a: Territory, b: Territory): number {
  // split the number portion into the part that starts with digits, and the rest
  // the goal is to sort 1, 1A, 2, ... 10, 10A
  if (a.disabled && !b.disabled) return 1;
  if (!a.disabled && b.disabled) return -1;

  if (HourglassGlobals.whoamiSettings.territory.sortByLocality) {
    const localityCompare = CollatorSingleton.getInstance().compare(a.locality.trim(), b.locality.trim());
    if (localityCompare != 0) return localityCompare;
  }

  const aTrimmed = a.number.trim();
  const bTrimmed = b.number.trim();

  const aMatch = aTrimmed.match(leadingNumberRegex);
  const bMatch = bTrimmed.match(leadingNumberRegex);
  if (aMatch && bMatch && aMatch[1] && bMatch[1]) {
    const aInt = parseInt(aMatch[1]);
    const bInt = parseInt(bMatch[1]);
    const aStr = aMatch[2] ?? "";
    const bStr = bMatch[2] ?? "";
    if (!isNaN(aInt) && !isNaN(bInt)) {
      // we have two valid numbers. use them
      if (aInt !== bInt) return aInt - bInt;
      // they're the same. sort based on the text portion
      if (aStr !== bStr) return CollatorSingleton.getInstance().compare(aStr, bStr);
      // they are the same. sort based on the locality
      return CollatorSingleton.getInstance().compare(a.locality, b.locality);
    }
  }
  return CollatorSingleton.getInstance().compare(aTrimmed, bTrimmed);
}

export type PrintOptions = {
  excludeHideOnMapAddresses: boolean;
  excludeAllAddresses: boolean;
  invertedShading: boolean;
  mapFullPage: boolean;
};

export function addressesForPDF(addresses: TerritoryAddress[], options?: PrintOptions): TerritoryAddress[] {
  let locationCount = 0;
  return (
    addresses
      .filter((a) => {
        return !(options?.excludeHideOnMapAddresses && a.hideOnMap);
      })
      .sort(territoryAddressCompare)
      .map((a) => {
        if (!a.location) return { ...a, pinLabel: undefined };
        locationCount++;
        return { ...a, pinLabel: `${locationCount}` };
      }) ?? []
  );
}

export const PublisherDDM = (props: {
  userMap: Map<number, User>;
  selectedUser: number | null;
  searchString: string;
  setSelectedUser: (set: number) => void;
  setSearchString: (set: string) => void;
  size?: "sm" | "lg";
  showClear?: boolean;
}) => {
  const [needSetFocus, setNeedSetFocus] = useState(false);

  return (
    <ButtonGroup className="dropdown-wide">
      <DropdownButton
        title={props.userMap.get(props.selectedUser || 0)?.displayName || t("general.none-selected")}
        variant="secondary"
        className="dropdown-bounded dropdown-wide"
        size={props.size}
        onToggle={(nextShow: boolean) => {
          if (!nextShow) props.setSearchString("");
          setNeedSetFocus(nextShow);
        }}
      >
        {props.showClear && !!props.selectedUser && (
          <>
            <Dropdown.Item onClick={() => props.setSelectedUser(0)}>
              ❌ <i>{t("schedules.fill.clear")}</i>
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        <DropdownFilter filter={props.searchString} setFilter={props.setSearchString} setFocus={needSetFocus} />
        {Array.from(props.userMap.values())
          .filter((u) => {
            return containsFuzzy(u.displayName, props.searchString) && u.status !== UserStatus.StudentOnly;
          })
          .sort(userCompare)
          .map((u) => (
            <Dropdown.Item key={u.id} onClick={() => props.setSelectedUser(u.id)}>
              {u.displayName}
            </Dropdown.Item>
          ))}
      </DropdownButton>
    </ButtonGroup>
  );
};

export function useTerritoryMap() {
  const terrQuery = useTerritories();
  if (!Array.isArray(terrQuery.data)) {
    return new Map<number, Territory>();
  }
  return new Map<number, Territory>(terrQuery.data?.map((t) => [t.id, t]) || []);
}

export type MapTileProvider = {
  url: string;
  attribution: string;
  isVector: boolean;
};

export function tileStyle(provider: MapTileProvider): StyleSpecification | string {
  const rasterTileStyle = (prov: MapTileProvider): StyleSpecification => {
    return {
      version: 8,
      sources: {
        "raster-tiles": {
          type: "raster",
          tiles: [prov.url],
          tileSize: 256,
          attribution: prov.attribution,
        },
      },
      glyphs: maptilerGlyphs(),
      layers: [
        {
          id: "rasterLayer",
          type: "raster",
          source: "raster-tiles",
          minzoom: 0,
          maxzoom: 22,
        },
      ],
    };
  };

  return provider.isVector ? provider.url : rasterTileStyle(provider);
}

export function maptilerGlyphs(): string {
  return "https://api.maptiler.com/fonts/{fontstack}/{range}.pbf?key=" + maptilerApiKey();
}

export function maptilerApiKey(): string {
  const devKey = import.meta.env.VITE_MAPTILER_KEY;
  return !!devKey ? devKey : "ZLfbPHyaiJoxOmcJHJZt";
}

export function mapFontStack(settings: CongSettings): string[] {
  // needed so that HERE maps use an allowed font
  if (settings.terr_tile_provider === 4) return ["Fira GO Map"];
  return [];
}

export function tileProvider(settings: CongSettings): MapTileProvider {
  const mapTilerAttribution =
    '<a href="https://www.maptiler.com"><img src="https://api.maptiler.com/resources/logo.svg" alt="MapTiler Logo"/></a> <a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
  const mapTilerKey = maptilerApiKey();
  const year = new Date().getFullYear();
  const hereAttribution = `© ${year} HERE`;
  const esriAttribution = "Esri, Maxar, Earthstar Geographics, and the GIS User Community";
  const awsKey =
    import.meta.env.VITE_AWS_MAPS_KEY ||
    "v1.public.eyJqdGkiOiI0NjNlZGFkYS0yNzUwLTRlOTYtODE5Yi05YjFmNWM5NTRhOTEifS0N_ww6a5UuxFEUG-m-7ioVIL-kTWwtOd6uphugfE0KoiNuteTzZX1yyMyFrWaMmIxg0pl8p0sq0V7DE4xZLBLbPHelFc436eGrVWxfhVoRlLCkLVLaP0GXUQiBYHCeziNmbTML9oTWn2sn5vMTjqDoc9bLu3CfuEFNgD8WKRuy-fv89qd99Ds0W6QS6hebuvotFQ7j_93hbYAVwjLXCy-uGBSKT5ZSj_h-sj63353KvkcbY7CWvOU2-NhFbM2HnzVQ7kdgVKjz6QCnyutjjX8qxLtj-lfegaODOCZRv1GInJ80OAbZXL0V5cBCBiElANDk46MbgSYap3x76hMH-f0.ZWU0ZWIzMTktMWRhNi00Mzg0LTllMzYtNzlmMDU3MjRmYTkx";
  const mapboxAttribution =
    '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>';

  switch (settings.terr_tile_provider) {
    case 1:
      return {
        attribution:
          'Maps &copy; <a href="https://www.thunderforest.com" target="_blank">Thunderforest</a>, Data &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        url: "https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=52d4cb2673334b43ae5be1e59041afab",
        isVector: false,
      };
    case 2:
      return {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        isVector: false,
      };
    case 3:
      return {
        attribution: mapTilerAttribution,
        url: `https://api.maptiler.com/maps/streets-v2/style.json?key=${mapTilerKey}`,
        isVector: true,
      };
    case 4:
      return {
        attribution: hereAttribution,
        url: `https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/here/style-descriptor?key=${awsKey}`,
        isVector: true,
      };
    case 5:
      return {
        attribution: mapTilerAttribution,
        url: `https://api.maptiler.com/maps/satellite/style.json?key=${mapTilerKey}`,
        isVector: true,
      };
    case 6:
      return {
        attribution: esriAttribution,
        url: `https://maps.geo.us-east-1.amazonaws.com/maps/v0/maps/esriSatellite/style-descriptor?key=${awsKey}`,
        isVector: true,
      };
    case 7:
      return {
        attribution: mapTilerAttribution,
        url: `https://api.maptiler.com/maps/openstreetmap/style.json?key=${mapTilerKey}`,
        isVector: true,
      };
    case 8:
      return {
        attribution: mapTilerAttribution,
        url: `https://api.maptiler.com/maps/hybrid/style.json?key=${mapTilerKey}`,
        isVector: true,
      };
    case 9:
      return {
        attribution: mapboxAttribution,
        url: `https://api.mapbox.com/styles/v1/mapbox/streets-v12?access_token=${mapboxAccessToken}`,
        isVector: true,
      };
    case 10:
      return {
        attribution: mapboxAttribution,
        url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12?access_token=${mapboxAccessToken}`,
        isVector: true,
      };
    default:
      return {
        attribution: mapTilerAttribution,
        url: `https://api.maptiler.com/maps/basic-v2/style.json?key=${mapTilerKey}`,
        isVector: true,
      };
  }
}

export type LabeledLayer = {
  label: string;
  geojson: GeoJsonObject;
  id: number;
};

export function useAllMappedTerritories(filter?: (t: Territory) => boolean): LabeledLayer[] {
  const allTerritoriesQuery = useTerritories();

  return allTerritoriesQuery.data
    ? allTerritoriesQuery.data
        .filter((t): t is Territory & { boundaries: string } => !!t.boundaries)
        .filter((t) => (!!filter ? filter(t) : true))
        .map((t) => {
          return {
            label: t.number,
            geojson: JSON.parse(t.boundaries),
            id: t.id,
          };
        })
    : [];
}

export function MapManager(props: {
  territory?: Territory;
  needCenter: boolean;
  setNeedCenter: (need: boolean) => void;
  setZoomLevel: (zoom: number) => void;
  labeledLayers?: LabeledLayer[];
  setLabeledLayers: (ll: LabeledLayer[]) => void;
  setPrintImageBlob?: (b: Blob) => void;
  setMaplibreMap: (map: MaplibreMap) => void;
  onClick?: (p: Position) => void;
  restrictMarkerToBounds?: boolean;
  addresses: TerritoryAddress[];
}) {
  const map = useMap();
  const allLabeledLayers = useAllMappedTerritories().filter((ll) => ll.id !== props.territory?.id);
  const congTerritoryQuery = useCongTerritory();

  map.attributionControl.setPrefix(false);
  const boundaries = props.territory?.boundaries ? JSON.parse(props.territory.boundaries) : undefined;
  const annotations = props.territory?.annotations ? JSON.parse(props.territory.annotations) : undefined;

  useMapEvent("click", (e) => {
    const latlng = e.latlng;
    if (!props.onClick || !latlng || !isValidLatLng(latlng.lat, latlng.lng)) return;
    const pos: Position = [latlng.lng, latlng.lat];
    // if restrictMarkerToBounds is true, then don't allow clicks outside the polygon to set a marker
    if (props.restrictMarkerToBounds) {
      try {
        const lGeo = L.geoJSON(boundaries);
        if (!lGeo.getBounds().contains(latlng)) return;
      } catch (e: any) {}
    }
    props.onClick(pos);
  });

  const getAddressNotations = (): FeatureCollection => {
    return {
      type: "FeatureCollection",
      features: props.addresses
        .filter((a) => a.location?.x && a.location?.y)
        .map((a) => {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [a.location?.x ?? 0, a.location?.y ?? 0],
            },
          } as Feature;
        }),
    };
  };

  const getMapBounds = (): LatLngBounds | undefined => {
    const geoObjects = boundaries ? [boundaries] : [];
    if (annotations) geoObjects.push(annotations);
    if (props.addresses.length) geoObjects.push(getAddressNotations());
    const geojson = L.geoJSON(geoObjects);
    if (geojson) {
      const bounds = geojson.getBounds();
      // see if the bounds is just one point; if so, extend it
      if (bounds && bounds.getNorthEast() && bounds.getNorthEast().equals(bounds.getSouthWest())) {
        return bounds.getNorthEast().toBounds(500);
      } else if (bounds.isValid()) return bounds;
    }

    if (congTerritoryQuery.data) {
      // center it in the congregation's territory
      const lGeo = L.geoJSON(JSON.parse(congTerritoryQuery.data.boundaries));
      return lGeo.getBounds();
    }

    // we don't know where to center it. the map will be the entire world
    return;
  };

  const populateLabeledLayers = useCallback(() => {
    // if we weren't supplied with our own labeled layers, compute them
    if (props.labeledLayers) return;
    // find which of all the labeled layers are on the map
    try {
      const bounds = map.getBounds();
      const center = bounds.isValid() ? bounds.getCenter() : undefined;
      const layers = allLabeledLayers
        .filter((ll) => {
          const geo = L.geoJSON(ll.geojson);
          return bounds.intersects(geo.getBounds());
        })
        .sort((lla, llb) => {
          // we want to order by the closest center - comparing the center of the map to the center of the layer's bounds
          if (!center) return 0;
          let distanceA = 0;
          let distanceB = 0;
          try {
            const geoA = L.geoJSON(lla.geojson);
            const centerA = geoA.getBounds().getCenter();
            distanceA = centerA.distanceTo(center);
          } catch (err: any) {
            console.error("sorting labeled layers a", err);
          }

          try {
            const geoB = L.geoJSON(llb.geojson);
            const centerB = geoB.getBounds().getCenter();
            distanceB = centerB.distanceTo(center);
          } catch (err: any) {
            console.error("sorting labeled layers b", err);
          }

          if (distanceA === distanceB) return 0;
          return distanceA < distanceB ? -1 : 1;
        });
      // never more than 30 for performance reasons. we could optimize this to make it the ones closest to the map center
      props.setLabeledLayers(layers.slice(0, 30));
    } catch (err: any) {
      console.error("error populating labeled layers on map", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, allLabeledLayers]);

  useMapEvent("zoomend", () => {
    props.setZoomLevel(map.getZoom());
    populateLabeledLayers();
  });

  let mlMap: MaplibreMap | undefined;
  map.eachLayer((l) => {
    try {
      // @ts-ignore we are catching failures
      mlMap = l.getMaplibreMap();
    } catch {}
  });
  const haveMlMap = !!mlMap;

  useEffect(() => {
    if (mlMap) props.setMaplibreMap(mlMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveMlMap, props.setMaplibreMap]);

  useEffect(() => {
    if (props.needCenter) {
      const center = getMapBounds();
      if (center && center.isValid()) map.fitBounds(center);
      else map.fitWorld();
      props.setNeedCenter(false);
      props.setZoomLevel(map.getZoom());
      populateLabeledLayers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, populateLabeledLayers]);

  return null;
}

export enum TerritoryPage {
  Checkout,
  Return,
  Records,
  Territories,
  CongregationTerritory,
  Import,
  Statistics,
  Addresses,
  Tags,
}

export type TerritoryOption = {
  id: number;
  label: string;
};

export type TerritoryAddressWithLocation = TerritoryAddress & { location: NonNullable<MapPoint> };

export function locationsForTerritory(
  geojson: MultiPolygon,
  addresses: TerritoryAddressWithLocation[],
): TerritoryAddress[] {
  return addresses.filter((a) =>
    geojson.coordinates.some((polygon) => {
      if (Array.isArray(polygon)) return polygon.some((c) => pointInPolygon([a.location.x, a.location.y], c));
      return false;
    }),
  );
}

function territoryGeoJSONProperties(territory: Territory): GeoJsonProperties {
  return {
    number: territory.number,
    locality: territory.locality,
    business: territory.business,
    notes: territory.notes,
    url: territory.url,
    disabled: territory.disabled,
    tags: territory.tags,
  };
}

export function territoryAsGeoJSON(territory: Territory): FeatureCollection {
  const features: Feature[] = [];

  if (territory.boundaries) {
    const boundary: MultiPolygon = JSON.parse(territory.boundaries);
    const feature: Feature<MultiPolygon> = {
      type: "Feature",
      id: territory.id,
      geometry: {
        type: "MultiPolygon",
        coordinates: boundary.coordinates,
      },
      properties: territoryGeoJSONProperties(territory),
    };

    features.push(feature);
  }

  if (territory.annotations) {
    const annotations: FeatureCollection = JSON.parse(territory.annotations);
    if (Array.isArray(annotations.features) && annotations.features.length > 0) {
      if (!territory.boundaries && annotations.features[0]) {
        annotations.features[0].id = territory.id;
        annotations.features[0].properties = {
          ...annotations.features[0].properties,
          ...territoryGeoJSONProperties(territory),
        };
      }

      features.push(...annotations.features);
    }
  }

  return {
    type: "FeatureCollection",
    features: features,
  };
}

export function territoryFilename(territory: Territory): string {
  const label = terrLabel(territory).replace(/[^a-z0-9]+/gi, "_");
  return `${t("schedules.territory.territory").toLocaleLowerCase()}_${label}`;
}

export async function invalidateTerritoriesAfterImport(queryClient: QueryClient) {
  await Promise.all([
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.Territories],
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TerritoryAddresses],
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TerritoryRecordsLatest],
    }),
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.TerritoryRecordsAll],
    }),
  ]);
}
