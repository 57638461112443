import { Feature, FeatureCollection, MultiPolygon, Polygon, Position } from "geojson";
import { MapPoint } from "../types/scheduling/territory";
import polylabel from "./polylabel";

export const DefaultSrid = 4326;

/* geoJsonMultiPolygon takes geojson and sees if it has 1 or more Polygon features.
   If it does, it modifies them to all be part of a MultiPolygon.
   This is intended for use with KML import, where we get a FeatureCollection.
 */
export function geoJsonMultiPolygon(geoJson: FeatureCollection): Feature {
  const multiPolygons: Position[][][] = [];
  geoJson.features.forEach((gf) => {
    if (gf.geometry.type === "Polygon") multiPolygons.push(gf.geometry.coordinates);
  });

  return {
    type: "Feature",
    geometry: {
      type: "MultiPolygon",
      coordinates: multiPolygons,
    },
    properties: {},
  };
}

/* geoJsonPolygons is the opposite of geoJsonMultiPolygon: it takes a MultiPolygon and
turns it into an array of Polygons
 */
export function geoJsonPolygons(geoJson: MultiPolygon): Polygon[] {
  const features: Polygon[] = [];
  if (geoJson.type === "MultiPolygon" && geoJson.coordinates.length) {
    geoJson.coordinates.forEach((mp) =>
      features.push({
        type: "Polygon",
        coordinates: mp,
      }),
    );
  }

  return features;
}

export function polygonToMultiPolygon(polygon: Polygon): MultiPolygon {
  return {
    type: "MultiPolygon",
    coordinates: [polygon.coordinates],
  };
}

export function multipolygonToPolygons(multiPolygon: MultiPolygon): Polygon[] {
  return multiPolygon.coordinates.map((mp): Polygon => {
    return {
      type: "Polygon",
      coordinates: mp,
    };
  });
}

export function polygonCenter(polygon: Polygon): Position {
  return polylabel(polygon.coordinates, 0.000001).position;
}

export function mapPointFromPosition(p: Position): MapPoint {
  return {
    x: p[0] ?? 0,
    y: p[1] ?? 0,
    srid: DefaultSrid,
  };
}

export function positionFromMapPoint(mp: MapPoint): Position {
  return [mp.x, mp.y];
}

export function latLngToPosition(lat: number, lng: number): Position {
  return [lng, lat];
}

export function isValidLatLng(lat: number, lng: number): boolean {
  const validLat = isFinite(lat) && Math.abs(lat) <= 90;
  const validLng = isFinite(lng) && Math.abs(lng) <= 180;
  return validLat && validLng;
}

export function latLngToMapPoint(lat: number, lng: number): MapPoint {
  return {
    x: lng,
    y: lat,
    srid: DefaultSrid,
  };
}
