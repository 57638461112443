import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import { decryptObject, E2E_IV, encryptObject, FORCE_DECRYPT_HEADER } from "../helpers/e2e";
import HourglassGlobals from "../helpers/globals";
import { ISODateString } from "../types/date";
import { DeleteStatus, UpdateStatus } from "../types/scheduling/meetings";
import {
  Congregation,
  CongregationTradeResponse,
  NewCongInput,
  PublicSpeakerInput,
  PublicTalk,
  PublicTalkAssignment,
  PublicTalkAssignmentInput,
  Speaker,
  SpeakerExchangeFormat,
  TradesWithOtherCong,
  WMCoreParts,
  WMSchedule,
  WMScheduleInput,
} from "../types/scheduling/weekend";
import { api, QueryDateRange, schedulingPath } from "./api";
import QueryKeys from "./queryKeys";

class WeekendAPI {
  static base = schedulingPath("/wm");

  static E2EProps = new Set<keyof PublicSpeakerInput>([
    "firstname",
    "middlename",
    "lastname",
    "suffix",
    "email",
    "cellphone",
    "homephone",
    "otherphone",
  ]);

  static async speakerFromResponse(speaker: any): Promise<Speaker> {
    if (HourglassGlobals.e2eKey && speaker[E2E_IV]) {
      try {
        speaker = await decryptObject(speaker, HourglassGlobals.e2eKey);
      } catch (err: any) {
        console.error("failure to decrypt speaker", speaker.id, err);
        HGBugsnagNotify("decryptSpeaker", err);
      }
    }

    return speaker;
  }

  //decrypt speaker inside an assignment
  static async processAssignment(pta: PublicTalkAssignment): Promise<PublicTalkAssignment> {
    if (!pta || !pta.speaker) return pta;
    pta.speaker = await WeekendAPI.speakerFromResponse(pta.speaker);
    return pta;
  }

  //decrypt speaker inside a schedule
  static async processSchedule(schedule: WMSchedule): Promise<WMSchedule> {
    if (!schedule || !schedule.speaker) return schedule;
    schedule.speaker = await WeekendAPI.speakerFromResponse(schedule.speaker);
    if (schedule.out) schedule.out = await Promise.all(schedule.out.map((pta) => WeekendAPI.processAssignment(pta)));
    return schedule;
  }

  async getSchedule(date: string, lgroup: number): Promise<WMSchedule> {
    const url = `${WeekendAPI.base}/schedule/${date}`;
    const data = await api.get(url, { searchParams: { lgroup: lgroup } }).json<WMSchedule>();
    return await WeekendAPI.processSchedule(data);
  }

  async getSchedules(from: string, to: string, lgroup: number): Promise<WMSchedule[]> {
    const url = `${WeekendAPI.base}/schedule/${from}_${to}`;
    const data = await api.get(url, { searchParams: { lgroup: lgroup } }).json<WMSchedule[]>();
    return await Promise.all(data.map((s: WMSchedule) => WeekendAPI.processSchedule(s)));
  }

  async autoSingleCRH(date: string, part: WMCoreParts, lgroup: number): Promise<WMSchedule> {
    const url = `${WeekendAPI.base}/schedule/${date}/auto/${part}`;
    const data = await api.put(url, { searchParams: { lgroup: lgroup } }).json<WMSchedule>();
    return await WeekendAPI.processSchedule(data);
  }

  async autoRangeCRH(from: string, to: string, lgroup: number): Promise<WMSchedule[]> {
    const url = `${WeekendAPI.base}/schedule/${from}_${to}/auto`;
    const data = await api.put(url, { searchParams: { lgroup: lgroup } }).json<WMSchedule[]>();
    return await Promise.all(data.map((s: WMSchedule) => WeekendAPI.processSchedule(s)));
  }

  async setSchedule(input: WMScheduleInput, lgroup: number): Promise<WMSchedule> {
    const url = `${WeekendAPI.base}/schedule`;
    const data = await api.put(url, { json: input, searchParams: { lgroup: lgroup } }).json<WMSchedule>();
    return await WeekendAPI.processSchedule(data);
  }

  async setLastGiven(pts: PublicTalk[], lgroup: number): Promise<PublicTalk[]> {
    const url = `${WeekendAPI.base}/public_talk/last_given`;
    return await api.put(url, { json: pts, searchParams: { lgroup: lgroup } }).json<PublicTalk[]>();
  }

  async setOutSchedule(input: PublicTalkAssignmentInput, lgroup: number): Promise<UpdateStatus> {
    const url = `${WeekendAPI.base}/schedule/out`;
    return api.put(url, { json: input, searchParams: { lgroup: lgroup } }).json<UpdateStatus>();
  }

  async deleteOutSchedule(id: number, lgroup: number): Promise<UpdateStatus> {
    const url = `${WeekendAPI.base}/schedule/${id}`;
    return api.delete(url, { searchParams: { lgroup: lgroup } }).json<UpdateStatus>();
  }

  async getCongregations(lgroup: number): Promise<Congregation[]> {
    const url = `${WeekendAPI.base}/cong`;
    return await api.get(url, { searchParams: { lgroup: lgroup } }).json();
  }

  async getCongregation(congID: number, lgroup: number): Promise<Congregation> {
    const url = `${WeekendAPI.base}/cong/${congID}`;
    return await api.get(url, { searchParams: { lgroup: lgroup } }).json();
  }

  async getSpeakers(cong: number, lastGiven: boolean, outOnly: boolean, lgroup: number): Promise<Speaker[]> {
    if (!cong) {
      return Promise.resolve([] as Speaker[]);
    }

    const url = `${WeekendAPI.base}/speaker/cong/${cong}`;
    const searchParams = {
      last_given: lastGiven,
      out_only: false,
      lgroup: lgroup,
    };
    const data = await api.get(url, { searchParams }).json<Speaker[]>();
    return await Promise.all(data.map((s: Speaker) => WeekendAPI.speakerFromResponse(s)));
  }

  async getSpeakersAll(lgroup: number): Promise<Speaker[]> {
    const url = `${WeekendAPI.base}/speaker`;
    const data = await api.get(url, { searchParams: { lgroup: lgroup } }).json<Speaker[]>();
    return await Promise.all(data.map((s: Speaker) => WeekendAPI.speakerFromResponse(s)));
  }

  async setSpeaker(input: PublicSpeakerInput, forceDecrypted = false, lgroup: number): Promise<Speaker> {
    const url = `${WeekendAPI.base}/speaker`;
    let savedSpeaker: any = input;
    if (HourglassGlobals.e2eKey && !forceDecrypted) {
      savedSpeaker = await encryptObject(input, HourglassGlobals.e2eKey, WeekendAPI.E2EProps);
    }

    let config = { searchParams: { lgroup: lgroup } };
    if (forceDecrypted) {
      config = {
        ...config,
        ...FORCE_DECRYPT_HEADER,
      };
    }
    const data = await api.put(url, { json: savedSpeaker, ...config }).json();
    return await WeekendAPI.speakerFromResponse(data);
  }

  async multSpeakerUpload(congID: number, formData: FormData, lgroup: number): Promise<UpdateStatus> {
    const url = `${WeekendAPI.base}/speaker/upload/${congID}`;

    try {
      await api.post(url, { body: formData, searchParams: { lgroup: lgroup } });
      return { Updated: true };
    } catch (e) {
      return { Updated: false };
    }
  }

  async addCong(input: NewCongInput, lgroup: number): Promise<CongregationTradeResponse> {
    const url = `${WeekendAPI.base}/cong`;
    return api.post(url, { json: input, searchParams: { lgroup: lgroup } }).json();
  }

  async deleteCong(congID: number, lgroup: number): Promise<DeleteStatus> {
    const url = `${WeekendAPI.base}/cong/${congID}`;
    return api.delete(url, { searchParams: { lgroup: lgroup } }).json();
  }

  async setCongNotes(congID: number, custom_name: string, notes: string, lgroup: number): Promise<UpdateStatus> {
    const url = `${WeekendAPI.base}/cong/${congID}`;
    return api.put(url, { json: { custom_name: custom_name, notes: notes }, searchParams: { lgroup: lgroup } }).json();
  }

  async deleteSpeaker(speakerID: number, lgroup: number): Promise<DeleteStatus> {
    const url = `${WeekendAPI.base}/speaker/${speakerID}`;
    return api.delete(url, { searchParams: { lgroup: lgroup } }).json();
  }

  async getTalksByCong(congID: number, lastGiven: boolean, outOnly: boolean, lgroup: number): Promise<PublicTalk[]> {
    const url = `${WeekendAPI.base}/public_talk/given_in_cong/${congID}`;
    const searchParams = {
      last_given: lastGiven,
      out_only: false,
      lgroup: lgroup,
    };
    return api.get(url, { searchParams }).json();
  }

  async getTalks(last_given = false, lgroup: number): Promise<PublicTalk[]> {
    const url = `${WeekendAPI.base}/public_talk`;
    const searchParams = {
      last_given: last_given,
      lgroup: lgroup,
    };
    return api.get(url, { searchParams }).json();
  }

  async getCongTrades(
    congID: number,
    lgroup: number,
    from?: ISODateString,
    to?: ISODateString,
  ): Promise<TradesWithOtherCong> {
    const url = `${WeekendAPI.base}/schedule/cong/${congID}`;

    const queryParams: QueryDateRange = {};
    if (from) queryParams.fromDate = from;
    if (to) queryParams.toDate = to;

    const trades = await api.get(url, { searchParams: { lgroup: lgroup, ...queryParams } }).json<TradesWithOtherCong>();

    if (trades.outbound)
      trades.outbound = await Promise.all(trades.outbound.map((pta) => WeekendAPI.processAssignment(pta)));
    if (trades.inbound)
      trades.inbound = await Promise.all(trades.inbound.map((pta) => WeekendAPI.processAssignment(pta)));
    return trades;
  }

  async getSpeakerUpcomingAssignment(speakerID: number, lgroup: number): Promise<PublicTalkAssignment[]> {
    const url = `${WeekendAPI.base}/schedule/speaker/${speakerID}`;
    return api.get(url, { searchParams: { lgroup: lgroup } }).json();
  }

  async getExport(lgroup: number): Promise<SpeakerExchangeFormat> {
    const url = `${WeekendAPI.base}/speaker/exchange/export`;
    return api.get(url, { searchParams: { lgroup: lgroup } }).json();
  }

  async sendExport(sef: SpeakerExchangeFormat, lgroup: number): Promise<UpdateStatus> {
    const url = `${WeekendAPI.base}/speaker/exchange/import`;
    return api.post(url, { json: sef, searchParams: { lgroup: lgroup } }).json();
  }

  async sendKhsExchange(file: File, guid: string, lgroup: number): Promise<UpdateStatus> {
    const url = `${WeekendAPI.base}/speaker/exchange/khsimport`;
    const body = new FormData();
    body.append("guid", guid);
    body.append("payload", file);
    return api.post(url, { body, searchParams: { lgroup: lgroup } }).json();
  }

  async respondSpeakerExchange(congId: number, accept: boolean, lgroup: number) {
    const url = `${WeekendAPI.base}/cong/respondSpeakerExchange/${congId}`;
    await api.put(url, { json: { accept: accept }, searchParams: { lgroup: lgroup } }).json();
  }
}

export function useWMSchedules(
  from: ISODateString,
  to: ISODateString,
  langGroupId: number,
  options?: Partial<UseQueryOptions<WMSchedule[], Error>>,
) {
  return useQuery({
    queryKey: [QueryKeys.WMCSchedules, langGroupId, from, to],
    queryFn: () => weekendApi.getSchedules(from, to, langGroupId),
    ...options,
    meta: { clearStoredLangGroupOn404: true },
  });
}

export function useWMSpeakers(
  congId: number,
  langGroupId: number,
  lastGiven = false,
  outOnly = false,
  options?: Partial<UseQueryOptions<Speaker[], Error>>,
) {
  return useQuery({
    queryKey: [QueryKeys.WMSpeakers, congId, langGroupId],
    queryFn: () => weekendApi.getSpeakers(congId, lastGiven, outOnly, langGroupId),
    ...options,
  });
}

export const weekendApi = new WeekendAPI();
