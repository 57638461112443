import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Nav, NavDropdown } from "react-bootstrap";
import HourglassGlobals from "../../helpers/globals";
import { Permission } from "../../types/permission";
import { StarFill } from "react-bootstrap-icons";

export function NavScheduling() {
  const { t } = useTranslation();
  return (
    <Nav className="flex-column hg-nav">
      <NavDropdown.Item as={Link} to="/scheduling/mm">
        {t("conganalysis.attendance.midweek")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/wm">
        {t("conganalysis.attendance.weekend")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/avattendant">
        {t("schedules.ava.title")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/cleaning">
        {t("schedules.cleaning.title")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/fsMeeting">
        {t("schedules.field-service.title")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/publicWitnessing">
        {t("schedules.public-witnessing.title")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/maintenance">
        <span>
          {t("schedules.maintenance.title")} <StarFill className="ms-1" color="blue" />
        </span>
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item as={Link} to="/scheduling/memorial">
        {t("schedules.events.memorial")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      {(HourglassGlobals.permissions.has(Permission.ViewUsers) ||
        HourglassGlobals.permissions.has(Permission.ViewUsersMinimal)) && (
        <NavDropdown.Item as={Link} to="/scheduling/privilege_matrix">
          {t("schedules.privileges.title")}
        </NavDropdown.Item>
      )}
      <NavDropdown.Item as={Link} to="/scheduling/absence">
        {t("schedules.absence.title")}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/scheduling/events">
        {t("schedules.events.title")}
      </NavDropdown.Item>
      {HourglassGlobals.permissions.has(Permission.UpdateCongregation) && (
        <NavDropdown.Item as={Link} to="/scheduling/settings">
          {t("Jcy-FH-fbr.title")}
        </NavDropdown.Item>
      )}
    </Nav>
  );
}
