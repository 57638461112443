import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { FormEvent, useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { congregationApi, useCongSettings } from "../../../api/cong";
import QueryKeys from "../../../api/queryKeys";
import HourglassGlobals, { HGContext, HGGlobals } from "../../../helpers/globals";
import { Cong } from "../../../types/cong";
import { Permission } from "../../../types/permission";
import { CongSetting, CongSettings } from "../../../types/scheduling/settings";
import { QueryStatus } from "../../queryStatus";
import { SaveResult, SaveStatus } from "../../saveStatus";
import { CheckBox, DDMstrings, SettingsTerr, TextArea, TextInput } from "../../scheduling/settings/settings";
import { Unlock } from "react-bootstrap-icons";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { HGPageTitle } from "../../utils";

export function canUpdateCongSettings(): boolean {
  return HourglassGlobals.permissions.has(Permission.UpdateCongregation);
}

export default function CongregationSettings() {
  const query = useCongSettings();
  const queryClient = useQueryClient();
  const queryStatus = QueryStatus(query);
  if (queryStatus !== null) return queryStatus;
  if (!query.data) return null;

  return <AllSettings settings={query.data} queryClient={queryClient} />;
}

const AllSettings = (props: { settings: CongSettings; queryClient: QueryClient }) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState(props.settings);
  const [saveResult, setSaveResult] = useState<SaveResult>(SaveResult.None);
  const saveSettingsMutation = useMutation({
    mutationFn: (settings: CongSettings) => congregationApi.setSettings(settings, 0),
  });
  const queryClient = useQueryClient();
  const ctx = useContext(HGContext);
  const myCong = ctx.globals.cong;

  const handleReset = () => {
    setSettings(props.settings);
  };

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) return;

    try {
      const resp = await saveSettingsMutation.mutateAsync(settings);

      // update the globals that come from settings. if we get many more of these, we should re-think this.
      const updatedCountry: Country = { ...myCong.country };
      if (resp.name_format) updatedCountry.namefmt = resp.name_format;
      if (resp.paper_size) updatedCountry.paper_size = resp.paper_size;
      const newCong: Cong = { ...myCong, country: updatedCountry };
      const newGlobals: HGGlobals = { ...ctx.globals, cong: newCong };
      ctx.setGlobals(newGlobals);

      setSaveResult(SaveResult.Success);
      queryClient.setQueryData([QueryKeys.CongregationSettings, myCong.id], resp);
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("error saving scheduling settings", err);
      HGBugsnagNotify("congSettings", err);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-end mb-4">
        <HGPageTitle title={t("congregation.settings.title")} />
      </div>
      <Form onSubmit={handleUpdate}>
        <SettingsCong settings={settings} setSettings={setSettings} myCong={myCong} />
        <div className="my-4">
          <Button variant="secondary" onClick={handleReset} disabled={!canUpdateCongSettings()}>
            {t("general.reset")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="ms-1 me-1"
            disabled={settings === props.settings || !canUpdateCongSettings()}
          >
            {t("general.save")}
          </Button>
          <SaveStatus saveResult={saveResult} saveKey="sched_settings" setSaveResult={setSaveResult} />
        </div>
      </Form>
    </div>
  );
};

export const SettingsCong = (props: {
  settings: CongSettings;
  setSettings: (set: CongSettings) => void;
  myCong: Cong;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CheckBox
        switchType
        settingKey={CongSetting.CongregationGroupsUseLabels}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <DDMstrings
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.PaperSize}
        options={[
          { label: "A4 (210 x 297 mm)", value: "a4" },
          { label: 'Letter (8.5 x 11")', value: "letter" },
        ]}
      />
      <DDMstrings
        settings={props.settings}
        setSettings={props.setSettings}
        settingKey={CongSetting.NameFormat}
        options={[
          {
            label: `${t("userinfo.name.last")}, ${t("userinfo.name.first")} ${t("userinfo.name.middle")}`,
            value: "last",
          },
          {
            label: `${t("userinfo.name.first")} ${t("userinfo.name.middle")} ${t("userinfo.name.last")}`,
            value: "first",
          },
        ]}
      />
      <TextInput
        settingKey={CongSetting.InfoBoardURL}
        settings={props.settings}
        setSettings={props.setSettings}
        placeholder={t("settings.infoboard.help")}
        inputType="url"
      />
      <TextInput
        settingKey={CongSetting.CircuitOverseerName}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <TextArea
        settingKey={CongSetting.CircuitOverseerContactInfo}
        settings={props.settings}
        setSettings={props.setSettings}
        labelIcon={HourglassGlobals.isE2E() ? <Unlock /> : undefined}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.DisableAppCreditHours}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.ShowMyContactInfo}
        settings={props.settings}
        setSettings={(settings) => {
          if (!settings.show_my_contact_info) settings.edit_my_contact_info = false;
          props.setSettings(settings);
        }}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.EditMyContactInfo}
        settings={props.settings}
        setSettings={props.setSettings}
        disabled={!props.settings.show_my_contact_info}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.ShowMyEmergencyContacts}
        settings={props.settings}
        setSettings={(settings) => {
          if (!settings.show_my_emergency_contacts) settings.edit_my_emergency_contacts = false;
          props.setSettings(settings);
        }}
      />
      <CheckBox
        switchType
        settingKey={CongSetting.EditMyEmergencyContacts}
        settings={props.settings}
        setSettings={props.setSettings}
        disabled={!props.settings.show_my_emergency_contacts}
      />
      {HourglassGlobals.isE2E() && (
        <CheckBox
          switchType
          settingKey={CongSetting.LabelAsPreferredName}
          settings={props.settings}
          setSettings={props.setSettings}
        />
      )}
      <CheckBox
        switchType
        settingKey={CongSetting.DisableDeclineAssignment}
        settings={props.settings}
        setSettings={props.setSettings}
      />
      <hr className="mt-3" />
      <h5>{t("schedules.territory.territories")}</h5>
      <SettingsTerr settings={props.settings} setSettings={props.setSettings} />
    </>
  );
};
